import { Typography } from "antd";
import React from "react";

type PresentationVideoStatusProps = {
  icon?: string | React.ReactNode;
  title?: string;
  description?: string;
  status: string;
};

const PresentationVideoStatusComponent: React.FC<PresentationVideoStatusProps> = ({ icon, title, description, status }) => {
  const titleClass = status ? `status-title ${status}` : "status-title";

  return (
    <div className="status-wrapper">
      {icon && typeof icon === "string" ? <img src={icon} className="status-icon" alt="status icon" /> : icon}
      <div className="status-info-container">
        <Typography.Text className={titleClass}>{title}</Typography.Text>
        <Typography.Text className="status-description">{description}</Typography.Text>
      </div>
    </div>
  );
};

export default PresentationVideoStatusComponent;
