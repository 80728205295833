import { Layout, Row, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FOOTER_IDS, THUMBNAIL_TYPES } from "../../helpers/constant";
import { getFeedsByCreatorId } from "../../redux/feeds/feedsSlice";
import { AppDispatch, StoreState } from "../../redux/store";
import { authService } from "../../services";
import ArrowLeftIconComponent from "../ArrowLeftIconComponent";
import FooterComponent from "../FooterComponent";
import MainSwiperSlideItem from "../MainSwiperSlideItem";

import "swiper/less";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "swiper/less/zoom";
import "./index.less";
import { Pagination, Mousewheel, Zoom, Keyboard, Navigation } from 'swiper/modules';
import { FanHomePageSkelton } from "../SkeltonsComponents/FanHomePageSkelton";

function MainFeedDetailsComoponent() {
  const { t } = useTranslation();
  const { id, userID } = useParams();
  const shouldFetch = useRef(true);
  const {list: feeds, loading} = useSelector((state: StoreState) => state.feeds);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(getFeedsByCreatorId({creatorId : userID!}));
    }
  }, []);

  return (
    <div>
      <Layout>
        <Layout.Header className="header">
          <Row className="relative justify-content-center">
            <ArrowLeftIconComponent />
            <Typography.Text className="header-title">{t("Feeds")}</Typography.Text>
          </Row>
        </Layout.Header>
        <Layout.Content className="content fanFeedDetailsContentWrapper">
        {
          loading === "pending" ? <FanHomePageSkelton loading={loading === "pending"} />  
            : <Swiper 
              className="swiper" 
              slidesPerView={1} 
              direction="vertical" 
              scrollbar={{ draggable: true }} 
              initialSlide={Number(id)}
              pagination={{
                clickable: true,
              }}
              keyboard={{
                enabled: true,
              }}
              navigation={false}
              modules={[Mousewheel, Keyboard, Pagination, Zoom, Navigation]}
              mousewheel={true}
              zoom={true}>
            {feeds?.map((feed, index) => {
              let thumbnail = "";
              let fileUrl = "";
              if (feed.files !== undefined) {
                if (!feed.isPrivateForCurrentUser) {
                  fileUrl = feed.files[0].url!;
                  thumbnail = feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath!;
                } else {
                  thumbnail = feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath!;
                  fileUrl = thumbnail;
                }
              }
              return (
                <SwiperSlide key={index}>
                  <MainSwiperSlideItem
                    feedId={feed._id!}
                    name={feed?.creatorInfo?.name!}
                    username={feed?.creatorInfo?.username!}
                    avatar={feed?.creatorInfo?.avatar!}
                    creatorId={feed?.sourceId}
                    totalLikes={feed?.totalLike}
                    fileUrl={fileUrl}
                    thumbnail={thumbnail}
                    isLiked={feed?.isLiked}
                    isBookMarked={feed?.isBookMarked}
                    isPrivate={feed?.isPrivateForCurrentUser}
                    description={feed?.text!}
                    type={feed.type!}
                    isCurrentUser={feed.creatorInfo?._id === authService.getId()}
                    // creatorIds={feed?.creatorIds || []}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        }

        </Layout.Content>
        <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} />
      </Layout>
    </div>
  );
}

export default MainFeedDetailsComoponent;
