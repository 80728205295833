import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import { copyToClipboardCustom } from "../../../../utls/FunctionsUtil";
import "./index.less";

type CollectionDetailModalProps = {
  isOpen: boolean;
  link: string;
  onClose: () => void;
  editCollection: () => void;
  openShareModal: () => void;
  openDeleteModal: () => void;
};

const CollectionDetailModalComponent: React.FC<CollectionDetailModalProps> = ({
  isOpen,
  link,
  onClose,
  editCollection,
  openShareModal,
  openDeleteModal,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const copyCollectionLink = async () => {
    await copyToClipboardCustom(link, t("something-want-wrong"), t("link-copied") as string);
    onClose();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-30">{t("what-do-you-want-to-do")}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          <button className="bottom-modal-button" onClick={editCollection}>
            <Typography.Text className="bottom-modal-button-title">{t("edit-collection")}</Typography.Text>
          </button>

          <button className="bottom-modal-button mt-14" onClick={openShareModal}>
            <Typography.Text className="bottom-modal-button-title">{t("share")}</Typography.Text>
          </button>

          <button className="bottom-modal-button mt-14" onClick={copyCollectionLink}>
            <Typography.Text className="bottom-modal-button-title">{t("copy-link")}</Typography.Text>
          </button>

          <button className="bottom-modal-button mt-14" onClick={openDeleteModal}>
            <Typography.Text className="bottom-modal-button-title">{t("delete-collection")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollectionDetailModalComponent;
