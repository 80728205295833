import React from "react";
import { Col, Typography } from "antd";
import CollectionFileIcon from "../../../assets/icons/collection-file.svg";
import CollectionPriceIcon from "../../../assets/icons/collection-price.svg";
import HoldTapGif from "../../../assets/gif/hold-tap.gif";
import RemoveIcon from "../../../assets/icons/edit-collection.svg";
import HideIcon from "../../../assets/icons/hide-collection.svg";
import { ICollections } from "../../../helpers/types";
import "./index.less";

type CollectionItemProps = {
  index: number;
  collection: ICollections;
  isEditMode: boolean;
  isMyProfile: boolean;
  showTrainingOverlay?: boolean;
  onEdit: (collection: ICollections, event: React.MouseEvent | React.TouchEvent) => void;
  onNavigate: (collectionId: string) => void;
  onLongPressStart: () => void;
  onLongPressEnd: () => void;
  draggable: boolean;
  onDragStart: (e: React.DragEvent, index: number) => void;
  onDrop: (e: React.DragEvent, index: number) => void;
  onDragOver: (e: React.DragEvent) => void;
  collectionHiddenPressed: () => void;
};

const CollectionItemComponent: React.FC<CollectionItemProps> = ({
  index,
  collection,
  isEditMode,
  isMyProfile,
  showTrainingOverlay,
  onEdit,
  onNavigate,
  onLongPressStart,
  onLongPressEnd,
  draggable,
  onDragStart,
  onDrop,
  onDragOver,
  collectionHiddenPressed,
}) => {
  if (collection.isHidden && !isMyProfile) return null;

  return (
    <Col
      className={`collection-item ${isEditMode ? "edit-mode" : ""}`}
      span={8}
      draggable={draggable}
      onMouseDown={onLongPressStart}
      onMouseUp={onLongPressEnd}
      onTouchStart={onLongPressStart}
      onTouchEnd={onLongPressEnd}
      onClick={(e) => (collection.isHidden && isMyProfile ? onEdit(collection, e) : onNavigate(collection._id))}
      onDragStart={(e) => onDragStart(e, index)}
      onDrop={(e) => onDrop(e, index)}
      onDragOver={onDragOver}
    >
      <div className="collection-box">
        {showTrainingOverlay && <img src={HoldTapGif} className="hold-tap-gif" alt="Training GIF" />}
        {collection.isHidden && (
          <div>
            <div className="overlay-dimmed"></div>
            <img src={HideIcon} className="hide-collection-img" alt="Hidden" />
          </div>
        )}
        {isEditMode && isMyProfile && (
          <img
            src={RemoveIcon}
            className="remove-icon"
            alt="edit"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(collection, e);
            }}
          />
        )}
        <img className="collection-img" src={collection.thumbnail} alt="collection" />
        {collection.price !== undefined && (
          <div className="overlay-left">
            <img src={CollectionPriceIcon} className="overlay-icon" alt="price" />
            <span className="overlay-text">{collection.price} €</span>
          </div>
        )}
        <div className="overlay-right">
          <img src={CollectionFileIcon} className="overlay-icon" alt="files" />
          <span className="overlay-text">{collection.filesCount}</span>
        </div>
        <div className="overlay-bottom">
          <div className="collection-description-container">
            <Typography.Text className="collection-description-text">{collection.title}</Typography.Text>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default CollectionItemComponent;
