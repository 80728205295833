import { Col, Layout, message, Row, Space, Switch, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { getEmailNotifications, MAX_PRICE } from "../../../helpers/constant";
import { AppDispatch, StoreState } from "../../../redux/store";
import { userService } from "../../../services";
import "./index.less";
import MailIcon from "../../../assets/icons/mail.svg";
import { getMyProfile } from "../../../redux/users/usersSlice";
import ConfirmationModalNotificationComponent from "../../../components/ConfirmationModalNotificationComponent";

const FanEmailNotificationPage = () => {
  const { t } = useTranslation();
  const shouldFetch = useRef(true);
  const dispatch = useDispatch<AppDispatch>();
  const notificationsInformations = getEmailNotifications(t);
  const user = useSelector((state: StoreState) => state.users.currentUserProfile);
  const [notifications, setNotifications] = useState({
    mediaPush: true,
    stream: true,
    scheduleStream: true,
    reminderStream: true,
  });
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState<string | null>(null);
  const [previousNotifications, setPreviousNotifications] = useState(notifications);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      getProfile();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setNotifications(user.notification);
      setPreviousNotifications(user.notification);
    }
  }, [user]);

  const getProfile = () => {
    dispatch(getMyProfile());
  };

  const handleSwitchChange = async (e: boolean, frequency: string) => {
    if (e === false) {
      setSelectedFrequency(frequency);
      setIsConfirmationModalOpen(true);
    } else {
      const updatedNotifications = { ...notifications, [frequency]: e };
      setNotifications(updatedNotifications);
      setPreviousNotifications(notifications);

      await updateNotification(e, frequency, updatedNotifications);
    }
  };
  const updateNotification = async (e: boolean, frequency: string, updatedNotifications: typeof notifications) => {
    try {
      await userService.updateNotificationSetting(updatedNotifications);
    } catch (err) {
      setNotifications(previousNotifications);
      message.error(t("something-went-wrong"));
    }
  };

  return (
    <Layout>
      <Layout.Content className="content fanEmailNotificationWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent top={1} />
          <Typography.Text className="header-title-black-24">{t("email-Notification-title")}</Typography.Text>
        </Row>
        <Row className="pl-20 pr-13 mt-50">
          <Space className="w-full gap-20" direction="vertical">
            {notificationsInformations.map((monetization, index) => {
              return (
                <Col key={index} className="FanNotificationBox">
                  <Row className="justify-content-between">
                    <Space direction="horizontal" className="items-start fanNotificationtitleandSubtile">
                      <img src={MailIcon} width="14px" height="14px" className="mt-7" />
                      <Col className="d-flex flex-column">
                        {" "}
                        <Typography.Text className="FanNotificationItemTitle m-0">{monetization.title}</Typography.Text>
                        <Typography.Text className="FanNotificationItemSubTitle m-0">{monetization.subTitle}</Typography.Text>
                      </Col>
                    </Space>
                    <Switch
                      className="switch revenueSwitch"
                      checked={
                        monetization.frequency === "stream"
                          ? notifications.stream
                          : monetization.frequency === "mediaPush"
                            ? notifications.mediaPush
                            : monetization.frequency === "reminderStream"
                              ? notifications.reminderStream
                              : monetization.frequency === "scheduleStream"
                                ? notifications.scheduleStream
                                : false
                      }
                      onChange={(e) => {
                        handleSwitchChange(e, monetization.frequency);
                      }}
                    />
                  </Row>
                </Col>
              );
            })}
          </Space>
        </Row>
      </Layout.Content>
      <ConfirmationModalNotificationComponent
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        disableNotification={() => {
          if (selectedFrequency) {
            const updatedNotifications = { ...notifications, [selectedFrequency]: false };
            setNotifications(updatedNotifications);
            setPreviousNotifications(notifications);
            updateNotification(false, selectedFrequency, updatedNotifications);
            setIsConfirmationModalOpen(false);
          }
        }}
      />
    </Layout>
  );
};

export default FanEmailNotificationPage;
