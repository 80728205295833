import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import TipsVideoPresentationComponent from "../../../../Formations/Creators/utils/TipsVideoPresentationComponent";
import { useDisableScroll } from "../../../../../utls/PresentationVideoUtils";
import "../index.less";

interface WhatKindVideoMakeModalProps {
  isOpen: boolean;
  onClose: () => void;
  confirm: () => void;
}

const WhatKindVideoMakeModalComponent: React.FC<WhatKindVideoMakeModalProps> = ({ isOpen, onClose, confirm }) => {
  const { t } = useTranslation();

  useDisableScroll(isOpen);

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="presentation-video-modal-overlay no-scroll" onClick={handleOverlayClick}>
      <div className="presentation-video-modal-content" onClick={handleModalClick}>
        <div className="presentation-video-modal-content-wrapper">
          <div className="w-full d-flex justify-content-center">
            <div className="formation-modal-line-header"></div>
          </div>

          <Typography.Text className="presentation-video-modal-title">{t("what-kind-video-should-I-make")}</Typography.Text>

          <TipsVideoPresentationComponent />

          <button className="presentation-video-modal-confirmation-button" onClick={confirm}>
            {t("watch-the-tutorial")}
          </button>

          <div className="w-full d-flex justify-content-center" onClick={onClose}>
            <Typography.Text className="presentation-video-modal-cancel-button">{t("pass")}</Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatKindVideoMakeModalComponent;
