import { InputNumber, Row, Space, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

type Props = {
  displayTitle?: boolean;
  customPrice?: number | null;
  handleCustomPriceChange: (value: number | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const PriceInputComponent: React.FC<Props> = ({ displayTitle = true, customPrice, handleCustomPriceChange, onFocus, onBlur }) => {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);

  const onInputChange = (value: number | null) => {
    handleCustomPriceChange(value);
  };

  const handleBlur = () => {
    setShowInput(false);
    if (onBlur) {
      onBlur?.();
    }
  };

  return (
    <div>
      {displayTitle && <Typography.Text className="font-30-bold text-white-color">{t("price")}</Typography.Text>}
      <Row>
        <Space className="w-full gap-8" direction="vertical">
          {displayTitle && (
            <Typography.Text className="font-13-regular text-grey-color">{t("customize-the-amount-by-clicking-on-it")}</Typography.Text>
          )}
          <Row className="mt-5">
            {!showInput ? (
              <div className={customPrice ? "priceInputCompleted" : "priceInput"} onClick={() => setShowInput(true)}>
                {customPrice !== undefined && customPrice !== null ? customPrice.toFixed(2) : "0.00"} €
              </div>
            ) : (
              <InputNumber
                className="priceInputCompleted"
                min={3}
                bordered={false}
                onChange={onInputChange}
                value={customPrice ?? undefined}
                autoFocus
                onFocus={onFocus}
                onBlur={handleBlur}
                inputMode="numeric"
              />
            )}
          </Row>
        </Space>
      </Row>
    </div>
  );
};

export default PriceInputComponent;
