import React from "react";
import SendMessageInputComponent from "./SendMessageInputComponent";
import SubscribeViewCollectionsComponent from "./SubscribeViewCollectionsComponent";
import { UserDiscoverDto } from "../../../../helpers/types";
import "./index.less";

type SlideBottomOverlayFanProps = {
  user: UserDiscoverDto;
};

const SlideBottomOverlayFanComponent: React.FC<SlideBottomOverlayFanProps> = ({ user }) => {
  return (
    <div className="slide-bottom-overlay-fan-wrapper">
      <SubscribeViewCollectionsComponent username={user.username} creatorId={user._id} />
      <SendMessageInputComponent user={user} />
    </div>
  );
};

export default SlideBottomOverlayFanComponent;
