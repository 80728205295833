import React from "react";
import { IFanDiscoverDto } from "../../../../../helpers/types";
import VideoPlayer from "../../VideoPlayer";
import SwiperBottomOverlayComponent from "./SwiperBottomOverlayComponent";
import "./index.less";

type SwiperItemProps = {
  discover: IFanDiscoverDto;
  presentationOfSingleCreator: boolean;
  index: number;
  total: number;
  loadMoreDiscovers: () => void;
  globalMuteState: boolean;
  handleMuteChange: (newMuteState: boolean) => void;
  globalShowMuteButton: boolean;
  handleShowMuteButtonChange: (newShowMuteButtonState: boolean) => void;
};

const SwiperItemComponent: React.FC<SwiperItemProps> = ({
  discover,
  presentationOfSingleCreator,
  total,
  index,
  loadMoreDiscovers,
  globalMuteState,
  handleMuteChange,
  globalShowMuteButton,
  handleShowMuteButtonChange,
}) => {
  return (
    <div className="slide-container">
      <VideoPlayer
        discoverId={discover._id}
        src={discover.file.url}
        thumbnail={discover.file.thumbnails.find((thumb: any) => thumb.type === "CLEAR").absolutePath}
        globalMuted={globalMuteState}
        onMuteChange={handleMuteChange}
        globalShowMuteButton={globalShowMuteButton}
        handleShowMuteButtonChange={handleShowMuteButtonChange}
        index={index}
        total={total}
        loadMoreDiscovers={loadMoreDiscovers}
      />
      {!presentationOfSingleCreator && <SwiperBottomOverlayComponent discover={discover} />}
    </div>
  );
};

export default SwiperItemComponent;
