import { LockOutlined } from "@ant-design/icons";
import { Space, Typography, Upload, message } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import VideoIcon from "../../assets/icons/jouer.svg";
import PlusIcon from "../../assets/icons/plus-black.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white-2.svg";
import { Media } from "../../helpers/types";
import { fileService } from "../../services";
import { compressImage, convertHeicFile, generateThumbnailURL, isImageAcceptedFormat } from "../../utls/FunctionsUtil";
import MailboxMediaModal from "../MailboxMediaModal";
import "./index.less";

type props = {
  showTitle?: boolean;
  crop?: boolean;
  disabled?: boolean;
  title?: string;
  maxCount?: number;
  defaultList?: any[];
  onChange?: (fileMap: Map<string, Media>, fileList?: UploadFile<any>[]) => void;
  onFilesChange?: (fileList: UploadFile<[]>[]) => void;
  children?: any;
  isVideoAllowed?: boolean;
  greenCircle?: boolean;
  addPublication?: boolean;
  iconSize?: number;
  setFiles?: any;
  setQuickFiles?: any;
};

const AddMediaComponent: React.FC<props> = ({
  showTitle = true,
  crop = false,
  disabled = false,
  title,
  maxCount,
  defaultList,
  onFilesChange,
  children,
  isVideoAllowed = true,
  greenCircle = false,
  addPublication = false,
  iconSize = 15,
  setFiles,
  setQuickFiles,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isMediaModal, setIsMediaModal] = useState(false);
  const navigate = useNavigate();
  const uploadRef = useRef<any>(null);
  const params: any = new URLSearchParams(window.location.search);
  const paramsFiles: Array<any[]> = JSON.parse(params.get("selected"));
  const [paramIds, setParamIds] = useState<any>([]);

  useEffect(() => {
    if (onFilesChange !== undefined) {
      onFilesChange(fileList);
    }
  }, [fileList]);

  const customItemRender = (originNode: React.ReactNode, file: UploadFile) => {
    let lock: boolean = false;
    let isVideo: boolean = file.type?.includes("video")!;
    defaultList?.forEach((e) => {
      if (file.uid === e.uid && e.isPrivate) {
        lock = true;
      }
    });
    return (
      <>
        {originNode}
        {lock && <LockOutlined className="lockIcon" />}
        {isVideo && <img src={VideoIcon} alt="video icon" className={`videoIcon ${addPublication ? "postVideoIcon" : ""}`} />}
      </>
    );
  };

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      //console.log("in before **upload** file", file.uid, file);
      // If the file is in an accepted format, proceed without conversion
      if (isImageAcceptedFormat(file.type)) {
        // compress file
        const compressedImage = await compressImage(file);
        //console.log("beforeUpload 1 compressedImage", compressedImage);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        //handleConvertedFile(file.uid, file, true);
        return false;
      } else if (file.type === "image/heic" || file.type === "") {
        handleConvertedFile(file.uid, file, false);
        // convert file
        const convertedFile = await convertHeicFile(file);
        //console.log("beforeUpload 2 convertedFile", convertedFile);
        // compress file
        const compressedImage = await compressImage(convertedFile);
        //console.log("beforeUpload 3 compressedImage", compressedImage);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        return false;
      } else if (file.type.includes("video/") && isVideoAllowed) {
        handleConvertedFile(file.uid, file, true);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      removeUnsupportedFiles(file.uid);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const beforeCrop: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      //console.log("in before ++crop++ file", file.uid, file);
      // If the file is in an accepted format, proceed without conversion
      if (isImageAcceptedFormat(file.type)) {
        // compress file
        const compressedImage = await compressImage(file);
        //console.log("beforeCrop 1 compressedImage", compressedImage);
        if (compressedImage !== null) {
          return compressedImage;
        }
        return true;
      } else if (file.type === "image/heic" || file.type === "") {
        // convert file
        const convertedFile = await convertHeicFile(file);
        //console.log("beforeCrop 2 convertedFile", convertedFile);
        // compress file
        const compressedImage = await compressImage(convertedFile);
        //console.log("beforeCrop 3 compressedImage", compressedImage);
        if (compressedImage !== null) {
          return compressedImage;
        }
        return convertedFile;
      } else if (file.type.includes("video/")) {
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleConvertedFile = async (uid: string, file: File, converted: boolean) => {
    //console.log("uid, converted, file :>> ", uid, converted, file);
    if (!converted) {
      const fileObj: UploadFile = {
        uid: uid,
        name: file.name,
        status: "uploading",
        percent: 50,
      };
      setFileList((prevList) => [fileObj, ...prevList]);
    } else {
      const fileObj: UploadFile = {
        uid: uid,
        name: file.name,
        status: "done",
        type: file.type,
        size: file.size,
        originFileObj: file as RcFile,
      };
      if (file.type.includes("video")) {
        const result = await generateThumbnailURL(file as RcFile);
        fileObj.url = result;
      }
      setFileList((prevList) => [fileObj, ...prevList.filter((file) => file.uid !== fileObj.uid)]);
    }
  };

  const isImageUrl = (): boolean => {
    return true;
  };

  const removeUnsupportedFiles = (uid: string) => {
    setFileList((prevList) => [...prevList.filter((file) => file.uid !== uid)]);
  };

  const onRemove = async (file: UploadFile<any>) => {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
    setQuickFiles({ id: "", type: "" });
  };

  const handleDeviceClick = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
      setIsMediaModal(false);
    }
  };

  useEffect(() => {
    if (paramsFiles?.length !== 0) {
      setParamIds(paramsFiles);
      navigate(location.pathname, { replace: true });
    }
    return () => {
      setParamIds([]);
    };
  }, []);

  useEffect(() => {
    if (paramIds !== null && paramIds !== undefined && paramIds?.length !== 0) {
      getAllSelectedFiles(paramIds);
    }
  }, [paramIds]);

  const getAllSelectedFiles = async (fileIds: Array<any>) => {
    await fileService.allSelectedFiles(fileIds).then((response: any) => {
      response.forEach((item: any) => {
        let fileType = "";
        let fileExt = "";
        let isVideo = false;
        if (item.url.includes(".mp4")) {
          fileType = "video/mp4";
          fileExt = "mp4";
          isVideo = true;
        } else {
          fileType = "image/webp";
          fileExt = "webp";
        }
        let originFileObj2 = {
          isPrivate: true,
          status: "done",
          uid: item._id,
          name: item._id,
          percent: 0,
          thumbUrl: "",
          type: "quick-link",
        };
        let JsonString = JSON.stringify(originFileObj2);
        let blob = new Blob([JsonString], { type: fileType });
        let file = new File([blob], `newQuickFile.${fileExt}`, { type: fileType });
        setFileList((prevFiles: any) => [
          ...prevFiles,
          {
            ...item,
            imgType: "quick-file",
            isPrivate: true,
            status: "done",
            uid: item._id,
            name: item._id,
            percent: 0,
            thumbUrl: isVideo ? item?.thumbnailUrl : item?.url,
            type: fileType,
            originFileObj: file,
          },
        ]);
        setQuickFiles({ id: item?._id, type: isVideo ? "video" : "photo" });
        setParamIds(null);
      });
    });
  };

  return (
    <Space
      className={`gap-13 
        ${
          children === undefined
            ? !addPublication
              ? greenCircle
                ? "addMediaWrapperCercleGreen"
                : fileList.length === 0
                  ? "w-full addMediaWrapper"
                  : "w-full addMediaSquareDash"
              : "addMediaWrapperPublication"
            : "childrenContainer"
        } `}
      direction="vertical"
    >
      {showTitle && <Typography.Text className="font-30-bold text-white-color">{title || t("media")}</Typography.Text>}
      {crop ? (
        <>
          {fileList.length === 0 && (
            <div
              onClick={() => {
                if (location.pathname === "/creator/publication") {
                  setIsMediaModal(true);
                } else {
                  handleDeviceClick();
                }
              }}
            >
              {children !== undefined ? (
                children
              ) : maxCount ? (
                fileList.length < maxCount && (
                  <div className={`${greenCircle ? "addCircleGreen" : "addCircle"}  ${disabled ? "disabled" : ""}`}>
                    <img src={PlusIcon} width={iconSize} alt="plus" />
                  </div>
                )
              ) : (
                <div className={`addCircle ${disabled ? "disabled" : ""}`}>
                  <img src={PlusIcon} width={greenCircle ? 40 : 15} alt="plus" />
                </div>
              )}
            </div>
          )}
          <Upload
            accept="image/*,video/*"
            disabled={disabled}
            action=""
            itemRender={(originNode: any, file: UploadFile) => customItemRender(originNode, file)}
            defaultFileList={defaultList}
            fileList={fileList}
            listType="picture-card"
            maxCount={maxCount}
            onRemove={onRemove}
            beforeUpload={beforeUpload}
            isImageUrl={isImageUrl}
            showUploadList={{
              showRemoveIcon: true,
              showPreviewIcon: false,
            }}
          >
            {/* {children !== undefined ? (
              children
            ) : maxCount ? (
              fileList.length < maxCount && (
                <div className={`${greenCircle ? "addCircleGreen" : "addCircle"}  ${disabled ? "disabled" : ""}`}>
                  <img src={PlusIcon} width={iconSize} alt="plus" />
                </div>
              )
            ) : (
              <div className={`addCircle ${disabled ? "disabled" : ""}`}>
                <img src={PlusIcon} width={greenCircle ? 40 : 15} alt="plus" />
              </div>
            )} */}
            <div ref={uploadRef} className="d-none"></div>
          </Upload>
        </>
      ) : (
        <>
          <Upload
            accept="image/*,video/*"
            disabled={disabled}
            action=""
            itemRender={(originNode: any, file: any) => customItemRender(originNode, file)}
            defaultFileList={defaultList}
            fileList={fileList}
            listType="picture-card"
            maxCount={maxCount}
            onRemove={onRemove}
            beforeUpload={beforeUpload}
            isImageUrl={isImageUrl}
            showUploadList={{
              showRemoveIcon: true,
              showPreviewIcon: false,
            }}
            multiple={true}
          >
            {children !== undefined ? (
              children
            ) : maxCount ? (
              fileList.length < maxCount && (
                <div className={`addCircle ${disabled ? "disabled" : ""}`}>
                  <img src={PlusWhiteIcon} width={25} alt="plus" />
                </div>
              )
            ) : (
              <div className={`addCircle ${disabled ? "disabled" : ""}`}>
                <img src={PlusWhiteIcon} width={25} alt="plus" />
              </div>
            )}
          </Upload>
        </>
      )}
      <MailboxMediaModal
        isOpen={isMediaModal}
        onClose={() => setIsMediaModal(false)}
        onMyAlbums={() => {
          navigate(`/creator/quickLink?id=post`);
        }}
        onMyDevice={handleDeviceClick}
      />
    </Space>
  );
};

export default AddMediaComponent;
