import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { IDiscoverDto } from "../../../../helpers/types";
import { discoverService } from "../../../../services";

const PresentationVideoChartComponent = ({ discover }: { discover: IDiscoverDto | null }) => {
  const [chartData, setChartData] = useState<{ label: string; count: number }[]>([]);

  useEffect(() => {
    if (discover === null) return;
    const formatChartData = (payload: { date: string; count: number }[]) => {
      const data: { label: string; count: number }[] = [];
      payload.forEach((item) => {
        const date = dayjs(item.date).format("DD/MM/YYYY");
        data.push({ label: date, count: item.count });
      });
      return data;
    };
    discoverService.getDiscoverAnalytics(discover?._id!).then((res) => {
      setChartData(formatChartData(res.data));
    });
  }, [discover]);

  const CustomDot = (props: any) => {
    const { cx, cy, value } = props;
    return <circle cx={cx} cy={cy} r={value === 0 ? 0 : 3} fill="#7FFAA1" />;
  };

  const CustomTooltipContent = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const date = payload[0].payload.label;
      return (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            padding: "5px 14px",
            borderRadius: "8px",
            color: "#FFFFFF",
          }}
        >
          <p className="font-12-bold mt-1 mb-0">{date}</p>
          <p className="font-12-bold mb-0">{payload[0].value} views</p>
        </div>
      );
    }
    return null;
  };

  if (chartData.length === 0) {
    return <div style={{ paddingBottom: "15px" }}></div>;
  }

  return (
    <div style={{ width: "100%", height: "260px" }}>
      <ResponsiveContainer width="100%" height="100%" style={{ paddingTop: "20px" }}>
        <AreaChart data={chartData} margin={{ bottom: 0 }} style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
          <defs>
            <linearGradient id="colorOpacity" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#7FFAA1" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#7FFAA1" stopOpacity={0} />
            </linearGradient>
          </defs>

          {/* Axe des X */}
          <XAxis dataKey="label" tick={{ fill: "#FFFFFF", fontSize: 12 }} tickLine={false} axisLine={false} />

          {/* Axe des Y */}
          <YAxis tick={{ fill: "#FFFFFF", fontSize: 12 }} tickLine={false} axisLine={false} width={40} allowDecimals={false} />
          <Tooltip content={<CustomTooltipContent />} position={{ y: 0 }} />
          <Area type="monotone" dataKey="count" stroke="#7FFAA1" fill="url(#colorOpacity)" strokeWidth={2} dot={<CustomDot />} activeDot={{ r: 6 }} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PresentationVideoChartComponent;
