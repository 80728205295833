import { Typography } from "antd";
import PlaneVideo from "../../../../assets/videos/discover/discover-plane.mp4";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./index.less";

const NoVideoPresentationComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/creator/create/presentation/videos");
  };

  return (
    <div className="no-video-presentation-container">
      <div className="no-video-presentation-gif-container">
        <video src={PlaneVideo} width="100%" autoPlay muted playsInline loop={true} className="plane-video" />
      </div>
      <div className="no-video-presentation-description-container">
        <Typography.Text className="no-video-presentation-title">{t("boost-your-profile")}</Typography.Text>
        <Typography.Text className="no-video-presentation-description">{t("boost-your-profile-desc")}</Typography.Text>
        <button className="no-video-presentation-button" onClick={handleNavigate}>
          {t("discover")}
        </button>
      </div>
    </div>
  );
};

export default NoVideoPresentationComponent;
