import { Layout, Row, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { FILE_STATUS, THUMBNAIL_TYPES } from "../../helpers/constant";
import { IFeed } from "../../helpers/types";
import { getFeedsByCreatorId, getMyFeeds, updateFile } from "../../redux/feeds/feedsSlice";
import { AppDispatch, StoreState } from "../../redux/store";
import { authService, reportService } from "../../services";
import { socket } from "../../socket";
import ArrowLeftIconComponent from "../ArrowLeftIconComponent";
import MainSwiperSlideItemV2 from "../MainSwiperSlideItemV2/MainSwiperSlideItemV2";
import { FanHomePageSkelton } from "../SkeltonsComponents/FanHomePageSkelton";
import "./index.less";

function CreatorPublicationSwiper() {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const targetRef = useRef<HTMLDivElement>(null);
  const { id, userID } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isMyProfile = params.get("isMyProfile") === "true";
  const shouldFetch = useRef(true);
  const { list, currentUserFeeds, loading } = useSelector((state: StoreState) => state.feeds);
  const [feeds, setFeeds] = useState<IFeed[]>([]);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      if (isMyProfile) {
        if (currentUserFeeds.length === 0) {
          dispatch(getMyFeeds());
        } else {
          setFeeds(currentUserFeeds);
        }
      } else {
        if (list.length === 0) {
          dispatch(getFeedsByCreatorId({ creatorId: userID! }));
        } else {
          setFeeds(list);
        }
      }
    }

    function handleFileUpdated(data: { fileId: string; absolutePath: string; thumbnails: any; status: string }) {
      dispatch(updateFile(data));
    }

    socket.on("file-updated", handleFileUpdated);

    return () => {
      socket.off("file-updated", handleFileUpdated);
    };
  }, []);

  useEffect(() => {
    if (isMyProfile) {
      setFeeds(currentUserFeeds);
    } else {
      setFeeds(list);
    }
  }, [list, currentUserFeeds, isMyProfile]);

  useEffect(() => {
    if (feeds.length > 0 && targetRef.current !== null) {
      targetRef.current.scrollIntoView({ behavior: "auto", block: "start", inline: "start" });
    }
  }, [feeds]);

  async function onReportClick(feed: IFeed) {
    const payload = {
      target: "feed",
      targetId: feed._id,
      title: "report",
      description: "",
    };
    const resp = await reportService.createReport(payload);
    if (resp?.data) {
      message.success(t("report-success"));
    } else if (resp?.message) {
      message.error(resp.message);
    }
    dispatch(getFeedsByCreatorId({ creatorId: userID! }));
  }

  return (
    <div>
      <Layout>
        <Layout.Header className="header">
          <Row className="relative justify-content-center">
            <ArrowLeftIconComponent top={10} />
            <Typography.Text className="header-title-black-35">{t("publications")}</Typography.Text>
          </Row>
        </Layout.Header>
        {
          <Layout.Content className="content fanFeedDetailsContentWrapper no-scrollbar">
            {loading === "pending" ? (
              <FanHomePageSkelton loading={loading === "pending"} />
            ) : (
              <div className="pub-content-container">
                {feeds?.map((feed) => {
                  let thumbnail = "";
                  let fileUrl = "";
                  let isUploading: boolean = false;
                  if (feed.files !== undefined) {
                    isUploading = feed.files[0].status === FILE_STATUS.UPLOADING;
                    if (!isUploading) {
                      if (!feed.isPrivateForCurrentUser) {
                        fileUrl = feed.files[0].url!;
                        thumbnail = feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath!;
                      } else {
                        thumbnail = feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath!;
                        fileUrl = thumbnail;
                      }
                    }
                  }
                  return (
                    <MainSwiperSlideItemV2
                      refProp={id === feed._id ? targetRef : null}
                      key={feed._id}
                      feedId={feed._id!}
                      name={feed.creatorInfo?.name!}
                      username={feed.creatorInfo?.username!}
                      avatar={feed.creatorInfo?.avatar!}
                      creatorId={feed.creatorInfo?._id}
                      totalLikes={feed.totalLike}
                      fileUrl={fileUrl}
                      thumbnail={thumbnail}
                      isLiked={feed.isLiked}
                      isBookMarked={feed.isBookMarked}
                      isPrivate={feed.isPrivateForCurrentUser}
                      description={feed.text!}
                      onReportClick={() => feed && onReportClick(feed)}
                      verifiedAccount={feed.creatorInfo?.verifiedAccount}
                      type={feed.type!}
                      isCurrentUser={feed.creatorInfo?._id === authService.getId()}
                      isUploading={isUploading}
                      isMyProfile={isMyProfile}
                      // creatorIds={feed?.creatorIds || []}
                    />
                  );
                })}
              </div>
            )}
          </Layout.Content>
        }
        {/* {authService.getRole() === "creator" ? (
          <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} />
        ) : (
          <FooterComponent type="fan" page={FOOTER_IDS.PROFILE} />
        )} */}
      </Layout>
    </div>
  );
}

export default CreatorPublicationSwiper;
