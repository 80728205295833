import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFanDiscovers } from "../../../redux/discovers/discoversSlice";
import { AppDispatch, StoreState } from "../../../redux/store";

export default function useFetchDiscovers(page: number, username?: string) {
  const dispatch = useDispatch<AppDispatch>();

  const { fanDiscovers, loadingFanDiscovers, errorFanDiscovers, total } = useSelector((state: StoreState) => state.discovers);

  useEffect(() => {
    dispatch(getFanDiscovers({ page, username }));
  }, [page, dispatch, username]);

  return {
    fanDiscovers,
    loadingFanDiscovers,
    errorFanDiscovers,
    total,
  };
}
