import { message, Typography, Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PlusIcon from "../../../../assets/icons/plus-black.svg";
import { DISCOVER_TO_UPLOAD } from "../../../../helpers/constant";
import { IDiscoverDto, IVideoUpload } from "../../../../helpers/types";
import { addCreatorDiscover, removeCreatorDiscover, updateFile, updateUploadedCreatorDiscover } from "../../../../redux/discovers/discoversSlice";
import { AppDispatch } from "../../../../redux/store";
import { discoverService, storageService } from "../../../../services";
import { socket } from "../../../../socket";
import { checkVideoDuration, generateThumbnailURL } from "../../../../utls/FunctionsUtil";
import "./index.less";
import VideoUploadItemComponent from "./VideoUploadItemComponent";

const UploadPresentationVideoComponent = ({
  maxCount,
  defaultList,
  onSelectDiscover,
  onDeleteDiscover,
  setVideoBeingVerifiedModalIsVisible,
  setLoading,
  setProgress,
}: {
  maxCount: number;
  defaultList: IDiscoverDto[];
  onSelectDiscover: (discover: IDiscoverDto | null) => void;
  onDeleteDiscover: (discoverId: string | null) => void;
  setVideoBeingVerifiedModalIsVisible: (isVisible: boolean) => void;
  setLoading: (loading: boolean) => void;
  setProgress: Dispatch<SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedIndex, setSelectedIndex] = useState<string | null>(null);
  // const [loading, setLoading] = useState(false);
  // const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [creatorDiscovers, setCreatorDiscovers] = useState<IDiscoverDto[]>([]);
  // const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);
  const hasDefaultSelectionBeenMade = useRef<boolean>(false);

  useEffect(() => {
    socket.on("file-updated", handleFileUpdated);

    return () => {
      socket.off("file-updated", handleFileUpdated);
    };
  }, []);

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  useEffect(() => {
    if (defaultList.length > 0) {
      const existingDiscovers = defaultList.map((discover) => {
        return {
          ...discover,
          file: {
            _id: discover.file._id,
            thumbnails: discover.file.thumbnails,
            url: discover.file.thumbnails.length > 0 ? discover.file.thumbnails.find((thumb: any) => thumb.type === "CLEAR").absolutePath : "",
            videoUrl: discover.file.url,
          },
        };
      });
      setCreatorDiscovers(existingDiscovers);
    }
  }, [defaultList]);

  useEffect(() => {
    if (creatorDiscovers.length > 0 && !hasDefaultSelectionBeenMade.current) {
      const firstDiscover = creatorDiscovers[0];
      setSelectedIndex(firstDiscover._id);
      onSelectDiscover(firstDiscover);
      hasDefaultSelectionBeenMade.current = true;
    }
  }, [creatorDiscovers]);

  function handleFileUpdated(data: { fileId: string; absolutePath: string; thumbnails: any; status: string }) {
    dispatch(updateFile(data));
  }

  async function handleMediaUpload(fileList: UploadFile[]) {
    setLoading(true);
    try {
      if (fileList.length === 0) {
        message.error(t("no-file-selected-error-message"));
        return;
      }
      const videoFileToChunk: any[] = [];
      const videoFiles: IVideoUpload[] = [];

      fileList.forEach((file: UploadFile) => {
        if (file.status !== "removed") {
          videoFileToChunk.push(file);
        }
      });

      if (videoFileToChunk.length > 0) {
        const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
        setTotalSize(totalSizes);

        for (let i = 0; i < videoFileToChunk.length; i++) {
          const file = videoFileToChunk[i];
          try {
            const result = await storageService.uploadFileMultiPart(file, setLoadedData, totalSizes, "discover");

            videoFiles.push({
              Location: result.Location,
              Key: result.Key,
              ETag: result.ETag,
              Bucket: result.Bucket,
              filename: file.name,
              size: file.size,
            });
          } catch (error: any) {
            // Show error message for individual file upload failure
            console.log("error.message", error.message);
            message.error(t(error.message));
            setLoading(false);
            return; // Exit the function entirely
          }
        }
      }

      const createdDiscovers: IDiscoverDto[] = await discoverService.uploadPresentationVideos({ videoFiles }, setProgress);
      setVideoBeingVerifiedModalIsVisible(true);
      setFileList([]);
      setProgress(0);
      setLoadedData(0);
      setTotalSize(0);
      dispatch(updateUploadedCreatorDiscover(createdDiscovers));
    } catch (error: any) {
      console.log("error.message", error.message);
      message.error(error.message || "Failed to upload media to album");
    } finally {
      setLoading(false);
    }
  }

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      if (file.type.includes("video/")) {
        const isValidDuration = await checkVideoDuration(file);
        if (!isValidDuration) {
          message.error(t("video-duration-error") || "Video must be 1 minute or less");
          return Upload.LIST_IGNORE;
        }
        handleConvertedFile(file.uid, file);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      removeUnsupportedFiles(file.uid);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleConvertedFile = async (uid: string, file: File) => {
    const fileObj: UploadFile = {
      uid: uid,
      name: file.name,
      status: "done",
      type: file.type,
      size: file.size,
      originFileObj: file as RcFile,
    };
    if (file.type.includes("video")) {
      const result = await generateThumbnailURL(file as RcFile);
      fileObj.url = result;
    }
    const discover: IDiscoverDto = {
      _id: uid,
      file: {
        _id: uid,
        thumbnails: [{ absolutePath: fileObj.url!, type: "CLEAR" }],
        url: fileObj.url!,
        videoUrl: fileObj.url!,
      },
      status: DISCOVER_TO_UPLOAD,
      rejectedReason: {
        description: "",
        fileId: "",
        reason: "",
      },
      isVisible: true,
      stats: {
        views: 0,
        clicks: 0,
        subscribers: 0,
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    setFileList((prevList) => [fileObj, ...prevList.filter((file) => file.uid !== fileObj.uid)]);
    dispatch(addCreatorDiscover(discover));
    handleMediaUpload([fileObj]);
    // setShowSubmitBtn(true);
  };

  const isImageUrl = (): boolean => {
    return true;
  };

  const removeUnsupportedFiles = (uid: string) => {
    setFileList((prevList) => [...prevList.filter((file) => file.uid !== uid)]);
  };

  const removeFile = (uid: string) => {
    const index = fileList.findIndex((f) => f.uid === uid);
    const index2 = creatorDiscovers.findIndex((f) => f._id === uid && f.status === DISCOVER_TO_UPLOAD);
    if (index !== -1 && index2 !== -1) {
      setFileList((prev) => prev.filter((f) => f.uid !== uid));
      dispatch(removeCreatorDiscover(uid));
    } else {
      onDeleteDiscover(uid);
    }
  };

  const customItemRender = () => {
    return <></>;
  };

  const props: UploadProps = {
    accept: "video/*",
    action: "",
    itemRender: customItemRender,
    fileList: fileList,
    maxCount: maxCount,
    beforeUpload: beforeUpload,
    isImageUrl: isImageUrl,
    showUploadList: {
      showRemoveIcon: true,
      showPreviewIcon: false,
    },
  };

  const handleSelect = (discover: IDiscoverDto | null) => {
    if (discover === null) {
      setSelectedIndex(null);
      onSelectDiscover(null);
    } else {
      setSelectedIndex(discover._id);
      onSelectDiscover(discover);
    }
  };

  return (
    <div className="upload-video-presentation-wrapper">
      {creatorDiscovers.length === 0 ? (
        <div className="without-video-upload-wrapper">
          <Upload {...props}>
            <div className="without-video-upload-container">
              <img src={PlusIcon} width={70} alt="plus" />
            </div>
          </Upload>
          <div className="video-upload-description-container">
            <Typography.Text className="video-upload-description-primary-text">{t("click-on-plus-button")}</Typography.Text>
            <Typography.Text className="video-upload-description-secondary-text">{t("to-import-your-presentation-video")}</Typography.Text>
          </div>
        </div>
      ) : (
        <div className="with-video-upload-wrapper">
          <Typography.Text className="with-video-upload-title">{t("campaign-in-progress")}</Typography.Text>
          <div className="video-upload-container">
            {creatorDiscovers.length < maxCount && (
              <Upload {...props}>
                <div className="video-upload-rectangle-shape">
                  <div className="add-circle-white">
                    <img src={PlusIcon} width={70} alt="plus" />
                  </div>
                </div>
              </Upload>
            )}
            {creatorDiscovers.map((discover) => (
              <VideoUploadItemComponent
                key={discover._id}
                index={discover._id}
                src={discover.file.url!}
                isSelected={selectedIndex === discover._id}
                status={discover.status}
                onSelect={() => handleSelect(discover)}
                onDeselect={() => handleSelect(null)}
                onRemove={removeFile}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadPresentationVideoComponent;
