import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

type CollectionTrainingModalComponentProps = {
  onClose: () => void;
};

const CollectionTrainingModalComponent: React.FC<CollectionTrainingModalComponentProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="collection-training-overlay no-scroll">
      <div className="collection-training-overlay-content">
        <p className="collection-training-overlay-text">{t("training-collection-modal-text")}</p>
      </div>
      <div className="collection-training-overlay-footer">
        <button className="collection-training-overlay-button" onClick={onClose}>
          {t("i-understand")}
        </button>
      </div>
    </div>
  );
};

export default CollectionTrainingModalComponent;
