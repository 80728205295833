import { Badge, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { messageService, userService } from "../../services";
import { socket } from "../../socket";
import { checkModalStatus, updateModalStatus } from "../../utls/formationModalUtils";
import FormationModalComponent from "../Formations/Creators/Modals";
import { FOOTER_IDS } from "../../helpers/constant";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { removeActiveProfileTabFromStorage } from "../../utls/FunctionsUtil";

type props = {
  id: string;
  name: string;
  link: string;
  icon: string;
  activeIcon: string;
  isActive?: boolean;
};

const FooterItemComponent: React.FC<props> = React.memo(
  ({ id, name, link, icon, activeIcon, isActive }) => {
    const { pathname } = useLocation();
    const shouldFetch = useRef(true);
    const navigate = useNavigate();
    const { currentUserProfile } = useSelector((state: StoreState) => state.users);
    const [unreadConversations, setUnreadConversations] = useState(0);
    const [presentationVideoNotification, setPresentationVideoNotification] = useState(0);
    const [formationModalIsVisible, setFormationModalIsVisible] = useState<boolean>(false);

    const checkAndHandleProfileModal = async () => {
      const localModalStatus = localStorage.getItem("hasSeenProfileModal");

      if (localModalStatus === null || localModalStatus === "false") {
        try {
          const { value } = await userService.getModalStatus("hasSeenProfileModal");
          if (!value) {
            setFormationModalIsVisible(true);
          } else {
            localStorage.setItem("hasSeenProfileModal", "true");
          }
        } catch (error) {
          console.error("Failed to fetch modal status:", error);
        }
      }
    };

    const handleProfileClick = async () => {
      if (pathname !== link) {
        navigate(link);
        localStorage.setItem("shouldShowProfileModal", "true");
      } else {
        await checkAndHandleProfileModal();
      }
    };

    useEffect(() => {
      const shouldShowModal = localStorage.getItem("shouldShowProfileModal") === "true";
      if (shouldShowModal && id === FOOTER_IDS.PROFILE && pathname === link) {
        checkAndHandleProfileModal();
        localStorage.removeItem("shouldShowProfileModal");
      }
    }, [pathname, id, link]);

    function handleUpdateUnreadConversations(data: number) {
      if (data !== unreadConversations) {
        setUnreadConversations(data);
      }
    }

    useEffect(() => {
      if (shouldFetch.current && id === "messaging") {
        shouldFetch.current = false;
        messageService.getTotalNotReadMessages().then((total) => {
          if (total && total !== "") setUnreadConversations(parseInt(total, 10));
        });
      }

      socket.on("notify-read-messages-in-conversation", handleUpdateUnreadConversations);

      return () => {
        socket.off("notify-read-messages-in-conversation", handleUpdateUnreadConversations);
      };
    }, [id]);

    const getIt = async () => {
      updateModalStatus("hasSeenProfileModal", setFormationModalIsVisible);
    };

    return (
      <>
        <Space
          className={`${isActive ? "text-white-color" : "text-grey-color"} 
        ${id === "messaging" ? "footerItemMsg" : id === "live" ? "footerItemLive" : "footerItem"} 
        cursor-pointer gap-5`}
          direction="vertical"
          align="center"
          onClick={() => {
            if (id === "profile") {
              handleProfileClick();
            } else {
              removeActiveProfileTabFromStorage();
              navigate(link);
            }
          }}
        >
          {id === "messaging" ? (
            <Badge count={unreadConversations}>
              <img width={24} height={24} src={isActive ? activeIcon : icon} alt={`${name} icon`} />
            </Badge>
          ) : id === "profile" ? (
            <Badge dot={currentUserProfile?.expiredDiscovers} className="profileDotNotification">
              <img
                width={24}
                height={24}
                src={isActive ? activeIcon : icon}
                alt={`${name} icon`}
                onClick={() => {
                  handleProfileClick();
                }}
              />
            </Badge>
          ) : id === "live" ? (
            <img width={45} height={24} src={isActive ? activeIcon : icon} alt={`${name} icon`} />
          ) : (
            <>
              <img
                width={24}
                height={24}
                src={isActive ? activeIcon : icon}
                alt={`${name} icon`}
                onClick={() => {
                  // if (id === "profile") {
                  //   handleProfileClick();
                  // }

                  if (id === "home" && pathname === "/fan/home") {
                    localStorage.setItem("isHome", "true");
                    window.dispatchEvent(new Event("localStorageChanged"));
                  }
                }}
              />
            </>
          )}
        </Space>
        {formationModalIsVisible && (
          <FormationModalComponent
            id="profile-first-modal"
            visible={formationModalIsVisible}
            onClose={() => {
              setFormationModalIsVisible(false);
            }}
            getIt={getIt}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => prevProps.isActive === nextProps.isActive
);

export default FooterItemComponent;
