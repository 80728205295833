import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

type CancelledSubscriptionConfirmModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

const CancelledSubscriptionConfirmModalComponent: React.FC<CancelledSubscriptionConfirmModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-26">{t("your-subscription-has-been-cancelled")}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          <Typography.Text className="no-prelevment-text">{t("you-will-no-longer-be-debited")}</Typography.Text>
          <button className="bottom-modal-button cancelled-sub-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-button-title subs-title">{t("confirm-btn")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelledSubscriptionConfirmModalComponent;
