import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import ChevronRight from "../../../../../../assets/icons/go-profile-chevron.svg";
import { EventType, IFanDiscoverDto } from "../../../../../../helpers/types";
import { authService, discoverService } from "../../../../../../services";
import { useTranslation } from "react-i18next";
import "./index.less";

const SwiperBottomOverlayComponent = ({ discover }: { discover: IFanDiscoverDto }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToProfile = (event: React.MouseEvent) => {
    event.stopPropagation();
    const currentUserId = authService.getId();
    if (currentUserId === discover.user._id) return navigate(`/creator/profile`);
    navigate(`/p/${discover.user.username}`);
    discoverService.updateDiscoverStats({ id: discover._id, event: EventType.CLICKS });
  };

  return (
    <div className="slide-bottom-container" onClick={goToProfile}>
      <div className="user-profile-data-container">
        <Typography.Text className="user-profile-text">{discover.user.name}</Typography.Text>
        <div className="user-profile-images-container">
          {discover.feeds.map((feed, index) => (
            <img key={index} className="user-profile-images" src={feed.file.url} alt={`profile-${index}`} />
          ))}
        </div>
      </div>
      <div className="discover-profile-container">
        <Typography.Text className="discover-profile-text">{t("discover-profile")}</Typography.Text>
        <img className="discover-profile-chevron-img" src={ChevronRight} alt="go" />
      </div>
    </div>
  );
};

export default SwiperBottomOverlayComponent;
