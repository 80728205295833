import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ToIcon from "../../../../assets/icons/right-arrow-show.svg";
import { Typography } from "antd";
import "./index.less";

type HeaderInfoDiscoverProps = {
  renew: boolean | undefined;
};

const HeaderInfoDiscoverComponent: React.FC<HeaderInfoDiscoverProps> = ({ renew = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/creator/create/presentation/videos");
  };

  return (
    <div className="header-info-discover-container">
      <span className="header-info-discover-text">{renew ? t("renew-your-presentation") : t("also-appear-as-suggestion")}</span>
      <div className="header-info-discover-button" onClick={handleNavigate}>
        <img src={ToIcon} className="to-icon" alt="to" />
        <Typography.Text className="header-info-discover-button-text">{renew ? t("here-we-go") : t("discover")}</Typography.Text>
      </div>
    </div>
  );
};

export default HeaderInfoDiscoverComponent;
