import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import "./index.less";

type ConfirmationModalNotificationProps = {
  isOpen: boolean;
  onClose?: () => void;
  disableNotification?: () => void;
};

const ConfirmationModalNotificationComponent: React.FC<ConfirmationModalNotificationProps> = ({ isOpen, onClose, disableNotification }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-26">{t("confirmation-notification-modal-title")}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          <Typography.Text className="bottom-modal-description">{t("confirmation-notification-modal-description")}</Typography.Text>
          <button className="bottom-modal-button" onClick={disableNotification}>
            <Typography.Text className="bottom-modal-button-title">{t("yes-disable")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModalNotificationComponent;
