import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import UnderVerificationImg from "../../../../../assets/images/under-verification.webp";
import { useDisableScroll } from "../../../../../utls/PresentationVideoUtils";
import VideoOfPresentationVideoStatusComponent from "../VideoOfPresentationVideoStatusComponent/VideoOfPresentationVideoStatusComponent";
import "../index.less";

interface VideoBeingVerifiedModalProps {
  isOpen: boolean;
  displayVideo: boolean;
  onClose: () => void;
}

const VideoBeingVerifiedModalComponent: React.FC<VideoBeingVerifiedModalProps> = ({ isOpen, displayVideo, onClose }) => {
  const { t } = useTranslation();

  useDisableScroll(isOpen);

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="presentation-video-modal-overlay no-scroll" onClick={handleOverlayClick}>
      <div className={`presentation-video-modal-content ${displayVideo ? "" : "gradient-background-dark-blue"}`} onClick={handleModalClick}>
        <div className="presentation-video-modal-content-wrapper">
          <div className="w-full d-flex justify-content-center">
            <div className="formation-modal-line-header"></div>
          </div>
          <div className="w-full d-flex justify-content-center">
            <img src={UnderVerificationImg} className="presentation-video-modal-img w-65-p" alt="Under verification" />
          </div>
          <Typography.Text className="presentation-video-modal-title">{t("your-video-is-being-verified")}</Typography.Text>
          <div className="presentation-video-modal-text-container">
            <Typography.Text className="presentation-video-modal-text">
              {/* {t("our-presentation-video-rules-1")} <span className="white text-underline">{t("our-presentation-video-rules-2")}.</span> */}
              {t("our-presentation-video-rules-1")} {t("our-presentation-video-rules-2")}.
            </Typography.Text>

            <Typography.Text className="presentation-video-modal-text">
              {t("presentation-video-approval-part-1")}
              <span className="white black-font">{t("presentation-video-approval-part-2")}</span>
              {t("presentation-video-approval-part-3")}
            </Typography.Text>

            <Typography.Text className="presentation-video-modal-text">
              {t("presentation-video-refusal-part-1")}
              <span className="white black-font">{t("presentation-video-refusal-part-2")}</span>
              {t("presentation-video-refusal-part-3")}
            </Typography.Text>
          </div>

          {/* {displayVideo && (
            <div className="reason-for-refusal-container">
              <div className="reason-for-refusal-text-container">
                <Typography.Text className="presentation-video-modal-title font-size-16">
                  {t("watch-explanatory-video-better-understanding")}
                </Typography.Text>
              </div>

              <VideoOfPresentationVideoStatusComponent
                src="https://www.youtube.com/watch?v=eMGSHRXfctQ"
                thumbnail="https://img.youtube.com/vi/eMGSHRXfctQ/0.jpg"
              />
            </div>
          )} */}
        </div>

        <div className="presentation-video-sticky-button-container">
          <button className="presentation-video-sticky-button" onClick={onClose}>
            {t("confirm-btn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoBeingVerifiedModalComponent;
