import { Input, Layout, message, Typography } from "antd";
import HeaderTitlePage from "../../../../components/HeaderTitlePage";
import { useTranslation } from "react-i18next";
import PriceIcon from "../../../../assets/icons/price-icon.svg";
import TitleIcon from "../../../../assets/icons/title-icon.svg";
import RightIcon from "../../../../assets/icons/edit-collection-right-chevron.svg";
import BottomIcon from "../../../../assets/icons/edit-collection-bottom-chevron.svg";
import { useEffect, useState } from "react";
import PriceInputComponent from "../../../../components/PriceInputComponent";
import { useNavigate, useParams } from "react-router-dom";
import StickyButtonComponent from "../../../../components/StickyButtonComponent";
import { MAX_PRICE } from "../../../../helpers/constant";
import { collectionService } from "../../../../services/collection.service";
import "./index.less";

const CreatorEditCollectionPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { collectionId, title, price } = useParams();
  const [isPriceOpen, setIsPriceOpen] = useState<boolean>(false);
  const [isTitleOpen, setIsTitleOpen] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState<string>(title || "");
  const [newPrice, setNewPrice] = useState<number | null>(price ? parseFloat(price) : null);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPriceInputFocused, setIsPriceInputFocused] = useState<boolean>(false);
  const [isTitleInputFocused, setIsTitleInputFocused] = useState<boolean>(false);

  useEffect(() => {
    const isTitleChanged = newTitle !== (title || "");
    const isPriceChanged = newPrice !== (price ? parseFloat(price) : null);
    setHasChanges(isTitleChanged || isPriceChanged);
  }, [newTitle, newPrice, title, price]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(e.target.value);
  };

  const handlePriceChange = (value: number | null) => {
    if (value !== null) {
      setNewPrice(value);
      if (value > MAX_PRICE) {
        message.warning(t("maximum-price-alert"));
        setNewPrice(null);
      }
    }
  };

  const getUpdatedData = () => {
    return {
      collectionId: collectionId || "",
      title: newTitle,
      price: newPrice,
    };
  };

  const handleSaveChanges = async () => {
    if (!collectionId) {
      message.error(t("collection-id-missing"));
      return;
    }

    setIsLoading(true);

    try {
      const updatedData = getUpdatedData();
      await collectionService.editCollection(collectionId, updatedData.title, updatedData.price);
      message.success(t("successful-modification"));
      navigate(-1);
    } catch (error) {
      console.error("Error updating collection:", error);
      message.error(t("something-want-wrong"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Layout.Content className="creator-edit-collection-page-wrapper background-black">
        <HeaderTitlePage title={t("modification")} fontSize="24px" />

        <div className="edit-collection-item-container mt-25" onClick={() => setIsPriceOpen(!isPriceOpen)}>
          <img src={PriceIcon} className="edit-collection-item-icon" alt="Price" />
          <div className="edit-collection-item-right-container">
            <div className="edit-collection-text-and-chevron-container">
              <div className="edit-collection-item-text-container">
                <Typography.Text className="edit-collection-item-text-title">{t("price")}</Typography.Text>
                <Typography.Text className="edit-collection-item-text-description">{t("modify-the-price-of-your-collection")}</Typography.Text>
              </div>
              <img src={isPriceOpen ? BottomIcon : RightIcon} className="edit-collection-chevron-icon" alt="Open" />
            </div>

            {isPriceOpen && (
              <div className="edit-collection-input-container" onClick={(e) => e.stopPropagation()}>
                <Typography.Text className="edit-collection-item-input-title">{t("enter-your-new-price")}</Typography.Text>
                <div className="input-and-button-container">
                  <PriceInputComponent
                    displayTitle={false}
                    handleCustomPriceChange={handlePriceChange}
                    customPrice={newPrice}
                    onFocus={() => setIsPriceInputFocused(true)}
                    onBlur={() => setIsPriceInputFocused(false)}
                  />
                  {isPriceInputFocused && <button className="confirm-input-button-container">{t("validate")}</button>}{" "}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="edit-collection-item-container mt-14" onClick={() => setIsTitleOpen(!isTitleOpen)}>
          <img src={TitleIcon} className="edit-collection-item-icon" alt="Price" />
          <div className="edit-collection-item-right-container">
            <div className="edit-collection-text-and-chevron-container">
              <div className="edit-collection-item-text-container">
                <Typography.Text className="edit-collection-item-text-title">{t("title")}</Typography.Text>
                <Typography.Text className="edit-collection-item-text-description">{t("edit-collection-title")}</Typography.Text>
              </div>
              <img src={isTitleOpen ? BottomIcon : RightIcon} className="edit-collection-chevron-icon" alt="Open" />
            </div>

            {isTitleOpen && (
              <div className="edit-collection-input-container" onClick={(e) => e.stopPropagation()}>
                <Typography.Text className="edit-collection-item-input-title">{t("enter-your-new-title")}</Typography.Text>
                <div className="input-and-button-container">
                  <Input
                    className="edit-collection-title-input"
                    placeholder={t("title")!}
                    value={newTitle}
                    onChange={handleTitleChange}
                    onFocus={() => setIsTitleInputFocused(true)}
                    onBlur={() => setIsTitleInputFocused(false)}
                  />
                  {isTitleInputFocused && <button className="confirm-input-button-container">{t("validate")}</button>}{" "}
                </div>
              </div>
            )}
          </div>
        </div>

        <StickyButtonComponent title={t("save")} handleClickButton={handleSaveChanges} disabled={!hasChanges} isLoading={isLoading} />
      </Layout.Content>
    </Layout>
  );
};

export default CreatorEditCollectionPage;
