import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import FlagImg from "../../../../../assets/images/flags.webp";
import { FileDiscoverDto } from "../../../../../helpers/types";
import { useDisableScroll } from "../../../../../utls/PresentationVideoUtils";
import VideoOfPresentationVideoStatusComponent from "../VideoOfPresentationVideoStatusComponent/VideoOfPresentationVideoStatusComponent";
import "../index.less";

interface VideoRejectedModalProps {
  isOpen: boolean;
  description: string;
  rejectedFileExplanation?: FileDiscoverDto;
  onClose: () => void;
  deleteVideo: () => void;
  editVideo: () => void;
}

const VideoRejectedModalComponent: React.FC<VideoRejectedModalProps> = ({
  isOpen,
  description,
  rejectedFileExplanation,
  onClose,
  deleteVideo,
  editVideo,
}) => {
  const { t } = useTranslation();

  useDisableScroll(isOpen);

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleEditVideo = () => {
    onClose();
    editVideo();
  };

  const handleDeleteVideo = () => {
    onClose();
    deleteVideo();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="presentation-video-modal-overlay no-scroll" onClick={handleOverlayClick}>
      <div className="presentation-video-modal-content" onClick={handleModalClick}>
        <div className="presentation-video-modal-content-wrapper">
          <div className="w-full d-flex justify-content-center">
            <div className="formation-modal-line-header"></div>
          </div>
          <div className="w-full d-flex justify-content-center">
            <img src={FlagImg} className="presentation-video-modal-img m-30" alt="Under verification" />
          </div>
          <Typography.Text className="presentation-video-modal-title font-size-20">{t("video-refused-title")}</Typography.Text>
          <div className="presentation-video-modal-text-container">
            <Typography.Text className="presentation-video-modal-text">
              {/* {t("our-presentation-video-rules-1")} <span className="white text-underline">{t("our-presentation-video-rules-2")}.</span> */}
              {t("our-presentation-video-rules-1")} {t("our-presentation-video-rules-2")}.
            </Typography.Text>

            <Typography.Text className="presentation-video-modal-text">{t("reason-for-refusal-desc-1")}</Typography.Text>
          </div>

          <div className="reason-for-refusal-container">
            <div className="reason-for-refusal-text-container">
              <Typography.Text className="presentation-video-modal-title font-size-20">{t("reason-for-refusal")}</Typography.Text>
              <Typography.Text className="presentation-video-modal-text align-start">{description}</Typography.Text>
              {rejectedFileExplanation && (
                <Typography.Text className="presentation-video-modal-secondary-title mt-20">{t("video-explaining-the-refusal")}</Typography.Text>
              )}
            </div>
            {rejectedFileExplanation && (
              <VideoOfPresentationVideoStatusComponent
                src={rejectedFileExplanation?.url}
                thumbnail={rejectedFileExplanation?.thumbnails.find((thumb: any) => thumb.type === "CLEAR").absolutePath}
              />
            )}
          </div>
        </div>

        <div className="presentation-video-sticky-button-container">
          <button className="presentation-video-sticky-button" onClick={handleEditVideo}>
            {t("edit-your-video")}
          </button>

          <div className="w-full d-flex justify-content-center" onClick={handleDeleteVideo}>
            <Typography.Text className="presentation-video-modal-cancel-button">{t("delete-video")}</Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoRejectedModalComponent;
