import { Spin } from "antd";

import { CloudUploadOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import ExhaustedIcon from "../../../../../assets/icons/exhausted.svg";
import TrashIcon from "../../../../../assets/icons/presentation-video-trash.svg";
import RejectedIcon from "../../../../../assets/icons/rejected.svg";
import { DISCOVER_EXPIRED, DISCOVER_PENDING, DISCOVER_REJECTED, DISCOVER_TO_UPLOAD, DISCOVER_UPLOADING } from "../../../../../helpers/constant";
import { DiscoverStatus } from "../../../../../helpers/types";
import SpinnerComponent from "../../../../SpinnerComponent/SpinnerComponent";
import PresentationVideoStatusComponent from "./PresentationVideoStatusComponent";
import "./index.less";

type VideoUploadItemProps = {
  index: string;
  src: string;
  isSelected: boolean;
  status: DiscoverStatus;
  onSelect: (index: string) => void;
  onDeselect: () => void;
  onRemove: (uid: string) => void;
};

const VideoUploadItemComponent: React.FC<VideoUploadItemProps> = ({ index, src, isSelected, status, onSelect, onDeselect, onRemove }) => {
  const { t } = useTranslation();

  const statusInfo = {
    [DISCOVER_REJECTED]: {
      icon: RejectedIcon,
      title: t("refused"),
      description: t("refused-presentation-video-status-desc"),
    },
    [DISCOVER_EXPIRED]: {
      icon: ExhaustedIcon,
      title: t("exhaustion"),
      description: t("exhaustion-presentation-video-status-desc"),
    },
    [DISCOVER_PENDING]: {
      icon: undefined,
      title: t("waiting-for-validation-1"),
      description: t("pending-presentation-video-status-desc"),
    },
    [DISCOVER_TO_UPLOAD]: {
      icon: <CloudUploadOutlined className="status-icon" />,
      title: "",
      description: "",
    },
  } as Record<DiscoverStatus, { icon?: string | React.ReactNode; title?: string; description?: string }>;

  const handleClickTrash = (uid: string) => {
    onRemove(uid);
  };

  const handleClickItem = () => {
    if (isSelected) {
      onDeselect();
    } else {
      onSelect(index);
    }
  };

  return (
    <Spin spinning={status === DISCOVER_UPLOADING} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <div key={index} className={`video-upload-thumbnail-container ${isSelected ? "selected" : ""}`} onClick={handleClickItem}>
        <img src={src} className="video-upload-thumbnail" alt={`Video ${index}`} />
        {isSelected && (
          <div>
            <div className="presentation-video-thumbnail-overlay"></div>
            <img src={TrashIcon} className="trash-icon" alt="trash" onClick={() => handleClickTrash(index)} />
          </div>
        )}

        {[DISCOVER_REJECTED, DISCOVER_EXPIRED, DISCOVER_PENDING, DISCOVER_TO_UPLOAD].includes(status) && !isSelected && (
          <PresentationVideoStatusComponent
            icon={statusInfo[status]?.icon}
            title={statusInfo[status]?.title}
            description={statusInfo[status]?.description}
            status={status}
          />
        )}
      </div>
    </Spin>
  );
};

export default VideoUploadItemComponent;
