import { LoadingOutlined } from "@ant-design/icons";
import "./index.less";

type StickyButtonProps = {
  title: string;
  disabled?: boolean;
  backgroundGreen?: boolean;
  isLoading?: boolean;
  handleClickButton: () => void;
};

const StickyButtonComponent: React.FC<StickyButtonProps> = ({
  title,
  disabled = false,
  backgroundGreen = false,
  isLoading = false,
  handleClickButton,
}) => {
  return (
    <div className="sticky-bottom-button-container">
      <button className={`sticky-bottom-button ${backgroundGreen ? "background-green" : ""}`} onClick={handleClickButton} disabled={disabled}>
        {isLoading ? (
          <div className="spinner-container">
            <LoadingOutlined style={{ fontSize: 30, color: "#000000" }} spin />
          </div>
        ) : (
          title
        )}
      </button>
    </div>
  );
};

export default StickyButtonComponent;
