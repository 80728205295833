import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import "dayjs/locale/es";
import { useTranslation } from "react-i18next";
import { IDiscoverDto } from "../../../../helpers/types";
import languageUtil from "../../../../utls/LanguageUtil";
import { useEffect, useState } from "react";
import ToIcon from "../../../../assets/icons/to.svg";
import "./index.less";

const HistoryPresentationVideoComponent = ({ discoversHistory }: { discoversHistory: IDiscoverDto[] }) => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(languageUtil.getCurrentLanguage());

  useEffect(() => {
    const lang = languageUtil.getCurrentLanguage();
    setCurrentLang(lang);
    dayjs.locale(lang);
  }, [i18n.language]);

  return (
    <div className="history-presentation-video-wrapper">
      <div className="history-presentation-video-title">{t("video-history")}</div>
      <div className="history-presentation-video-container">
        {discoversHistory.map((item) => {
          const percentage = item?.stats?.views ? (item.stats.clicks / item.stats.views) * 100 : 0;
          return (
            <div key={item._id} className="history-presentation-video-row">
              <img
                src={item.file.thumbnails.find((thumb: any) => thumb.type === "CLEAR")?.absolutePath}
                alt={`Video ${item._id}`}
                className="history-presentation-video-avatar"
              />
              <div className="history-presentation-video-info-container">
                <div className="history-presentation-video-date">
                  {dayjs(item.createdAt).format("DD MMMM YYYY")} <img src={ToIcon} className="to-icon" alt="to" />{" "}
                  {dayjs(item.updatedAt).format("DD MMMM YYYY")}
                </div>
                <div className="history-presentation-video-data-container">
                  <div className="history-presentation-video-data-text">
                    <span className="green">{item.stats.views}</span> {t("views")}
                  </div>
                  <div className="history-presentation-video-data-text">
                    <span className="green">{item.stats.clicks}</span> {t("clicks")}
                  </div>
                  <div className="history-presentation-video-data-text">
                    <span className={`${percentage < 20 ? "orange" : "green"}`}>{percentage.toFixed(2)}</span> {t("%")}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HistoryPresentationVideoComponent;
