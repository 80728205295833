import { Col, Layout, Radio, Row, Space, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SubscribeIcon from "../../../assets/icons/fan/payment/v.svg";
import PremiumIcon from "../../../assets/icons/logo-black.svg";
import LeftArrowIcon from "../../../assets/icons/new-arrow-left.svg";
import CollectionIcon from "../../../assets/icons/subscribe-collection.svg";
import PhotosIcon from "../../../assets/icons/subscribe-photos.svg";
import PrivateContentIcon from "../../../assets/icons/subscribe-private-content.svg";
import PersoContentIcon from "../../../assets/icons/subscribe-perso-content.svg";
import ChatIcon from "../../../assets/icons/subscribe-chat.svg";
import LiveIcon from "../../../assets/icons/subscribe-live.svg";
import CheckIcon from "../../../assets/icons/check-grey.svg";
import FanSubscribeTypeRadioButtonComponent from "../../../components/FanSubscribeTypeRadioButtonComponent";
import { AppDispatch, StoreState } from "../../../redux/store";
import { getProfileById } from "../../../redux/users/usersSlice";
import { userService } from "../../../services";
import StickyButtonComponent from "../../../components/StickyButtonComponent";
import "./index.less";

const FanSubscribePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const shouldFetch = useRef(true);
  const { creatorId } = useParams();
  const [searchParams] = useSearchParams("redirectUrl");
  const isFromCollection = searchParams.get("isFromCollection");
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: StoreState) => state.users.item);
  const [planId, setPlanId] = useState("monthly");

  useEffect(() => {
    userService
      .getCreatorMonetizations(creatorId)
      .then((res) => {
        const monetizationData = res as {
          monthlyPrice: 0;
          trimestrialPrice: 0;
          half_yearlyPrice: 0;
          yearlyPrice: 0;
        };
        setSubscribeData(monetizationData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (creatorId && shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(getProfileById(creatorId));
    }
  }, [creatorId]);

  const [subscribeData, setSubscribeData] = useState({
    monthlyPrice: 0,
    trimestrialPrice: 0,
    half_yearlyPrice: 0,
    yearlyPrice: 0,
  });
  const subscribeTypes = [
    {
      name: t("monthly-label"),
      price: subscribeData.monthlyPrice ? subscribeData.monthlyPrice : 0,
      value: "monthly",
      type: "monthly",
    },
    {
      name: `${t("three-months-label")}`,
      price: subscribeData.trimestrialPrice ? subscribeData.trimestrialPrice : 0,
      value: "Three Months",
      type: "trimestrial",
    },
    {
      name: `${t("six-months-label")}`,
      price: subscribeData.half_yearlyPrice ? subscribeData.half_yearlyPrice : 0,
      value: "Six months",
      type: "half-yearly",
    },
    {
      name: ` ${t("yearly-label")}`,
      price: subscribeData.yearlyPrice ? subscribeData.yearlyPrice : 0,
      value: " Yearly",
      type: "yearly",
    },
  ];

  const subscribeList = [
    { text: t("access-to-my-collection"), icon: CollectionIcon },
    { text: t("unfold-my-profile-pictures"), icon: PhotosIcon },
    { text: t("receive-exclusive-private-content"), icon: PrivateContentIcon },
    { text: t("chat-with-me-for-free"), icon: ChatIcon },
    { text: t("access-my-lives"), icon: LiveIcon },
    { text: t("request-personalized-content-from-me"), icon: PersoContentIcon },
  ];

  const handlePlanSelection = (plan: { type: string }) => {
    setPlanId(plan.type);
  };

  const goToPaymentPage = () => {
    const url = searchParams.get("redirectUrl") !== undefined ? searchParams.get("redirectUrl") : `common/profile/${creatorId}`;
    navigate(`/common/payment?creatorId=${creatorId}&type=subscription&planId=${planId}&isFromCollection=${isFromCollection}&redirectUrl=${url}`, {
      replace: true,
    });
  };

  return (
    <Layout>
      <Layout.Content className="content fanSubscribeContentWrap gradient-background-black">
        <Col>
          <div className="fanSubscribeHeaderImg" style={{ backgroundImage: `url(${user?.avatar})` }}>
            <img className="fanSubscribeHeaderLeftArrow" width={30} src={LeftArrowIcon} alt="arrow left" onClick={() => navigate(-1)} />
            <div className="fanSubscribeHeaderUserInfo">
              <Typography.Text className="fullname">{user?.name}</Typography.Text>
            </div>
          </div>
          <Row className="mainContent mt-16">
            <Row className="gap-8 items-center mt-4">
              <img src={PremiumIcon} width={20} alt="premium" />
              <Typography.Text className="premiumLabel">{t("premium")}</Typography.Text>
            </Row>
            <Row className="mt-10">
              <Typography.Text className="font-28-bold text-white-color">
                {isFromCollection ? t("unlock-my-collection") : t("subscribe-for-an-exclusive-relationship")}
              </Typography.Text>
            </Row>
            <Radio.Group className="w-full mt-5" defaultValue={planId}>
              <Row gutter={14}>
                {subscribeTypes.map((item, index) => {
                  return item.price ? (
                    <Col onClick={() => handlePlanSelection(item)} span={8} key={index} className="mt-10">
                      <FanSubscribeTypeRadioButtonComponent key={index} name={item.name} price={item.price} value={item.value} />
                    </Col>
                  ) : (
                    ""
                  );
                })}
              </Row>
            </Radio.Group>
            <div>
              <Row className="subscribeListHeader">
                <Typography.Text className="subscribeListHeaderLabel">{t("by-subscribing-you-can")}</Typography.Text>
              </Row>
              <Row className="subscribeListContent">
                {subscribeList.map((item, index) => {
                  return (
                    <Row className="subscribe-list-row" key={index}>
                      <div className="subscribe-list-left-side">
                        <img src={item.icon} width={30} alt="subscribe" />
                        <Typography.Text className="subscribeItemLabel">{item.text}</Typography.Text>
                      </div>
                      <img src={CheckIcon} width={15} alt="check" />
                    </Row>
                  );
                })}
              </Row>
            </div>
            <StickyButtonComponent title={t("continue")} handleClickButton={goToPaymentPage} backgroundGreen={true} />
          </Row>
        </Col>
      </Layout.Content>
    </Layout>
  );
};

export default FanSubscribePage;
