import { ILocalAudioTrack, ILocalVideoTrack, IRemoteAudioTrack, IRemoteVideoTrack } from "agora-rtc-sdk-ng";

interface ISelectType {
  label: string;
  value: string;
}

interface ICreatorStats {
  totalFeeds?: number | null;
  totalCollections?: number | null;
  subscribers?: number | null;
  followers?: number | null;
}

interface IUser {
  _id?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  username?: string;
  email?: string;
  country?: string;
  phoneCode?: string;
  countryCode?: string;
  phone?: string;
  gender?: string;
  dateOfBirth?: string | null;
  description?: string;
  city?: string;
  password?: string;
  type?: string;
  avatar?: string;
  cover?: string | null;
  verifiedEmail?: boolean;
  stats?: ICreatorStats;
  hasDiscovers?: boolean;
  expiredDiscovers?: boolean;
  sponsorId?: string | null;
  paymentFrequency?: number | null;
  creatorId?: string | null;
  verifiedAccount?: boolean;
  lang?: string | null;
  payout_status?: boolean | null;
  billingAddress?: IBillingAddress;
  phoneModalShown?: boolean | null;
  notification?: any;
  defaultPage?: string;
  chat?: string;
}

interface IBillingAddress {
  companyName?: string;
  address?: string;
  city?: string;
  postalCode?: number;
  country?: string;
  vatNumber?: string;
}

type IMonetization = {
  monthly?: boolean;
  monthlyPrice?: number | null;
  trimestrial?: boolean;
  trimestrialPrice?: number | null;
  half_yearly?: boolean;
  half_yearlyPrice?: number | null;
  yearly?: boolean;
  yearlyPrice?: number | null;
};

type ICountry = {
  name: string;
  dial_code: string;
  code: string;
  flag: string;
};

type IMessage = {
  _id?: string;
  conversationId?: string;
  senderId?: string;
  receiverId?: string;
  mediaPushId?: string;
  type?: string;
  text?: string;
  status?: string;
  medias?: Media[];
  price?: string;
  createdAt?: string;
  updatedAt?: string;
  testSub?: boolean;

  mediaPushPrice?: {
    promo: boolean;
    price: number;
    resultPrice: number;
    expiresOn: Date;
    expireTimeType: string;
  };
};

interface IPromotion {
  _id?: string;
  code?: string;
  discountType?: string; // ["PERCENTAGE", "CURRENCY"]
  discount?: number;
  promoOn?: string; // ["SUBSCRIBERS", "MEDIA_PUSH"]
  expireTime?: number | null;
  expireTimeType?: string | null; // ["HOURS", "DAYS", "MONTHS", "YEARS", "UNLIMITED"]
  isActive?: boolean;
}

interface IFeedFile {
  _id?: string;
  id?: string;
  url?: string;
  path?: string;
  thumbnails?: IThumbnail[];
  status?: string;
}

interface IFeedCreatorInfo {
  _id?: string;
  name?: string;
  avatar?: string;
  username?: string;
  verifiedAccount?: boolean;
}

interface IFeed {
  creatorIds: never[];
  _id?: string;
  type?: string;
  sourceId?: string;
  source?: string;
  title?: string;
  slug?: string;
  text?: string;
  fileIds?: string[];
  pollIds?: any[];
  totalLike?: number;
  createdAt?: string;
  updatedAt?: string;
  isPrivate?: boolean;
  pollExpiredAt?: string;
  status?: string;
  creatorInfo?: IFeedCreatorInfo;
  files?: IFeedFile[];
  isLiked?: boolean;
  isBookMarked?: boolean;
  isPrivateForCurrentUser?: boolean;
  thumbnails?: IThumbnail[];
  data?: any;
}

interface IThumbnail {
  type: string;
  path: string;
  absolutePath: string;
}

interface IMediapush {
  isConversation?: boolean;
  conversationId?: string;
  segmentation?: string[];
  receivers?: string[];
  targetsNumber?: number;
  fileIds?: Media[];
  text?: string;
  price?: number;
  promo?: boolean;
  discount?: number;
  discountType?: string;
  expireTime?: number;
  expireTimeType?: string;
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

interface IBankingSetting {
  currency?: string;
  status?: string;
  firstName?: string;
  lastName?: string;
  bankName?: string;
  bankSwiftCode?: string;
  bankAccount?: string;
  country?: string;
  city?: number;
  address?: string;
  postalCode?: string;
  byDefault?: boolean;
  transfersId?: number;
  estimatedDeliveryDate?: Date;
  creatorId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

type Media = {
  fileId: string;
  url: string;
  isPrivate?: boolean;
  name?: string;
  thumbnails?: any;
};

type ISegmentations = {
  subscribers: number;
  followers: number;
  oldFollowers: number;
};

interface IFile {
  _id?: string;
  success?: boolean;
  type?: string;
  name?: string;
  description?: string;
  mimeType?: string;
  server?: string;
  size?: number;
  absolutePath?: string;
  path?: string;
  thumbnails?: IThumbnail[];
  refItems?: string[];
  metadata?: {};
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface IReaction {
  action: string;
  objectType: string;
  objectId: string;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
  activated?: boolean;
}

type IPayoutRequest = {
  _id?: string;
  source?: string;
  price?: number;
  netprice?: number;
  tokenConversionRate?: number;
  paymentAccountType?: string;
  refId?: string;
  clearedAt?: Date;
  sourceId?: string;
  requestNote?: string;
  bankId?: string;
  status?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

interface IQuickMessage {
  _id?: string;
  title?: string;
  text?: string;
}

interface ILive {
  _id?: string;
  access?: string;
  amount?: number | null;
  isFreeForSubscribers?: boolean;
  isChatSubscribers?: boolean;
  isChatAll?: boolean;
  startType?: string;
  scheduleData?: IScheduleData;
  sessionId?: string;
  creator?: {
    _id: string;
    name: string;
    username: string;
    avatar: string;
    verifiedEmail: boolean;
    firstName: string;
    lastName: string;
  };
  stats?: {
    members?: number;
    likes: number;
  };
  streamingTime?: number;
  isStreaming?: boolean;
  totalPrice?: number;
  reservations?: number;
  tipPrice?: number;
  isSubscribed?: boolean;
  isSuggested?: boolean;
  isFollowed?: boolean;
  isPaymentVerified?: boolean;
  date?: any;
  time?: any;
  isFromMobile?: boolean;
  isEndStreaming?: boolean;
  dailyRestQuotaTime?: number;
  isRTMP?: boolean;
  currentChallenge?: ILiveChallenge | null;
  top3Tips?: ITop3Tips[];
}

interface ILiveComment {
  streamId?: string;
  user?: IUser;
  text?: string;
}

interface ILiveTip {
  _id: string;
  streamId: string;
  user: IUser | string;
  amount: number;
}

interface ITop3Tips {
  _id: string;
  amount: number;
  username: string;
}

interface ILiveChallenge {
  _id: string;
  title: string;
  collectedAmount: number;
  price: number;
}
interface IStreamChallengeRequest {
  streamId: string;
  title: string;
  price: number;
}

interface IScheduleData {
  dateTime: string | null;
  message: string | null;
  media: string | IFile | null;
}

interface IState {
  loading: boolean;
  error: string | null;
}

interface IMonetizationState extends IState {
  list: IMonetization[] | null;
  item: IMonetization | null;
}

interface IUserState {
  list: IUser[];
  item: IUser | null;
  currentUserProfile: IUser | null;
  loading: boolean;
  error: string | null;
  recentList: IUser[] | null;
  total?: number;
  currentCategory?: string;
}

interface IFeedState {
  list: IFeed[];
  currentUserFeeds: IFeed[];
  homeFeeds: IFeed[];
  bookmarkFeeds: IFeed[];
  item: IFeed;
  totalPages: number;
  currentUserLoading: LoadingType;
  loading: LoadingType;
  loadingHome: LoadingType;
  loadingBookmarkFeeds: LoadingType;
  error: any;
  errorBookmarkFeeds: any;
  total: any;
}

interface IMediapushState extends IState {
  list: IMediapush[] | null;
  item: IMediapush | null;
  files: any[];
  quickFiles: any[];
  pendingMediaFiles: any[];
}

interface IFileState extends IState {
  list: IFile[] | null;
  item: IFile | null;
}

interface ILiveState extends IState {
  list: ILive[];
  todayScheduleList: ILive[];
  upcomingScheduleList: ILive[];
  rtmpLive: ILive | null;
  discoverList: ILive[];
  suggestedList: ILive[];
  item: ILive | null;
  token: string | null;
  isNewLive: boolean;
}

interface ILiveCommentState extends IState {
  list: ILiveComment[] | null;
  item: ILiveComment | null;
}

interface IReactionState extends IState {
  list: IReaction[] | null;
  item: IReaction | null;
}

interface IGlobalState {
  users: IUserState;
  feeds: IFeedState;
  mediapushes: IMediapushState;
}

interface IActionType {
  type: string;
  error?: string;
}

interface IUserActionType extends IActionType {
  data?: IUser[] | IUser;
}

interface IFeedActionType extends IActionType {
  data?: IFeedState[] | IFeed;
}

interface IMediapushActionType extends IActionType {
  data?: IMediapush[] | IMediapush;
}

interface IFileActionType extends IActionType {
  data?: IFile[] | IFile;
}

interface IConversationDTO {
  _id?: string;
  members?: [
    {
      userId: string;
      name: string;
      avatar: string;
      role: string; // ["creator", "fan"]
    },
  ];
  isMuted?: boolean;
  fanCanSendMessage?: boolean;
  fanCanSendHelloMessage?: boolean;
  hasNewMessages?: boolean;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
  undeliveredMessage?: IMessage;
  conversationType?: string;
  isToDeliver?: string;
  lastMessage?: string;
  chat?: string;
}

interface IConversationData {
  type: string;
  data: IConversation[];
  totalPages: number;
  total: number;
  page: number;
  lastDataLoad: number;
}

interface IMessageData {
  conversationId: string;
  messages: IMessage[];
  page: number;
  totalPages: number;
  total: number;
}

interface IGalleryMediaData {
  conversationId: string;
  medias: Media[];
  shouldUpdate: boolean;
}

interface IConversation {
  _id?: string;
  currentUser?: {
    username?: string;
    avatar?: string;
    userId?: string;
    name?: string;
    role?: string;
    isVerified?: boolean;
  };
  interlocutor?: {
    username?: string;
    avatar?: string;
    userId?: string;
    name?: string;
    role?: string;
    isVerified?: boolean;
  };
  fanCanSendMessage?: boolean;
  fanCanSendHelloMessage?: boolean;
  hasNewMessages?: boolean;
  createdAt?: string;
  updatedAt?: string;
  isNewMessage?: boolean;
  undeliveredMessage?: IMessage;
  lastMessage?: string;
  chat?: string;
}

type OnlineUsers = {
  userId: string;
  socketId: string;
};

interface ISwitchConversationType {
  conversationId: string;
  from: string;
  to: string;
  isToDeliver: boolean;
}

interface ISwitchUndeliveredMessage {
  conversationId: string;
  type: string;
  undeliveredMessage: IMessage;
}

type VerifySubscriptionPayload = {
  fanId: string | undefined;
  creatorId: string | undefined;
};

interface ISearchLivesPayload {
  startType: string | null;
}

interface IAgoraTrack {
  tracks: Array<ILocalVideoTrack | IRemoteVideoTrack | ILocalAudioTrack | IRemoteAudioTrack>;
}

interface PageableData<T> {
  data: T[];
  total: number;
  totalPages: number;
}

type ModalMediaType = {
  amount: string;
  customAmount: string;
  message: string;
};

type MediaPushPrice = {
  promo: boolean;
  price: number;
  resultPrice: number;
  expiresOn: Date;
};

interface IMediapushHistory {
  mediaPushId: string;
  createdAt: string;
  totalEarn: number;
  sale: number;
  totalSend: number;
  targetPeople: number;
}

interface ISponsorship {
  link?: string;
  totalGrossPrice?: number;
  report?: [
    {
      creator?: {
        _id: string;
        username: string;
        createdAt: string;
        avatarPath: string;
        firstName: string;
        lastName: string;
      };
      earning: number;
    },
  ];
}

interface IGetMediaPush {
  totalSend: number;
  text: string;
  price: number;
}

interface IVerifySubscription {
  data: boolean;
}

interface IVerifyFollow {
  data: Object;
}

interface IRevenue {
  weeklyTotalEarnings: any;
  oneMonthEarning: any;
  oneYearEarning: any;
  totalEarnings: any;
  nextInstallment: number;
  waitingForValidation: number;
  totalNetPrice: number;
  previousMonthEarn: number;
  statsData: [
    {
      count: number | string;
      label: number | string;
    },
  ];
  monthlyPreviousReport: [
    {
      year: number;
      month: number;
      total_cost_month: number;
      stats: string;
    },
  ];
  dailyPreviousReport: [
    {
      day: number;
      month: string;
      total_cost_month: number;
      stats: string;
    },
  ];
  detailPaymentReport: [
    {
      netPrice: number;
      isValidate: boolean;
      createdAt: string;
      transactionId: string;
      type: string;
    },
  ];
  todayEarnings: number;
}

interface IDetailedRevenueCategory {
  subscription: number;
  newSubscription: number;
  rebillSubscription: number;
  privateMediaPush: number;
  publicMediaPush: number;
  stream: number;
  streamTips: number;
  decrypt: number;
  collection: number;
  totalEarning: number;
}

interface IDecrypt {
  title: string;
  file: any;
  url: string;
  price: number;
  fanPrice: number;
  creatorPrice: number;
  isMultipleLink: boolean;
  numberMaxOfUnlocks: number;
  isInfiniteUnlocks: boolean;
  isVideo: number;
}

interface IDecryptDTO {
  _id?: string;
  numberMaxOfUnlocks?: number;
  isInfiniteUnlocks?: boolean;
  status?: string;
  files: {
    fileId: string;
    url: string;
    thumbnails: IThumbnail[];
  }[];
  price?: number;
  fanPrice?: number;
  creatorPrice?: number;
  isMultipleLink?: boolean;
  createdBy?: string;
  createdAt?: string;
  numberOfUnlocks?: number;
  numberOfClicks?: number;
  expiresOn?: string;
  slug?: string;
  title?: string;
  totalEarning?: number;
}

interface IDecryptDetailsDTO {
  previousReport?: any;
  totalEarnings?: number;
  decrypt?: IDecryptDTO;
}

interface PageableData<T> {
  data: T[];
  total: number;
  totalPages: number;
}

interface ICodePromo {
  discountType: string;
  promoOn: string;
  isActive: true;
  expireTimeType: string;
  _id: string;
  code: string;
  discount: number;
  expireTime: number;
  userId: string;
  createdAt: string;
  updatedAt: string;
  expiresOn: null;
  __v: number;
}

interface IStripeLivePayload {
  liveId: string | null;
  cardNumber: number;
  expiryMonth: number;
  expiryYear: number;
  cvv: number;
  paymentGateway: string;
  country: any;
  cardHolderName: any;
  savedCard?: any;
  isCardToken?: boolean;
  cardToken?: any;
  cardType?: any;
  token?: any;
}

interface IStripeLiveTipPayload {
  liveTipId: string | null;
  cardNumber: number;
  expiryMonth: number;
  expiryYear: number;
  cvv: number;
  paymentGateway: string;
  country: any;
  cardHolderName: any;
  savedCard?: any;
  isCardToken?: boolean;
  cardToken?: any;
  cardType?: any;
  token?: any;
}

interface IBookmarks {
  _id: string;
  objectId: string;
  createdBy: string;
  objectInfo: {
    _id: string;
    type: string;
    sourceId: string;
    source: string;
    fileIds: [string];
    totalLike: number;
    isPrivate: boolean;
    files: [
      {
        _id: string;
        type: string;
        name: string;
        description: string;
        mimeType: string;
        absolutePath: string;
      },
    ];
  };
}

interface IFanFeed {
  _id: string;
  totalLike: number;
  sourceId: string;
  isPrivate: boolean;
  creatorInfo: {
    username: string;
    avatar: string;
  };
  files: [
    {
      _id: string;
      absolutePath: string;
    },
  ];
  isLiked: boolean;
  isBookMarked: boolean;
  isPrivateForCurrentUser: boolean;
}
interface IAllSubscription {
  id: string;
  status: string;
  creatorInfo: {
    id: string;
    avatar: string;
    name: string;
    username: string;
  };
}

interface IVerifyPromoCode {
  discountType: string;
  promoOn: string;
  isActive: boolean;
  numberOfUses: number;
  expireTimeType: string;
  _id: string;
  code: string;
  discount: number;
  expireTime: number;
  userId: string;
}

interface ILiveTipState extends IState {
  tips: ITipResponse | null;
}

interface ITipResponse {
  username: string;
  name: string;
  avatar?: string;
  id: string;
  amount: string;
}

interface ILiveStreamStats extends IState {
  stats: IStreamStatsResponse | null;
}

interface IStreamStatsResponse {
  members: number;
  likes: number;
}

interface IQuickFileState extends IState {
  list: IAlbumState[];
  item: any | null;
  folderSize: boolean;
}

interface IAlbumState {
  id: string;
  name: string;
  filesNumber: number;
}

interface IAlbum {
  albumName: string;
}

interface ICreatorSuggestion {
  index: number;
  items: ICreatorSuggestionSection[];
}

interface ICreatorSuggestionSection {
  _id: string;
  rank: number;
  type: number;
  creator: Partial<IUser> & { followed: boolean };
}
interface ICreatorSuggestionState {
  list: ICreatorSuggestion[];
  suggestionByTypeList: Map<number, ICreatorSuggestionSection[]>;
  directLinks: IDirectLinks[];
  totalPages: number;
  loading: LoadingType;
  error: any;
}

interface IErrorResponse {
  error: string;
  message: string;
  statusCode: number;
}

interface SavedCardPayload {
  cardHolderName?: string;
  cardNumber?: number;
  expiryMonth?: number;
  expiryYear?: number;
  cvv?: number;
  cardType?: string;
}

interface IAllSavedCards {
  expiryDate: string;
  maskedToken: string;
  cardHolderName: string;
  id: string;
  issuer: string;
}

interface IHelpContactRequest {
  subject: string;
  description: string;
  userId: string;
  target: string;
}

interface GetFeedsParams {
  creatorId: string;
  skip?: number;
  limit?: number | null; // Adjust type as per your API or service requirements
}

interface IDecryptDownloadLink {
  url: string;
  creator: {
    id: string;
    avatar: string;
    name: string;
    username: string;
  };
}

type FilePrivacy = {
  fileId: string;
  isPrivate: boolean;
};

export interface GenerateSignedUrlsDto {
  partNumbers: number;
  uploadId: string;
  filename: string;
}

export interface UploadResponse {
  uploadId: string;
}

export interface ChunkUploadResponse {
  ETag: string;
}
export interface UploadedPart {
  ETag: string;
  PartNumber: number;
}

interface ICollection {
  _id?: string;
  title?: string;
  description?: string;
  price?: number;
  mediaPush?: Partial<IMediapush> | undefined;
  isMediaPush?: boolean;
  files?: [];
}

interface ICollections {
  _id: string;
  creatorId: string;
  title: string;
  price: number;
  thumbnail: string;
  filesCount: number;
  isHidden: boolean;
  order: number;
  createdAt: Date;
  updatedAt: Date;
}
interface ICreatorCollection {
  _id: string;
  title: string;
  description: string;
  price: number;
  createdAt: Date;
  updatedAt: Date;
  files: ICollectionFile[];
  totalPurchases: number;
  totalRevenue: number;
  purchasesHistory: string[];
  imagesCount: number;
  videoCount: number;
}

interface IFanCollection {
  _id: string;
  creatorId: string;
  username: string;
  title: string;
  description: string;
  price: number;
  createdAt: Date;
  updatedAt: Date;
  isPaymentVerified: boolean;
  isSubscribed: boolean;
  files: ICollectionFile[];
  imagesCount: number;
  videoCount: number;
}

interface ICollectionFile {
  url: string;
  thumbnailUrl: string;
  type: string;
  isPrivate: boolean;
  isThumbnail: boolean;
}

interface IDeleteResponse {
  success: boolean;
  _id: string;
}

interface ICollectionsState {
  creatorCollections: ICollections[];
  fanCollections: ICollections[];
  total: number;
  loadingCreatorCollections: LoadingType;
  loadingFanCollections: LoadingType;
  errorCreatorCollections: any;
  errorFanCollections: any;
}

interface IDirectLinks {
  _id: number;
  name: string;
  link: string;
}

type INotification = {
  mediaPush: boolean;
  stream: boolean;
  scheduleStream: boolean;
  reminderStream: boolean;
};

interface FileId {
  id: string;
  isPrivate: boolean;
  url: string;
}

interface IAutomationField {
  fileIds?: FileId[];
  text?: string;
  price?: number;
  promo?: boolean;
  discount?: number;
  discountType?: string;
  status?: any;
  id?: any;
  image?: string;
  title: string;
}

interface IVideoUpload {
  Key: string;
  Location: string;
  Bucket: string;
  ETag: string;
  size: number;
  filename: string;
}

interface FileDiscoverDto {
  _id: string;
  thumbnails: any;
  url: string;
  videoUrl: string;
}

interface UserDiscoverDto {
  _id: string;
  username: string;
  name: string;
  avatar: string;
}

interface FeedDiscoverDto {
  _id: string;
  file: FileDiscoverDto;
}

interface IDiscoverDto {
  _id: string;

  status: DiscoverStatus;

  rejectedReason: {
    reason: string;
    description: string;
    fileId: string;
    file?: FileDiscoverDto;
  };

  file: FileDiscoverDto;

  isVisible: boolean;

  stats: { views: number; subscribers: number; clicks: number };

  createdAt: string;

  updatedAt: string;
}

interface IFanDiscoverDto {
  _id: string;
  user: UserDiscoverDto;
  file: FileDiscoverDto;
  feeds: FeedDiscoverDto[];
}

interface IDiscoversState {
  creatorDiscovers: IDiscoverDto[];
  fanDiscovers: IFanDiscoverDto[];
  discoversHistory: IDiscoverDto[];
  total: number;
  loadingCreatorDiscovers: LoadingType;
  loadingFanDiscovers: LoadingType;
  errorCreatorDiscovers: any;
  errorFanDiscovers: any;
}

enum EventType {
  CLICKS = "clicks",
  SUBSCRIBERS = "subscribers",
  VIEWS = "views",
}

type LoadingType = "idle" | "pending" | "succeeded" | "failed";

type DiscoverStatus = "REJECTED" | "EXPIRED" | "PENDING" | "APPROVED" | "UPLOADING" | "TO_UPLOAD" | "DELETED";

export {
  DiscoverStatus,
  EventType,
  FeedDiscoverDto,
  FileDiscoverDto,
  FilePrivacy,
  GetFeedsParams,
  IAgoraTrack,
  IAlbum,
  IAlbumState,
  IAllSavedCards,
  IAllSubscription,
  IAutomationField,
  IBankingSetting,
  IBillingAddress,
  IBookmarks,
  ICodePromo,
  ICollection,
  ICollectionFile,
  ICollections,
  ICollectionsState,
  IConversation,
  IConversationData,
  IConversationDTO,
  ICountry,
  ICreatorCollection,
  ICreatorSuggestion,
  ICreatorSuggestionSection,
  ICreatorSuggestionState,
  IDecrypt,
  IDecryptDetailsDTO,
  IDecryptDownloadLink,
  IDecryptDTO,
  IDeleteResponse,
  IDetailedRevenueCategory,
  IDirectLinks,
  IDiscoverDto,
  IDiscoversState,
  IErrorResponse,
  IFanCollection,
  IFanDiscoverDto,
  IFanFeed,
  IFeed,
  IFeedActionType,
  IFeedState,
  IFile,
  IFileActionType,
  IFileState,
  IGalleryMediaData,
  IGetMediaPush,
  IGlobalState,
  IHelpContactRequest,
  ILive,
  ILiveChallenge,
  ILiveComment,
  ILiveCommentState,
  ILiveState,
  ILiveStreamStats,
  ILiveTip,
  ILiveTipState,
  IMediapush,
  IMediapushActionType,
  IMediapushHistory,
  IMediapushState,
  IMessage,
  IMessageData,
  IMonetization,
  IMonetizationState,
  INotification,
  IPayoutRequest,
  IPromotion,
  IQuickFileState,
  IQuickMessage,
  IReaction,
  IReactionState,
  IRevenue,
  IScheduleData,
  ISearchLivesPayload,
  ISegmentations,
  ISelectType,
  ISponsorship,
  IStreamChallengeRequest,
  IStreamStatsResponse,
  IStripeLivePayload,
  IStripeLiveTipPayload,
  ISwitchConversationType,
  ISwitchUndeliveredMessage,
  IThumbnail,
  ITipResponse,
  ITop3Tips,
  IUser,
  IUserActionType,
  IUserState,
  IVerifyFollow,
  IVerifyPromoCode,
  IVerifySubscription,
  IVideoUpload,
  LoadingType,
  Media,
  MediaPushPrice,
  ModalMediaType,
  OnlineUsers,
  PageableData,
  SavedCardPayload,
  UserDiscoverDto,
  VerifySubscriptionPayload,
};
