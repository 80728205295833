import React from "react";
import SearchIcon from "../../../../../../assets/icons/search-2.svg";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";

type SwiperTopOverlayProps = {
  searchClick: () => void;
};

const SwiperTopOverlayComponent: React.FC<SwiperTopOverlayProps> = ({ searchClick }) => {
  const { t } = useTranslation();

  return (
    <div className="discover-search-bar-wrapper">
      <div className="discover-search-bar" onClick={searchClick}>
        <img src={SearchIcon} width={15} alt="search" />
        <Typography.Text className="discover-search-bar-text">{`${t("search")}`}</Typography.Text>
      </div>
      {/* <div className="share-icon-wrapper" onClick={() => {}}>
        <img src={ShareIcon} alt="share" />
      </div> */}
    </div>
  );
};

export default SwiperTopOverlayComponent;
