import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MicrophoneImg from "../../../../assets/images/microphone.webp";
import "./index.less";

const RenewPresentationComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/creator/create/presentation/videos");
  };

  return (
    <div className="renew-presentation-container">
      <div className="renew-presentation-description-container">
        <Typography.Text className="renew-presentation-title">{t("renew-your-presentation")}</Typography.Text>
        <Typography.Text className="renew-presentation-description">{t("renew-your-presentation-desc")}</Typography.Text>
        <button className="renew-presentation-button" onClick={handleNavigate}>
          {t("here-we-go")}
        </button>
      </div>
      <img src={MicrophoneImg} className="microphone-img" alt="microphone" />
    </div>
  );
};

export default RenewPresentationComponent;
