import { useTranslation } from "react-i18next";
import "./index.less";

const CollectionCancelEditModeButtonComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="cancel-edit-mode-sticky-bottom-container">
      <button className="cancel-edit-mode-sticky-button">{t("cancel")}</button>
    </div>
  );
};

export default CollectionCancelEditModeButtonComponent;
