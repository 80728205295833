import React, { useEffect } from "react";
import { Row, Col, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../../redux/store";
import { getSuggestionByType } from "../../../redux/CreatorSuggestion/CreatorSuggestion";
import AvatarComponent from "../../AvatarComponent";
import "./index.less";

const SearchSuggestionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const suggestions = useSelector((state: StoreState) => state.suggestion.suggestionByTypeList.get(6) || []);

  useEffect(() => {
    if (!suggestions.length) {
      dispatch(getSuggestionByType(6));
    }
  }, []);

  return (
    <div className="recent-search-suggestion-container">
      <Row className="mb-20">
        <Typography.Text className="text-white-color font-16-bold">Suggestions</Typography.Text>
      </Row>
      {suggestions?.map((item, index) => (
        <Row key={index} className="justify-between items-center mt-20">
          <Col
            span={20}
            onClick={() => {
              navigate(`/p/${item?.creator.username}`);
            }}
          >
            <Row className="row-search-suggestion">
              <div>
                <AvatarComponent image={`${item?.creator.avatar}` || ""} size={65} name={""} fontSize={16} fontWeight={true} />
              </div>
              <div className="recent-search-suggestion-text-container">
                <Typography.Text className="recent-search-suggestion-text">{item?.creator.name}</Typography.Text>
              </div>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default SearchSuggestionComponent;
