import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Space, Spin, Tabs, Typography } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ActiveCollectionIcon from "../../../assets/icons/active-collection.svg";
import ActiveGridIcon from "../../../assets/icons/active-grid.svg";
import BookmarkOtherProfileIcon from "../../../assets/icons/bookmark-other-profile.svg";
import ChatIcon from "../../../assets/icons/chat-icon.svg";
import CollectionIcon from "../../../assets/icons/collection.svg";
import GridIcon from "../../../assets/icons/grid.svg";
import VideoIcon from "../../../assets/icons/jouer.svg";
import LocationIcon from "../../../assets/icons/location.svg";
import LockIcon from "../../../assets/icons/lock.svg";
import ShareOtherProfileIcon from "../../../assets/icons/share-other-profile.svg";
import VerifyIcon from "../../../assets/icons/verify.svg";
import AvatarComponent from "../../../components/AvatarComponent";
import CollectionGridComponent from "../../../components/Collections/CollectionGridComponent";
import PublicAuthentificationModalComponent from "../../../components/PublicAuthentificationModalComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import ThreeDotsComponent from "../../../components/ThreeDotsComponent";
import { THUMBNAIL_TYPES } from "../../../helpers/constant";
import { getPublicFeedsByCreatorUsername } from "../../../redux/feeds/feedsSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { getPublicProfileByUsername } from "../../../redux/users/usersSlice";
import { APP_NAME } from "../../../utls/constants";
import "./index.less";

const MAX_NAME_LENGTH = 16;

const PublicProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { username } = useParams();
  const { item: user, error } = useSelector((state: StoreState) => state.users);
  const feeds = useSelector((state: StoreState) => state.feeds.list);
  const feedsIsLoading = useSelector((state: StoreState) => state.feeds.loading);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLongName = user?.name ? user.name.length > MAX_NAME_LENGTH : false;
  const iconSize = isLongName ? "16px" : "22px";
  const pageTitle = user?.username ? `${user.username} ${APP_NAME}` : APP_NAME;
  const pageDescription = username ? t("profile_description", { username, appName: APP_NAME }) : t("app_description", { appName: APP_NAME });
  const [activeTab, setActiveTab] = useState<string>(user?.defaultPage || "collection");

  console.log("user", user);

  useEffect(() => {
    document.title = pageTitle;
    let metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    if (metaDescription) {
      metaDescription.setAttribute("content", pageDescription);
    } else {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = pageDescription;
      document.head.appendChild(metaDescription);
    }

    let metaRobots = document.querySelector('meta[name="robots"]') as HTMLMetaElement;
    if (!metaRobots) {
      metaRobots = document.createElement("meta");
      metaRobots.name = "robots";
      document.head.appendChild(metaRobots);
    }
    metaRobots.setAttribute("content", "noimageindex");

    return () => {
      document.title = APP_NAME;
      if (metaDescription) {
        metaDescription.setAttribute("content", "Loly");
      }
      if (metaRobots) {
        metaRobots.setAttribute("content", "");
      }
    };
  }, [pageTitle, pageDescription]);

  useEffect(() => {
    setActiveTab(user?.defaultPage || "collection");
  }, [user]);

  useEffect(() => {
    if (username) {
      dispatch(getPublicProfileByUsername(username));
      dispatch(getPublicFeedsByCreatorUsername(username));
    }
  }, [username]);

  useEffect(() => {
    if (error === "user-not-found") {
      navigate("/");
    }
  }, [error]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Spin spinning={isLoading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout className={`gradient-background-profile no-scroll-horizontal ${isModalVisible ? "no-scroll-page" : ""}`}>
        <Layout.Content className="content fanProfileContentWrapper background-transparent">
          <>
            <Row className="header-container">
              <img src={ShareOtherProfileIcon} width={18} alt="share" onClick={showModal} />
              <h2 className="header-text-other-profile">@{user?.username}</h2>

              <img src={BookmarkOtherProfileIcon} className="mr-10" width={16} onClick={showModal} alt="follow" />
            </Row>
            <div className="fanProfileTopWrapper">
              <Row align="middle">
                <Col className="colAvatar mr-10" onClick={showModal}>
                  <AvatarComponent image={user?.avatar || ""} size={105} fontSize={16} fontWeight={true} />
                </Col>
                <Col className="colDetails">
                  <Row className="nameRow" align="middle">
                    <Typography.Text className={`nameTitleOtherProfile`}>
                      {error !== "user-not-found" ? <>{user?.name}</> : t(error as string)}
                    </Typography.Text>
                    {user?.verifiedAccount && <img className="verifyIcon" src={VerifyIcon} width={iconSize} alt="verify" />}
                  </Row>

                  <Row className="buttonRow mt-5" align="middle">
                    <Space>
                      <Button className="subscribeBtn" onClick={showModal}>
                        {t("Subscribe-without-obligation")}
                      </Button>
                      <img src={ChatIcon} onClick={showModal} width={44} alt="" />
                    </Space>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="labelWrapper">
              {user?.city && (
                <Row className="m-5">
                  <Col className="colValues location">
                    <img className="locationImg mr-5" src={LocationIcon} width={16} alt="location" />
                    <Typography.Text className="fanProfileValue">{user.city}</Typography.Text>
                  </Col>
                </Row>
              )}
              <Row className="mt-10">
                <div
                  className="fanProfileDescriptionLabel"
                  dangerouslySetInnerHTML={{
                    __html: (user?.description || "").replace(/\n/g, "<br />"),
                  }}
                />
              </Row>

              <Row className="mt-10 max-w-80 justify-content-between" align="middle">
                <div className="leftItems">
                  <Space>
                    <Col className="colValues">
                      <Typography.Text className="fanProfileAttributeValue">{user?.stats?.totalFeeds || 0}</Typography.Text>
                      <Typography.Text className="fanProfileValue">{t("publications")}</Typography.Text>
                    </Col>
                  </Space>

                  <Space>
                    <Col className="colValues">
                      <Typography.Text className="fanProfileAttributeValue">{user?.stats?.totalCollections || 0}</Typography.Text>
                      <Typography.Text className="fanProfileValue">{t("collections")}</Typography.Text>
                    </Col>
                  </Space>
                </div>

                <ThreeDotsComponent onClick={showModal} />
              </Row>
            </div>
          </>
          <Col className="feeds-container">
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              <TabPane tab={<img src={activeTab === "feeds" ? ActiveGridIcon : GridIcon} width={20} alt="publications icon" />} key="feeds">
                <Row className="mt-10 pr-4 pl-4" gutter={2}>
                  {feedsIsLoading === "pending" ? (
                    <div className="w-full divSpin">
                      <Spin size="large" indicator={<LoadingOutlined className="spin" spin />} />
                    </div>
                  ) : (
                    feeds?.map((feed, index) => {
                      if (feed.files) {
                        const lock = feed.isPrivate;
                        const isVideo = feed.type === "video";
                        const feedThumbnail = isVideo
                          ? feed.isPrivateForCurrentUser
                            ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath
                            : feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                          : feed.isPrivateForCurrentUser
                            ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath
                            : feed.files[0].url;
                        return (
                          <Col className="fanProfilePostWrapper" key={index} span={8} onClick={showModal}>
                            <div
                              className={`fanProfilePost fanProfilePrivatePost`}
                              style={{
                                backgroundImage: `url(${feedThumbnail})`,
                              }}
                            />
                            {lock && <img className="fanProfilePostLockIcon" src={LockIcon} alt="lock" />}
                            {isVideo && <img src={VideoIcon} style={!lock ? { right: "11px" } : {}} alt="video icon" className="feedVideoIcon" />}
                          </Col>
                        );
                      }
                      return null;
                    })
                  )}
                </Row>
              </TabPane>

              <TabPane
                tab={<img src={activeTab === "collection" ? ActiveCollectionIcon : CollectionIcon} width={20} alt="collections icon" />}
                key="collection"
              >
                <CollectionGridComponent
                  isMyProfile={false}
                  creatorId={user?._id}
                  isPublic={true}
                  showModal={showModal}
                  goToPublicationTab={() => handleTabChange("feeds")}
                  setCollectionEditMode={() => {}}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Layout.Content>
        <PublicAuthentificationModalComponent avatar={user?.avatar} name={user?.name} visible={isModalVisible} onClose={hideModal} />
      </Layout>
    </Spin>
  );
};

export default PublicProfilePage;
