import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import uniqBy from "lodash/uniqBy";
import { DISCOVER_PENDING, DISCOVER_TO_UPLOAD, DISCOVER_UPLOADING } from "../../helpers/constant";
import { IDiscoverDto, IDiscoversState } from "../../helpers/types";
import { discoverService } from "../../services";

const initialState: IDiscoversState = {
  creatorDiscovers: [],
  fanDiscovers: [],
  discoversHistory: [],
  loadingCreatorDiscovers: "idle",
  loadingFanDiscovers: "idle",
  errorCreatorDiscovers: null,
  errorFanDiscovers: null,
  total: -1,
};

export const getCreatorDiscovers = createAsyncThunk("discovers/getCreatorDiscovers", async () => {
  return await discoverService.getCreatorDiscovers();
});

export const getFanDiscovers = createAsyncThunk("discovers/getFanDiscovers", async ({ page, username }: { page: number; username?: string }) => {
  return await discoverService.getFanDiscovers({ page, username });
});

export const deleteDiscoversById = createAsyncThunk("discovers/deleteDiscover", async (discoverId: string) => {
  return await discoverService.deleteDiscover(discoverId);
});

const discoversSlice = createSlice({
  name: "discovers",
  initialState,
  reducers: {
    updateDiscoverVideos: (state, action) => {
      state.creatorDiscovers.push(action.payload.createdDiscover);
      state.creatorDiscovers = state.creatorDiscovers.filter((discover) => discover._id !== action.payload.deletedDiscover._id);
      if (action.payload.oldDiscover !== null) {
        state.discoversHistory.push(action.payload.oldDiscover);
      }
    },
    updateFile: (state, action) => {
      state.creatorDiscovers = state.creatorDiscovers.map((discover) => {
        if (discover.file._id !== action.payload.fileId) {
          return discover;
        }
        return {
          ...discover,
          status: DISCOVER_PENDING,
          file: {
            ...discover.file,
            _id: action.payload.fileId,
            videoUrl: action.payload.absolutePath,
            url: action.payload.thumbnails.find((thumbnail: any) => thumbnail.type === "CLEAR").absolutePath,
            thumbnails: action.payload.thumbnails,
          },
        };
      });
    },
    addCreatorDiscover: (state, action) => {
      state.creatorDiscovers.push(action.payload);
    },
    updateUploadedCreatorDiscover: (state, action) => {
      state.creatorDiscovers = state.creatorDiscovers.filter((discover) => discover.status !== DISCOVER_TO_UPLOAD);
      const uploadedDiscovers = action.payload.map((discover: IDiscoverDto) => ({ ...discover, status: DISCOVER_UPLOADING }));
      state.creatorDiscovers = state.creatorDiscovers.concat(uploadedDiscovers);
    },
    removeCreatorDiscover: (state, action) => {
      state.creatorDiscovers = state.creatorDiscovers.filter((discover) => discover._id !== action.payload);
    },
    resetFanDiscovers: (state) => {
      state.fanDiscovers = [];
      state.loadingFanDiscovers = "pending";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreatorDiscovers.pending, (state) => {
      state.loadingCreatorDiscovers = "pending";
    });
    builder.addCase(getCreatorDiscovers.fulfilled, (state, action) => {
      state.creatorDiscovers = action.payload.data.discovers;
      state.discoversHistory = action.payload.data.deletedDiscovers;
      state.loadingCreatorDiscovers = "succeeded";
    });
    builder.addCase(getCreatorDiscovers.rejected, (state, action) => {
      state.loadingCreatorDiscovers = "failed";
      state.errorCreatorDiscovers = action.payload || "Something went wrong";
    });
    builder.addCase(getFanDiscovers.pending, (state) => {
      state.loadingFanDiscovers = "pending";
    });
    builder.addCase(getFanDiscovers.fulfilled, (state, action) => {
      if (action.payload.data.length > 0) {
        state.fanDiscovers = uniqBy(state.fanDiscovers.concat(action.payload.data), "_id");
      }
      state.loadingFanDiscovers = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(getFanDiscovers.rejected, (state, action) => {
      state.loadingFanDiscovers = "failed";
      state.errorFanDiscovers = action.payload || "Something went wrong";
    });
    builder.addCase(deleteDiscoversById.pending, (state) => {
      state.loadingCreatorDiscovers = "pending";
    });
    builder.addCase(deleteDiscoversById.fulfilled, (state, action) => {
      const { discover, operation } = action.payload;
      state.creatorDiscovers = state.creatorDiscovers.filter((discovers) => discovers._id !== discover._id);
      state.fanDiscovers = state.fanDiscovers.filter((discovers) => discovers._id !== discover._id);
      if (operation === "update") {
        state.discoversHistory.push(discover);
      }
      state.loadingCreatorDiscovers = "succeeded";
    });
    builder.addCase(deleteDiscoversById.rejected, (state, action) => {
      state.loadingCreatorDiscovers = "failed";
      state.errorCreatorDiscovers = action.payload || "Something went wrong";
    });
  },
});

export const { updateDiscoverVideos, updateFile, addCreatorDiscover, removeCreatorDiscover, updateUploadedCreatorDiscover, resetFanDiscovers } =
  discoversSlice.actions;

export default discoversSlice.reducer;
