import { Layout, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BookmarkFillIcon from "../../assets/icons/bookmark-filled.svg";
import BookmarkIcon from "../../assets/icons/bookmark.svg";
import LikeFillIcon from "../../assets/icons/like-filled.svg";
import LikeIcon from "../../assets/icons/like.svg";
import VerifyIcon from "../../assets/icons/verify.svg";
import { updateBookmarkState, updateLikeState } from "../../redux/feeds/feedsSlice";
import { AppDispatch } from "../../redux/store";
import { reactionService } from "../../services";
import AvatarComponent from "../AvatarComponent";
import CreatorDeletePostModal from "../CreatorDeletePostModal";
import FanReportFeedModalComponent from "../FanReportFeedModalComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import ThreeDotsComponent from "../ThreeDotsComponent";
import VideoPlayer from "../VideoPlayerComponent";
import "./index.less";

type props = {
  feedId: string;
  name: string;
  username: string;
  totalLikes?: number;
  avatar: string;
  creatorId?: string;
  fileUrl?: string;
  thumbnail?: string;
  type: string;
  isLiked?: boolean;
  isBookMarked?: boolean;
  isPrivate?: boolean;
  description: string;
  verifiedAccount?: boolean;
  isCurrentUser: boolean;
  onReportClick?: () => void;
  isFanHomePage?: boolean;
  isUploading?: boolean;
  creatorIds?: any;
};

const MainSwiperSlideItem: React.FC<props> = ({
  feedId,
  name,
  username,
  totalLikes,
  avatar,
  creatorId,
  fileUrl,
  thumbnail,
  type,
  isLiked,
  isBookMarked,
  isPrivate,
  description,
  verifiedAccount,
  isCurrentUser,
  onReportClick,
  isFanHomePage,
  isUploading,
  creatorIds
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [likes, setLikes] = useState(isLiked);
  const [postTotalLikes, setPostTotalLikes] = useState<any>(totalLikes);
  const [bookmarks, setBookmark] = useState(isBookMarked);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setBookmark(isBookMarked);
  }, [isBookMarked]);

  useEffect(() => {
    setLikes(isLiked);
  }, [isLiked]);

  useEffect(() => {
    setPostTotalLikes(totalLikes);
  }, [totalLikes]);

  async function onLikeClick() {
    const payload = {
      action: "like",
      objectId: feedId,
      objectType: "feed",
    };
    if (likes) {
      setLikes(false);
      setPostTotalLikes(postTotalLikes - 1);
      updateFeedLikeState(false);
      await reactionService.deactivatedReaction(payload);
    } else {
      setPostTotalLikes(postTotalLikes + 1);
      setLikes(true);
      updateFeedLikeState(true);
      await reactionService.createReaction(payload);
    }
  }

  async function onBookmarkClick() {
    const payload = {
      action: "bookmark",
      objectId: feedId,
      objectType: "feed",
    };
    if (bookmarks) {
      setBookmark(false);
      updateFeedBookmarkState(false);
      await reactionService.deactivatedReaction(payload);
    } else {
      setBookmark(true);
      updateFeedBookmarkState(true);
      await reactionService.createReaction(payload);
    }
  }

  async function updateFeedBookmarkState(isBookmark: boolean) {
    dispatch(updateBookmarkState({ feedId, isBookmark }));
  }

  async function updateFeedLikeState(isLiked: boolean) {
    dispatch(updateLikeState({ feedId, isLiked }));
  }

  const processDescription = (text : any , creatorIds : any) => {
    const words = text.split(/\s+/);
    return words.map((word : any, index : any) => {
      if (word.startsWith('@') && word.length > 1) {
        const username = word.slice(1);
        const creator = creatorIds.find((creator:any) => creator.username === username);
        if (creator) {
        return <strong key={index} onClick={()=>navigate(`/p/${word.slice(1)}`)}>{word} </strong>;
        }
      }
      return word + ' ';
    })
  }

  return (
    <Layout className="slideItemWrapper">
      <Layout.Header className="slideItemHeader mt-5">
        <Space>
          <AvatarComponent className="cursor-pointer" image={avatar} size={34} onClick={() => navigate(`/p/${username}`)} />
          <Typography.Text className="slideItemNameLabel cursor-pointer" onClick={() => navigate(`/p/${username}`)}>
            {username}
          </Typography.Text>
          {verifiedAccount === true && <img src={VerifyIcon} alt="verify" />}
        </Space>
        <ThreeDotsComponent
          className="cursor-pointer"
          onClick={() => {
            isCurrentUser ? setDeleteModalOpen(true) : setIsReportModalOpen(true);
          }}
        />
      </Layout.Header>
      <Layout.Content className={`${isFanHomePage ? "main-container-img" : ""}`}>
        <Spin spinning={isUploading} className="feedSpinner" indicator={<SpinnerComponent />} />
        <div className="slideItemPostImg" onDoubleClick={onLikeClick}>
          {type === "video" ? (
            fileUrl !== "" && thumbnail !== "" ? (
              <VideoPlayer videoUrl={fileUrl!} thumbnailUrl={thumbnail!} />
            ) : (
              ""
            )
          ) : (
            <img className="postImg" src={fileUrl} alt={description} />
          )}

          {bookmarks ? (
            <img className="slideItemBookmark cursor-pointer" src={BookmarkFillIcon} alt="bookmarkFill" onClick={onBookmarkClick} />
          ) : (
            <img className="slideItemBookmark cursor-pointer" src={BookmarkIcon} alt="bookmark" onClick={onBookmarkClick} />
          )}
        </div>
        <CreatorDeletePostModal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} selectedFeed={feedId} />
        <FanReportFeedModalComponent
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onReportClick={() => {
            setIsReportModalOpen(false);
            onReportClick && onReportClick();
          }}
        />
      </Layout.Content>
      <Layout.Footer className="slideItemFooter">
        <Space className="mb-8">
          {likes ? (
            <img className="slideItemLike cursor-pointer" src={LikeFillIcon} alt="likeFill" onClick={onLikeClick} />
          ) : (
            <img className="slideItemLike cursor-pointer" src={LikeIcon} alt="like" onClick={onLikeClick} />
          )}
          <Typography.Text className="slideItemLikeLabel">
            {t("liked-by")}
            <Typography.Text className="slideItemLikeSubLabel">
              {postTotalLikes} {`${t("person")}${totalLikes! > 1 ? "s" : ""}`}
            </Typography.Text>
          </Typography.Text>
        </Space>
        <br />
        <Typography.Text className="slideItemLikeLabel">
          {name}
          <Typography.Text className="slideItemLikeDescription">{description}</Typography.Text>
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
};

export default MainSwiperSlideItem;
