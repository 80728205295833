import { Col, Layout, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DirectLinksComponent from "../../../components/DirectLinksComponent";
import PresentationVideoSwiper from "../../../components/discover/common/Swiper/PresentationVideoSwiperComponent";
import SwiperGifComponent from "../../../components/discover/common/Swiper/SwiperGifComponent";
import HeaderInfoDiscoverComponent from "../../../components/discover/creator/HeaderInfoDiscoverComponent";
import FooterComponent from "../../../components/FooterComponent";
import IsSearchingComponent from "../../../components/Search/IsSearchingComponent";
import SearchComponent from "../../../components/Search/SearchComponent";
import { FOOTER_IDS } from "../../../helpers/constant";
import { resetFanDiscovers } from "../../../redux/discovers/discoversSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { resetList } from "../../../redux/users/usersSlice";
import { checkModalStatus, updateModalStatus } from "../../../utls/formationModalUtils";
import RecentVisitedProfile from "../RecentVisitedProfile";
import "./index.less";

const DiscoverPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showCancelButton, setShowCancelButton] = useState<boolean>(false);
  const [showOverlayTraining, setShowOverlayTraining] = useState(false);
  const recentRef = useRef<HTMLInputElement | null>(null);
  const [showRecentVisitedProfile, setShowRecentVisitedProfile] = useState(false);
  const { currentUserProfile } = useSelector((state: StoreState) => state.users);
  const creatorHeaderInfoVisible =
    (currentUserProfile?.expiredDiscovers || !currentUserProfile?.hasDiscovers) && currentUserProfile?.type === "creator";

  useEffect(() => {
    checkModalStatus("hasSeenPresentationVideoTutorial", setShowOverlayTraining);
    dispatch(resetFanDiscovers());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (showOverlayTraining) {
        handleCloseOverlay();
      }
    };

    const handleClick = () => {
      if (showOverlayTraining) {
        handleCloseOverlay();
      }
    };

    window.addEventListener("touchmove", handleScroll);
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("click", handleClick);
    };
  }, [showOverlayTraining]);

  const handleCloseOverlay = async () => {
    await updateModalStatus("hasSeenPresentationVideoTutorial", setShowOverlayTraining);
  };

  const handleInputClick = () => {
    setShowRecentVisitedProfile(true);
    setShowCancelButton(true);
  };

  const searchCreators = (value: string) => {
    if (value !== undefined && value !== "") {
      setSearchValue(value);
      setIsSearching(true);
    } else {
      cancelSearching();
    }
  };

  const cancelSearching = () => {
    setSearchValue("");
    setIsSearching(false);
    setShowRecentVisitedProfile(false);
    setShowCancelButton(false);
    dispatch(resetList());
  };

  return (
    <Layout className="discover-content-wrapper gradient-background-black-blue no-scroll-horizontal">
      <Layout.Header className="discover-header-wrapper">
        {showRecentVisitedProfile && (
          <div className="discover-header-is-searching-wrapper">
            <Col span={showCancelButton ? 20 : 24}>
              <SearchComponent
                onClick={handleInputClick}
                placeholder={`${t("search-2")}`}
                handleChange={(e) => searchCreators(e.target.value)}
                value={searchValue}
              />
            </Col>
            {showCancelButton && (
              <Col span={4} className="cancel-text-col">
                <Typography.Text className="cancel-button cursor-pointer" onClick={cancelSearching}>
                  {t("cancel")}
                </Typography.Text>
              </Col>
            )}
          </div>
        )}
      </Layout.Header>
      <Layout.Content className="background-transparent">
        {creatorHeaderInfoVisible && !isSearching && !showRecentVisitedProfile && (
          <HeaderInfoDiscoverComponent renew={currentUserProfile?.expiredDiscovers} />
        )}
        {isSearching ? (
          <div className="padding-inline-container">
            <IsSearchingComponent isSearching={isSearching} searchValue={searchValue} />
          </div>
        ) : (
          !isSearching &&
          showRecentVisitedProfile && (
            <div>
              <div className="direct-links-wrapper">
                <DirectLinksComponent />
              </div>
              <div className="padding-inline-container" ref={recentRef}>
                <RecentVisitedProfile />
              </div>
            </div>
          )
        )}

        <PresentationVideoSwiper
          isVisible={!isSearching && !showRecentVisitedProfile}
          searchClick={handleInputClick}
          creatorHeaderInfoVisible={creatorHeaderInfoVisible}
        />
        {showOverlayTraining && (
          <div className="swiper-gif-overlay">
            <SwiperGifComponent />
          </div>
        )}
      </Layout.Content>
      <FooterComponent page={FOOTER_IDS.SEARCH} black={false} />
    </Layout>
  );
};

export default DiscoverPage;
