import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import uniqBy from "lodash/uniqBy";
import { IUser, IUserState } from "../../helpers/types";
import { userService } from "../../services";

const initialState: IUserState = {
  list: [],
  recentList: null,
  item: null,
  currentUserProfile: null,
  loading: false,
  error: null,
  total: 0,
  currentCategory: "",
};

export const getMyProfile = createAsyncThunk("users/getMyProfile", async () => {
  return userService.getMyProfile();
});

export const getProfileById = createAsyncThunk("users/getProfileById", async (id: string) => {
  return userService.getProfile(id);
});

export const getPublicProfileByUsername = createAsyncThunk("users/getProfileByUsername", async (username: string) => {
  return userService.getPublicProfile(username);
});

export const search = createAsyncThunk("users/search", async (param: any) => {
  return userService.search(param);
});

export const searchMoreUsers = createAsyncThunk("users/searchMoreUsers", async (param: any) => {
  return userService.search(param);
});

export const updateCoverImage = createAsyncThunk("users/updateCoverImage", async (data: FormData) => {
  return userService.updateCoverImage(data);
});

export const updateAvatar = createAsyncThunk("users/updateAvatar", async (data: FormData) => {
  return userService.updateAvatarImage(data);
});

export const updateProfile = createAsyncThunk("users/updateProfile", async (user: IUser) => {
  return userService.updateMyProfile(user);
});

export const getRecentSearch = createAsyncThunk("users/recentSearch", () => {
  return userService.getRecentSearch();
});

export const addRecentSeacrchUser = createAsyncThunk("user/createRecetSearch", (id: String) => {
  return userService.addRecentSeacrchUser(id);
});

export const deleteRecentSerachUser = createAsyncThunk("user/deleteRecentSearch", (id: String) => {
  return userService.deleteRecetSearchUser(id);
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    editProfile: (state, action) => {
      state.currentUserProfile = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    userHasExpiredDiscover: (state, action) => {
      if(state.currentUserProfile !== null){
        state.currentUserProfile.expiredDiscovers = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(search.fulfilled, (state, action) => {
      if (state.total !== action.payload.total) {
        state.list = [...action.payload.data];
      } else {
        state.list = uniqBy([...state.list, ...action.payload.data], "_id");
      }
      state.total = action.payload.total;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(searchMoreUsers.fulfilled, (state, action) => {
      state.list = uniqBy([...state.list, ...action.payload.data], "_id");
      state.total = action.payload.total;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getRecentSearch.fulfilled, (state, action) => {
      state.recentList = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(addRecentSeacrchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(deleteRecentSerachUser.fulfilled, (state, action) => {
      state.recentList = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addMatcher(isAnyOf(getMyProfile.fulfilled), (state, action) => {
      state.currentUserProfile = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addMatcher(isAnyOf(getMyProfile.pending), (state, action) => {
      state.currentUserProfile = null;
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(getMyProfile.rejected), (state, action) => {
      state.currentUserProfile = null;
      state.loading = false;
      state.error = action.error.message || "Something Went Wrong";
    });

    builder.addMatcher(isAnyOf(getProfileById.fulfilled), (state, action) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addMatcher(isAnyOf(getProfileById.pending, search.pending), (state, action) => {
      state.item = null;
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(getProfileById.rejected, search.rejected, searchMoreUsers.rejected, getRecentSearch.rejected), (state, action) => {
      state.item = null;
      state.loading = false;
      state.error = action.error.message || "Something Went Wrong";
    });
    builder.addMatcher(isAnyOf(getPublicProfileByUsername.fulfilled), (state, action) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addMatcher(isAnyOf(getPublicProfileByUsername.pending), (state, action) => {
      state.item = null;
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(getPublicProfileByUsername.rejected), (state, action) => {
      state.item = null;
      state.loading = false;
      state.error = action.error.message || "Something Went Wrong";
    });
    builder.addMatcher(isAnyOf(updateCoverImage.pending, updateAvatar.pending, updateProfile.pending), (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(updateCoverImage.fulfilled, updateAvatar.fulfilled, updateProfile.fulfilled), (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(updateCoverImage.rejected, updateAvatar.rejected, updateProfile.rejected), (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { editProfile, resetList, setCurrentCategory, userHasExpiredDiscover } = usersSlice.actions;

export default usersSlice.reducer;
