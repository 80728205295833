import React from "react";
import VideoPlayerComponent from "../../../../../components/VideoPlayerComponent";
import "../index.less";

interface VideoOfPresentationVideoStatusProps {
  src: string;
  thumbnail: string;
}
const VideoOfPresentationVideoStatusComponent: React.FC<VideoOfPresentationVideoStatusProps> = ({ src, thumbnail }) => {
  return (
    <div className="presentation-video-status-container">
      <div className="presentation-video-status-wrapper">
        {/* <img src={thumbnail} alt="thumbnail" className="presentation-video-status-thumbnail" /> */}
        <VideoPlayerComponent classNameProp="presentation-video-status-thumbnail" videoUrl={src} thumbnailUrl={thumbnail} watermarkText="" />
        {/* <img src={PlayIcon} className="presentation-video-status-play-icon" alt="play" /> */}
      </div>
    </div>
  );
};

export default VideoOfPresentationVideoStatusComponent;
