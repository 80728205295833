import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFanDiscovers } from "../../../../../redux/discovers/discoversSlice";
import { AppDispatch } from "../../../../../redux/store";
import SpinnerComponent from "../../../../SpinnerComponent/SpinnerComponent";
import useFetchDiscovers from "../../../hooks/useFetchDiscovers";
import SwiperItemComponent from "../SwiperItemComponent";
import SwiperTopOverlayComponent from "../SwiperItemComponent/SwiperTopOverlayComponent";
import "./index.less";

type PresentationVideoSwiperProps = {
  searchClick?: () => void;
  username?: string;
  presentationOfSingleCreator?: boolean;
  isVisible?: boolean;
  creatorHeaderInfoVisible?: boolean;
};

const PresentationVideoSwiper: React.FC<PresentationVideoSwiperProps> = ({
  searchClick,
  username,
  presentationOfSingleCreator = false,
  isVisible = true,
  creatorHeaderInfoVisible = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [page, setPage] = useState(1);
  const { fanDiscovers, loadingFanDiscovers, errorFanDiscovers, total } = useFetchDiscovers(page, username);
  const [globalMuteState, setGlobalMuteState] = useState(true);
  const [globalShowMuteButton, setGlobalShowMuteButton] = useState(true);
  const creatorHeaderInfoHeight = creatorHeaderInfoVisible ? 35 : 0;

  const handleMuteChange = (newMuteState: boolean) => {
    setGlobalMuteState(newMuteState);
  };

  const handleShowMuteButtonChange = (newShowMuteButtonState: boolean) => {
    setGlobalShowMuteButton(newShowMuteButtonState);
  };

  const updateHeaderHeight = () => {
    const headerElement = document.querySelector(".header-info-discover-container");
    if (headerElement) {
      const headerHeight = headerElement.getBoundingClientRect().height;
      document.documentElement.style.setProperty("--header-height", `${headerHeight}px`);
    }
  };

  const updateFooterHeight = () => {
    const footerElement = document.querySelector(".footer");
    if (footerElement) {
      const footerHeight = footerElement.getBoundingClientRect().height;
      document.documentElement.style.setProperty("--footer-height", `${footerHeight}px`);
    }
  };

  useEffect(() => {
    updateHeaderHeight();
    updateFooterHeight();

    const headerElement = document.querySelector(".header-info-discover-container");
    if (headerElement) {
      const resizeObserver = new ResizeObserver(updateHeaderHeight);
      resizeObserver.observe(headerElement);
      return () => resizeObserver.disconnect();
    }
  }, []);

  const updateViewportHeight = useCallback(() => {
    // Get the actual viewport height
    const vh = window.innerHeight;
    // Set the viewport height as a CSS variable
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    setViewportHeight(vh);
  }, []);

  useEffect(() => {
    // Initial viewport height update
    updateViewportHeight();

    // Update on resize and orientation change
    window.addEventListener("resize", updateViewportHeight);
    window.addEventListener("orientationchange", () => {
      // Add a small delay to ensure the browser UI has settled
      setTimeout(updateViewportHeight, 100);
    });

    return () => {
      window.removeEventListener("resize", updateViewportHeight);
      window.removeEventListener("orientationchange", updateViewportHeight);
    };
  }, [updateViewportHeight]);

  useEffect(() => {
    if (errorFanDiscovers) {
      message.error(errorFanDiscovers);
    }
  }, [errorFanDiscovers]);

  const loadMoreDiscovers = useCallback(() => {
    const hasMore = fanDiscovers.length < total!;
    if (loadingFanDiscovers === "pending" || !hasMore) return;
    const nextPage = page + 1;
    dispatch(getFanDiscovers({ page: nextPage, username }));
    setPage(nextPage);
  }, [dispatch, loadingFanDiscovers, page, setPage, total, username, fanDiscovers.length]);

  return fanDiscovers.length !== 0 ? (
    <div className={`swiper-wrapper ${isVisible ? "" : "hidden"}`}>
      {searchClick && <SwiperTopOverlayComponent searchClick={searchClick} />}

      <div className="swiper-container">
        <div
          className="swiper"
          style={{
            height: `${viewportHeight - creatorHeaderInfoHeight}px`,
          }}
        >
          {fanDiscovers.map((discover, index) => (
            <div className="swiper-slide" key={discover._id}>
              <SwiperItemComponent
                discover={discover}
                presentationOfSingleCreator={presentationOfSingleCreator}
                index={index}
                total={fanDiscovers.length}
                loadMoreDiscovers={loadMoreDiscovers}
                globalMuteState={globalMuteState}
                handleMuteChange={handleMuteChange}
                globalShowMuteButton={globalShowMuteButton}
                handleShowMuteButtonChange={handleShowMuteButtonChange}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <SpinnerComponent />
    // <div>
    //   {searchClick && <SwiperTopOverlayComponent searchClick={searchClick} />}
    //   <div className="discover-suggestion-wrapper">
    //     <FanHomeSuggestionComponent showSection4={true} />
    //   </div>
    // </div>
  );
};

export default PresentationVideoSwiper;
