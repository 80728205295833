import { Checkbox, Col, InputRef, Layout, Row, Space, Spin, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { userService } from "../../../services";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import AuthInputComponent from "../../../components/AuthInputComponent";
import AuthSelectLanguageComponent from "../../../components/AuthSelectLanguageComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { useLocation } from "react-router-dom";
import "./index.less";

const AuthSignUpGoogleFanPage = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any>(null);
  const [acceptAuthTerms, setAcceptAuthTerms] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const usernameInputRef = useRef<InputRef>(null);
  const location = useLocation();

  useEffect(() => {
    if (usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.userData) {
      setUser(location.state.userData);
    }
  }, [location.state]);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    let filedValue = e.target.value;
    if (e.target.name === "username") {
      filedValue = sanitizeUsername(filedValue)
    }
    setUser({ ...user, [e.target.name]: filedValue });
  };

  const sanitizeUsername = (username: string): string => {
    return username
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9._]/g, "");
  }; 
  

  const updateDateOfBirth = () => {
    if (!user?._id || !user?.email) return;
    if (!user?.firstName) return message.warning(t("first-name-required"));
    if (!user?.lastName) return message.warning(t("last-name-required"));
    if (!user?.username) return message.warning(t("username-required"));
    if (!acceptAuthTerms) return message.warning(t("terms-conditions-error-message"));

    setLoading(true);
    userService
      .updateDateOfBirth(user?._id, user?.email, user?.firstName, user?.lastName, user?.username, user?.dateOfBirth, true, "", "", "")
      .then(async () => {
        await message.success(t("google-register-success-message"));
        setLoading(false);
        window.location.reload();
      })
      .catch((error: any) => {
        setLoading(false);
        message.error(t(error));
      });
  };

  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout className="background-transparent">
        <Layout.Content className="authSignupContentWrapper">
          <Row className="title-row justify-content-center">
            <Typography.Text className="auth-title">{t("auth-signUp-title")}</Typography.Text>
          </Row>
          <Row className="mb-10 mt-20 padding-container">
            <Space className="w-full" direction="vertical">
              <Row gutter={7}>
                <Col span={12}>
                  <AuthInputComponent
                    label={t("first-name-label")}
                    placeholder={t("first-name-label")!}
                    type="text"
                    name="firstName"
                    value={user?.firstName || ""}
                    handleChange={handleChange}
                  />
                </Col>
                <Col span={12}>
                  <div className="">
                    <AuthInputComponent
                      label={t("last-name-label")}
                      placeholder={t("last-name-label")!}
                      type="text"
                      name="lastName"
                      value={user?.lastName || ""}
                      handleChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <AuthInputComponent
                  label={t("user-name-label")}
                  placeholder={t("user-name-label")!}
                  type="text"
                  name="username"
                  value={user?.username?.toLocaleLowerCase() || ""}
                  handleChange={handleChange}
                  ref={usernameInputRef}
                />
              </Row>
              <Row>
                <AuthInputComponent
                  label={t("email-label")}
                  placeholder={t("email-label")!}
                  type="email"
                  name="email"
                  value={user?.email || ""}
                  disabled
                />
              </Row>
              <Row className="mt-11">
                <Checkbox className="authTermsCheckBox" checked={acceptAuthTerms} onChange={(e) => setAcceptAuthTerms(e.target.checked)}>
                  <span>{t("auth-terms-1")}</span>
                  <span className="text-color-green">{t("auth-terms-2")}</span>
                  <span>{t("auth-terms-3")}</span>
                  <a href="https://loly.app/conditions-generales-de-vente/" target="_blank">
                    {t("terms-and-conditions")}
                  </a>
                  {t("and")}
                  <a href="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank">
                    {t("privacy-policy")}
                  </a>
                </Checkbox>
              </Row>
              <Row className="mt-14">
                <AuthButtonComponent label={t("continue-label")} onClick={updateDateOfBirth} />
              </Row>
            </Space>
          </Row>
          <Row className="w-full authRowFooter mt-20">
            <AuthSelectLanguageComponent />
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default AuthSignUpGoogleFanPage;
