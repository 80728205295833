import React, { useEffect, useState, memo } from "react";
import SelectedIcon from "../../assets/icons/edit-selected-switch.svg";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { IUser } from "../../helpers/types";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import "./index.less";

enum SwitchOption {
  Feeds = "feeds",
  Collection = "collection",
  Subscriber = "subscriber",
  Free = "free",
}

type Option = {
  value: SwitchOption;
  label: string;
  description: string;
};

type EditProfileSwitchProps = {
  forChat: boolean;
  changedItem: (field: keyof IUser, value: string | null) => void;
};

const EditProfileSwitchComponent: React.FC<EditProfileSwitchProps> = ({ forChat, changedItem }) => {
  const { t } = useTranslation();
  const user = useSelector((state: StoreState) => state.users.currentUserProfile);
  const [selectedOption, setSelectedOption] = useState<string>(
    forChat ? user?.chat || SwitchOption.Subscriber : user?.defaultPage || SwitchOption.Feeds
  );

  useEffect(() => {
    const newSelectedOption = forChat ? user?.chat : user?.defaultPage;
    if (newSelectedOption !== selectedOption) {
      setSelectedOption(newSelectedOption || (forChat ? SwitchOption.Subscriber : SwitchOption.Feeds));
    }
  }, [forChat, user?.chat, user?.defaultPage]);

  const fieldMap: Record<SwitchOption, keyof IUser> = {
    [SwitchOption.Feeds]: "defaultPage",
    [SwitchOption.Collection]: "defaultPage",
    [SwitchOption.Subscriber]: "chat",
    [SwitchOption.Free]: "chat",
  };

  const handleSwitchClick = (option: SwitchOption) => {
    setSelectedOption(option);
    const field = fieldMap[option];
    changedItem(field, option);
  };

  const options: Option[] = forChat
    ? [
        { value: SwitchOption.Subscriber, label: t("subscriber-chat"), description: t("chat-subscriber-description") },
        { value: SwitchOption.Free, label: t("free-chat"), description: t("chat-free-description") },
      ]
    : [
        { value: SwitchOption.Feeds, label: t("grid-post"), description: t("default-page-grid-description") },
        { value: SwitchOption.Collection, label: t("collection"), description: t("default-page-collection-description") },
      ];

  const selectedOptionData = options.find((option) => option.value === selectedOption);

  return (
    <div className="edit-profile-switch-wrapper">
      <div className="edit-profile-switch-container">
        {options.map((option) => (
          <div
            key={option.value}
            className={`edit-profile-switch ${selectedOption === option.value ? "selected" : ""}`}
            onClick={() => handleSwitchClick(option.value)}
            role="button"
            tabIndex={0}
            aria-pressed={selectedOption === option.value}
          >
            {selectedOption === option.value && <img src={SelectedIcon} className="selected-icon" alt="Selected" />}
            <Typography.Text className={`edit-profile-switch-text ${selectedOption === option.value ? "white" : ""}`}>{option.label}</Typography.Text>
          </div>
        ))}
      </div>
      <div className="edit-profile-text-container">
        <Typography.Text className="edit-profile-text">{selectedOptionData ? selectedOptionData.description : ""}</Typography.Text>
      </div>
    </div>
  );
};

export default memo(EditProfileSwitchComponent);
