import { Col, Row, Space, Typography } from "antd";
import SpinnerComponent from "../../SpinnerComponent/SpinnerComponent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, StoreState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addRecentSeacrchUser, search, searchMoreUsers } from "../../../redux/users/usersSlice";
import useInfiniteScroll from "react-infinite-scroll-hook";
import "./index.less";

const NB_USER_TO_SHOW = 15;

type IsSearchingProps = {
  isSearching: boolean;
  searchValue: string;
};

const IsSearchingComponent: React.FC<IsSearchingProps> = ({ isSearching, searchValue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { list: creators, total, loading } = useSelector((state: StoreState) => state.users);
  const [seeAllResults, setSeeAllResults] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    if (searchValue !== "") {
      const payload = {
        q: searchValue,
        limit: NB_USER_TO_SHOW,
      };
      const delayDebounceFn = setTimeout(() => {
        dispatch(search(payload));
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchValue]);

  const onProfileClick = (id: string, username: string) => {
    if (id !== "") {
      dispatch(addRecentSeacrchUser(id));
      navigate(`/p/${username}`);
    }
  };

  function loadMoreUsers() {
    if (isSearching) {
      const nextPage = page + 1;
      const totalPages = Math.ceil(total! / NB_USER_TO_SHOW);
      const payload = {
        q: searchValue,
        limit: NB_USER_TO_SHOW,
        offset: page * NB_USER_TO_SHOW,
      };
      dispatch(searchMoreUsers(payload));
      if (nextPage >= totalPages) {
        setHasNextPage(false);
      }
      setPage(nextPage);
    }
  }

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: loadMoreUsers,
  });

  return (
    <div className="w-full">
      {creators &&
        creators.map((creator, index) => {
          return (index < NB_USER_TO_SHOW && !seeAllResults) || seeAllResults ? (
            <Col span={24}>
              <Row
                key={creator._id}
                onClick={() => onProfileClick(creator._id !== undefined ? creator._id : "", creator.username!)}
                className="justify-between items-center mt-10"
              >
                <Col span={24}>
                  <Row className="items-center">
                    <img
                      className="avatar"
                      src={creator.avatar !== undefined ? creator.avatar : ""}
                      alt={creator.avatar !== undefined ? creator.avatar : ""}
                    />
                    <Space direction="vertical" className="recentVisitedTextWrapper">
                      <Typography.Text className="UserName">{t(`${creator.username !== undefined ? creator.username : ""}`)}</Typography.Text>
                      <Typography.Text className="FirstName">{creator.name}</Typography.Text>
                    </Space>
                  </Row>
                </Col>
              </Row>
            </Col>
          ) : null;
        })}

      {creators && !seeAllResults && total! > NB_USER_TO_SHOW ? (
        <Row className={`mt-16 mx-auto ${seeAllResults ? "d-none" : ""}`}>
          <Typography.Text
            onClick={() => {
              setSeeAllResults(true);
              setHasNextPage(true);
            }}
            className="SearchSeeAllResults mx-auto"
          >
            {t("see-all-results")}
          </Typography.Text>
        </Row>
      ) : null}

      {(loading || hasNextPage) && seeAllResults && <SpinnerComponent refProp={sentryRef} />}
    </div>
  );
};

export default IsSearchingComponent;
