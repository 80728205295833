import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ChevronBackIcon from "../../../assets/icons/back-chevron-presentation-video.svg";
import ShareIcon from "../../../assets/icons/share-presentation-video.svg";
import PresentationVideoSwiper from "../../../components/discover/common/Swiper/PresentationVideoSwiperComponent";
import SlideBottomOverlayFanComponent from "../../../components/discover/fan/SlideBottomOverlayFanComponent";
import ShareModalComponent from "../../../components/ShareModalComponent";
import { StoreState } from "../../../redux/store";
import "./index.less";

const PresentationVideoOfCreatorPage = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const { fanDiscovers } = useSelector((state: StoreState) => state.discovers);
  const user = fanDiscovers.length > 0 ? fanDiscovers[0].user : undefined;

  useEffect(() => {
    document.documentElement.style.setProperty("--footer-height", `0px`);
    document.documentElement.style.setProperty("--header-height", `0px`);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="creator-presentation-video-wrapper">
      <div className="creator-presentation-video-header">
        <img src={ChevronBackIcon} alt="back" onClick={handleBack} />
        <img
          src={ShareIcon}
          alt="share"
          onClick={() => {
            setIsShareModalOpen(true);
          }}
        />
      </div>

      <PresentationVideoSwiper username={username} presentationOfSingleCreator={true} />
      {user && <SlideBottomOverlayFanComponent user={user} />}

      <ShareModalComponent isOpen={isShareModalOpen} onClose={() => setIsShareModalOpen(false)} id={user?._id} username={username} />
    </div>
  );
};

export default PresentationVideoOfCreatorPage;
