import { Button, InputRef, Row, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MediaIcon from "../../../../../assets/icons/media-2.svg";
import Input from "rc-input";
import { IConversation, UserDiscoverDto } from "../../../../../helpers/types";
import { authService, messageService } from "../../../../../services";
import { CREATOR, FAN } from "../../../../../helpers/constant";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { getConversationById, resetUserConversations, selectConversation } from "../../../../../redux/messages/messagesSlice";
import "./index.less";

type SendMessageInputProps = {
  user: UserDiscoverDto;
};

const SendMessageInputComponent: React.FC<SendMessageInputProps> = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  //const [value, setValue] = useState<string>("");
  // const inputRef = React.useRef<InputRef>(null);

  // const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
  //   setValue(e.currentTarget.value);
  // };

  // const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === "Enter") {
  //     handleOnClick(e);
  //   }
  // };

  // const handleMediaClick = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   // if (onMediaClick) {
  //   //   onMediaClick();
  //   // }
  // };

  const handleStartConversation = async () => {
    const time = new Date();
    console.log("start conversation time :>> ", time, time.getTime());
    if (!user) {
      return;
    }
    const currentUserRole = authService.getRole();
    let urlToNavigate = currentUserRole === CREATOR ? "/creator/conversation/new" : "/fan/requestMedia/new";
    const currentUserId = authService.getId();
    const conversationId = await messageService.isConversationExist({
      fanId: currentUserId,
      creatorId: user._id !== undefined ? user._id : "",
    });
    if (conversationId === null) {
      const newConversation: IConversation = {
        _id: "new",
        fanCanSendMessage: true,
        interlocutor: {
          userId: user._id,
          avatar: user.avatar,
          name: user.name,
          role: CREATOR,
        },
        currentUser: {
          userId: currentUserId,
          avatar: "",
          name: "",
          role: FAN,
        },
      };
      dispatch(selectConversation(newConversation));
      urlToNavigate = urlToNavigate + `?newConversation=${JSON.stringify(newConversation)}`;
    } else {
      dispatch(resetUserConversations());
      dispatch(getConversationById(conversationId));
      urlToNavigate = urlToNavigate.replace("new", conversationId);
    }
    console.log("navigate time :>> ", new Date(), new Date().getTime() - time.getTime());
    navigate(urlToNavigate, { replace: false });
  };

  // const handleOnClick = (e: any) => {
  //   if (!value.trim()) return;
  //   e.preventDefault();
  //   e.stopPropagation();

  //   // messageService.sendMessageFromDiscover({
  //   //   receiverId: user._id,
  //   //   message: value
  //   // });

  //   // if (onSendClick) {
  //   //   onSendClick(value);
  //   //   setValue("");
  //   //   inputRef.current?.focus();
  //   // }
  // };
  console.log("user :>> ", user);
  return (
    // <Input
    //   ref={inputRef}
    //   className="presentation-video-message-input"
    //   placeholder={`${t("send-message-to")} ${user.name}`}
    //   suffix={
    //     value.trim() ? (
    //       <Typography.Text className="message-send-label" onClick={handleOnClick}>
    //         {t("send")}
    //       </Typography.Text>
    //     ) : (
    //       <img src={MediaIcon} width={18} alt="media" onClick={handleMediaClick} />
    //     )
    //   }
    //   value={value}
    //   onChange={handleChange}
    //   onKeyUp={handleOnKeyUp}
    // />
    <Button className={`presentation-video-message-button`} onClick={handleStartConversation}>
      {t("send-message-to") + " " + user.name}
    </Button>
  );
};

export default SendMessageInputComponent;
