import { message, Spin, Typography, Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PlusIcon from "../../../../../assets/icons/plus-black.svg";
import TrashIcon from "../../../../../assets/icons/presentation-video-trash.svg";
import { IDiscoverDto, IVideoUpload } from "../../../../../helpers/types";
import { updateDiscoverVideos } from "../../../../../redux/discovers/discoversSlice";
import { AppDispatch } from "../../../../../redux/store";
import { discoverService, storageService } from "../../../../../services";
import { checkVideoDuration, generateThumbnailURL } from "../../../../../utls/FunctionsUtil";
import { useDisableScroll } from "../../../../../utls/PresentationVideoUtils";
import SpinnerComponent from "../../../../SpinnerComponent/SpinnerComponent";
import "../index.less";
import { DISCOVER_EXPIRED, DISCOVER_REJECTED } from "../../../../../helpers/constant";
import { userHasExpiredDiscover } from "../../../../../redux/users/usersSlice";

interface UploadVideoModalProps {
  isOpen: boolean;
  isNewVideo: boolean;
  onClose: () => void;
  showVerificationModal: () => void;
  selectedDiscover: IDiscoverDto | null;
  discovers: IDiscoverDto[];
}

const UploadVideoModalComponent: React.FC<UploadVideoModalProps> = ({ isOpen, onClose, isNewVideo, showVerificationModal, selectedDiscover, discovers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isTrashVisible, setTrashVisible] = useState(false);
  const trashIconRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [enableSubmitBtn, setEnableSubmitBtn] = useState(false);

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  useDisableScroll(isOpen);

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleImageClick = () => {
    setTrashVisible((value) => !value);
  };

  async function handleMediaUpload() {
    if (fileList.length === 0) {
      message.error(t("no-video-selected"));
      return;
    }
    setLoading(true);
    try {
      const videoFileToChunk: any[] = [];
      const videoFiles: IVideoUpload[] = [];

      fileList.forEach((file: UploadFile) => {
        if (file.status !== "removed") {
          videoFileToChunk.push(file);
        }
      });

      if (videoFileToChunk.length > 0) {
        const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
        setTotalSize(totalSizes);

        for (let i = 0; i < videoFileToChunk.length; i++) {
          const file = videoFileToChunk[i];
          try {
            const result = await storageService.uploadFileMultiPart(file, setLoadedData, totalSizes, "discover");
            videoFiles.push({
              Location: result.Location,
              Key: result.Key,
              ETag: result.ETag,
              Bucket: result.Bucket,
              filename: file.name,
              size: file.size,
            });
          } catch (error: any) {
            // Show error message for individual file upload failure
            console.log("error.message", error.message);
            message.error(t(error.message));
            setLoading(false);
            return; // Exit the function entirely
          }
        }
      }

      const result = await discoverService.updateDiscoverVideos({ id: selectedDiscover?._id as string, videoFiles }, setProgress);
      result.createdDiscover = {
        ...result.createdDiscover,
        file: {
          thumbnails: [
            {
              type: "CLEAR",
              absolutePath: fileList[0].url,
            },
          ],
        },
      };
      result.deletedDiscover = { _id: selectedDiscover?._id };
      dispatch(updateDiscoverVideos(result));
      console.log('discovers -- ', discovers);
      
      if(discovers) {
        const hasExpiredOrRejectedDiscover = discovers.some((discover) => (discover.status === DISCOVER_EXPIRED || discover.status === DISCOVER_REJECTED) && discover._id !== selectedDiscover?._id);
        dispatch(userHasExpiredDiscover(hasExpiredOrRejectedDiscover));
      }
      
      setFileList([]);
      onClose();
      showVerificationModal();
      setLoading(false);
      setProgress(0);
    } catch (error: any) {
      console.log("error.message", error.message);
      message.error(error.message || "Failed to upload media to album");
    } finally {
      setLoading(false);
    }
  }

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      if (file.type.includes("video/")) {
        const isValidDuration = await checkVideoDuration(file);
        if (!isValidDuration) {
          message.error(t("video-duration-error") || "Video must be 1 minute or less");
          return Upload.LIST_IGNORE;
        }
        handleConvertedFile(file.uid, file);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      removeUnsupportedFiles(file.uid);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleConvertedFile = async (uid: string, file: File) => {
    //console.log("uid, converted, file :>> ", uid, converted, file);
    const fileObj: UploadFile = {
      uid: uid,
      name: file.name,
      status: "done",
      type: file.type,
      size: file.size,
      originFileObj: file as RcFile,
    };
    if (file.type.includes("video")) {
      const result = await generateThumbnailURL(file as RcFile);
      fileObj.url = result;
    }
    setFileList((prevList) => [fileObj, ...prevList.filter((file) => file.uid !== fileObj.uid)]);
    setEnableSubmitBtn(true);
  };

  const isImageUrl = (): boolean => {
    return true;
  };

  const removeUnsupportedFiles = (uid: string) => {
    setFileList((prevList) => [...prevList.filter((file) => file.uid !== uid)]);
  };

  const removeFile = () => {
    setFileList([]);
    setEnableSubmitBtn(false);
  };

  const customItemRender = () => {
    return <></>;
  };

  const props: UploadProps = {
    accept: "video/*",
    action: "",
    itemRender: customItemRender,
    fileList: fileList,
    maxCount: 1,
    beforeUpload: beforeUpload,
    isImageUrl: isImageUrl,
    showUploadList: {
      showRemoveIcon: true,
      showPreviewIcon: false,
    },
  };

  return (
    <div className="presentation-video-modal-overlay no-scroll" onClick={handleOverlayClick}>
      <div className="presentation-video-modal-content" onClick={handleModalClick}>
        <Spin
          spinning={loading}
          style={{ maxHeight: "100%" }}
          indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
        >
          <div className="presentation-video-modal-content-wrapper">
            <div className="w-full d-flex justify-content-center">
              <div className="formation-modal-line-header"></div>
            </div>

            <Typography.Text className="presentation-video-modal-title font-size-20">
              {isNewVideo ? t("import-new-video-click-plus-button") : t("import-compliant-video-click-plus-button")}
            </Typography.Text>

            <div className="video-upload-modal-container">
              {fileList.length === 0 ? (
                <Upload {...props}>
                  <div className="video-upload-rectangle-shape">
                    <div className="add-circle-white">
                      <img src={PlusIcon} width={70} alt="plus" />
                    </div>
                  </div>
                </Upload>
              ) : (
                <div>
                  <img src={fileList[0].url} className="video-upload-modal-thumbnail" alt="thumbnail" onClick={handleImageClick} />
                  {isTrashVisible && (
                    <div ref={trashIconRef}>
                      <img src={TrashIcon} className="trash-icon" alt="trash" onClick={removeFile} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="presentation-video-sticky-button-container">
            <button className="presentation-video-sticky-button" onClick={handleMediaUpload} disabled={loading || !enableSubmitBtn}>
              {t("confirm")}
            </button>

            <div className="w-full d-flex justify-content-center" onClick={onClose}>
              <Typography.Text className="presentation-video-modal-cancel-button">{t("cancel")}</Typography.Text>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default UploadVideoModalComponent;
