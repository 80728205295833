import { Button, Row, Space, Spin, Typography, UploadFile } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StatusBlackIcon from "../../assets/icons/status-circle-black.svg";
import { ACCEPTED, FILE, MAX_MEDIAS_TO_SEND, PENDING, REJECTED, THUMBNAIL_TYPES } from "../../helpers/constant";
import { IMessage } from "../../helpers/types";
import { mediapushService, storageService } from "../../services";
import { socket } from "../../socket";
import AddMediaComponent from "../AddMediaComponent";
import MediaComponent from "../MediaComponent";
import MessagePriceComponent from "../MessagePriceComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./index.less";
import ConversationMediaComponent from "../AddMediaComponent/ConversationMediaComponent";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { setPendingMediaFiles, setSelectFiles } from "../../redux/mediaPush/mediaPushSlice";
import { generateURL } from "../../utls/FunctionsUtil";
import { RcFile } from "antd/es/upload";

type props = {
  message: IMessage;
  expired?: boolean;
  hideStatus?: boolean;
  isBlur?: boolean;
  displayVideo?: boolean;
};

const MessageMediaItemComponent: React.FC<props> = ({ message, expired, hideStatus, isBlur = true, displayVideo = false }) => {
  const { t } = useTranslation(); 
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);
  const { pendingMediaFiles } = useSelector((state: StoreState) => state.mediaPushes);
  const dispatch = useDispatch()
  useEffect(() => {
    function onProgressUpdate(data: number) {
      setProgress((prev) => {
        const prog = prev + 1;
        if (prog <= 99) {
          return prog;
        }
        return prev;
      });
    }

    function onProcessEnd() {
      setLoading(false);
    }

    socket.on("file-updated", onProcessEnd);
    socket.on("progress-update", onProgressUpdate);

    return () => {
      socket.off("file-updated", onProcessEnd);
      socket.off("progress-update", onProgressUpdate);
    };
  }, []);

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress / 2);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  const handleFilesChange = async (fileList: UploadFile<[]>[]) => {
  
    // Merge the files and filter duplicates (assuming unique by 'uid' or 'name')
    const uniqueFiles = [...pendingMediaFiles, ...fileList].filter(
      (value, index, self) =>
        self.findIndex((v) => v.uid === value.uid) === index
    );
  
    // Process files asynchronously
    const updatedFiles = await Promise.all(
      uniqueFiles.map(async (item) => {
        if (item.imgType !== "quick-file") {
          const src = await generateURL(item.originFileObj as RcFile);
          return { ...item, thumbUrl : src };
        }
        return item;
      })
    );
  
    // Update state and dispatch actions
    dispatch(setPendingMediaFiles({ files: updatedFiles }));
    setFiles(updatedFiles);
  };
  

  const handleSendMedias = async () => {
    if (!isEmpty(files) && !loading) {
      setLoading(true);
      const formData = new FormData();
      formData.set("messageId", message._id!);
      formData.set("mediaPushId", message.mediaPushId!);
      const videoFileToChunk: any[] = [];
      const quickFiles : any = []

      files.forEach((file: any) => {
       if(file?.imgType === "quick-file"){
        quickFiles.push({_id : file._id})
       }else{
         if (file?.originFileObj?.type?.includes("image/")) {
          formData.append(`files`, file.originFileObj as any);
        } else {
          videoFileToChunk.push(file);
        }
      }
      });
      formData.append("quickFiles" , JSON.stringify(quickFiles))

      if (videoFileToChunk.length > 0) {
        const videoFiles: any[] = [];
        const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
        setTotalSize(totalSizes);

        for (let i = 0; i < videoFileToChunk.length; i++) {
          const file = videoFileToChunk[i];
          const result = await storageService.uploadFileMultiPart(file, setLoadedData);
          videoFiles.push({
            Location: result.Location,
            isPrivate: file.isPrivate,
            Key: result.Key,
            ETag: result.ETag,
            Bucket: result.Bucket,
            filename: file.name,
            size: file.size,
          });
        }

        formData.append("videoFiles", JSON.stringify(videoFiles));
      }
      await mediapushService.updateMediaPushFiles(formData).then(() => {
        setLoading(false);
        dispatch(setPendingMediaFiles({ files: [] }));
      });
    }
  };

  return (
    <Space className={`messageMediaItem ${expired ? "expired" : ""} ${message.status === ACCEPTED ? "accepted" : ""}`} direction="vertical">
      <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent progress={true} progressValue={progress} />}>
        <Row>
          <Space className="w-full gap-4" direction="vertical">
            <MessagePriceComponent message={message} income={false} />

            {message.medias && !isEmpty(message.medias) ? (
              <Row>
                {message.medias?.map((media) => {
                  let imageUrl = media.url;
                  let isVideo = false;
                  if (media.url.includes(".mp4")) {
                    imageUrl = media.thumbnails?.find((thumb: any) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath;
                    isVideo = true;
                  }
                  return (
                    <MediaComponent
                      key={media.fileId}
                      width={60}
                      height={60}
                      borderRadius={11}
                      image={imageUrl}
                      url={media.url}
                      isVideo={isVideo}
                      username={""}
                      isPrivate={media.isPrivate}
                      isBlur={isBlur}
                      shouldAddWatermark={false}
                      displayVideo={displayVideo}
                    />
                  );
                })}
              </Row>
            ) : (
              !expired &&
              message.status !== REJECTED && (
                <Row className="mt-5">
                  <ConversationMediaComponent
                    disabled={message.status === PENDING}
                    onFilesChange={handleFilesChange}
                    showTitle={false}
                    maxCount={MAX_MEDIAS_TO_SEND}
                    messageId={message._id}
                  />
                </Row>
              )
            )}
          </Space>
        </Row>
        {message.text && (
          <Row className="mt-7">
            <Typography.Text className="font-15-medium text-black-color">{message.text}</Typography.Text>
          </Row>
        )}
        {isEmpty(message.medias) && !expired && message.status !== REJECTED && (
          <Button
            className="sendMediaButton"
            onClick={handleSendMedias}
            disabled={isEmpty(files) || (message.status !== REJECTED && message.status === PENDING)}
          >
            {t("send")}
          </Button>
        )}
        {!hideStatus ? (
          <Row className="mt-16">
            <Space>
              <img src={StatusBlackIcon} alt={message.status} />
              <Typography.Text className="font-14-bold text-black-color">
                {t(message.status!)} {isEmpty(message.medias) && message.status === ACCEPTED && message.type === FILE ? `(${t(PENDING)})` : ""}
              </Typography.Text>
            </Space>
          </Row>
        ) : null}
      </Spin>
    </Space>
  );
};

export default MessageMediaItemComponent;
