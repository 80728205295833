import { Typography } from "antd";
import Hls from "hls.js";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import playIcon from "../../../../assets/icons/jouer.svg";
import { EventType } from "../../../../helpers/types";
import { discoverService } from "../../../../services";
import "./index.less";

interface VideoPlayerProps {
  src: string;
  thumbnail: string;
  discoverId: string;
  globalMuted: boolean;
  index: number;
  total: number;
  onMuteChange: (muted: boolean) => void;
  loadMoreDiscovers: () => void;
  globalShowMuteButton: boolean;
  handleShowMuteButtonChange: (newShowMuteButtonState: boolean) => void;
}

const VolumeOnIcon = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
  </svg>
);

const VolumeOffIcon = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
  </svg>
);

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  thumbnail,
  discoverId,
  globalMuted,
  index,
  total,
  onMuteChange,
  loadMoreDiscovers,
  globalShowMuteButton,
  handleShowMuteButtonChange,
}) => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasReached25, setHasReached25] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(globalMuted);
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldShowMuteButton, setShouldShowMuteButton] = useState(globalShowMuteButton);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  useEffect(() => {
    // Mark as initialized after first mount
    setHasInitialized(true);

    const handleUserInteraction = () => {
      setUserInteracted(true);
    };

    // Listen for user interactions
    document.addEventListener("touchstart", handleUserInteraction);
    document.addEventListener("mousedown", handleUserInteraction);

    return () => {
      document.removeEventListener("touchstart", handleUserInteraction);
      document.removeEventListener("mousedown", handleUserInteraction);
    };
  }, []);

  const startPlayback = useCallback(() => {
    // console.log("startPlayback ------->", videoRef.current);
    if (!videoRef.current) return; // Only start playback if video is visible

    const video = videoRef.current;

    // Ensure video is properly configured for mobile autoplay
    video.playsInline = true;
    // video.autoplay = true;
    video.setAttribute("playsinline", "true");
    video.setAttribute("webkit-playsinline", "true");
    // video.setAttribute("autoplay", "true");

    video.loop = true;
    const playPromise = video.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          setIsPlaying(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Playback failed:", error);
          // If autoplay fails, try again with explicit user interaction
          if (isMobileDevice()) {
            const touchStartHandler = () => {
              if (isVisible) {
                // Only play if still visible
                video.play().catch(console.error);
              }
              document.removeEventListener("touchstart", touchStartHandler);
            };
            document.addEventListener("touchstart", touchStartHandler);
          }
          setIsPlaying(false);
          setIsLoading(false);
        });
    }
  }, [isVisible]); // Add isVisible to dependencies

  const toggleMute = useCallback(
    (event?: React.MouseEvent) => {
      event?.preventDefault();
      event?.stopPropagation();

      // Prevent mute toggle if video hasn't been interacted with on mobile
      if (isMobileDevice() && !userInteracted) {
        return;
      }

      const newMuteState = !isMuted;
      if (videoRef.current) {
        videoRef.current.muted = newMuteState;
        setIsMuted(newMuteState);
        onMuteChange(newMuteState);
      }
    },
    [isMuted, onMuteChange, userInteracted]
  );

  const initPlayer = useCallback(
    (isVideoVisible: boolean) => {
      if (!videoRef.current || !src) return;

      try {
        if (Hls.isSupported()) {
          const hls = new Hls({
            debug: false,
            enableWorker: true,
            maxBufferLength: 30,
            manifestLoadingTimeOut: 10000,
            manifestLoadingMaxRetry: 2,
            levelLoadingTimeOut: 10000,
            levelLoadingMaxRetry: 2,
            fragLoadingTimeOut: 20000,
            fragLoadingMaxRetry: 2,
            startLevel: -1,
            autoStartLoad: true,
            maxBufferHole: 0.5,
            maxFragLookUpTolerance: 0.25,
            maxBufferSize: 15 * 1000 * 1000,
            maxMaxBufferLength: 30,
            abrEwmaDefaultEstimate: 500000,
            abrBandWidthFactor: 0.95,
            abrBandWidthUpFactor: 0.7,
            xhrSetup: (xhr: XMLHttpRequest, url: string) => {
              const originalUrl = src;
              const queryParams = originalUrl.split("?")[1];
              const finalUrl = queryParams && !url.includes("?") ? `${url}?${queryParams}` : url;
              xhr.open("GET", finalUrl, true);
              xhr.withCredentials = false;
            },
            lowLatencyMode: true,
            backBufferLength: 30,
          });

          hlsRef.current = hls;

          // Track fragment loading state
          let lastLoadedFragmentSN: number | string = -1;
          let fragmentLoadingErrors = 0;

          hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
            // Reset error counter on successful load
            fragmentLoadingErrors = 0;
            lastLoadedFragmentSN = data.frag.sn;
            if (data.frag.sn === 1) {
              setIsVideoReady(true);
              setIsLoading(false);
              setIsPlaying(true);
            }

            // If this is the first fragment and video is not visible, stop loading
            // if (!isVideoVisible && data.frag.sn === 0) {
            //   hls.stopLoad();
            // }
          });

          hls.on(Hls.Events.ERROR, (_, data) => {
            console.error("HLS error:", data);

            // Handle fragment loading errors
            if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
              if (data.details === Hls.ErrorDetails.FRAG_LOAD_ERROR || data.details === Hls.ErrorDetails.FRAG_LOAD_TIMEOUT) {
                console.warn("Fragment loading issue:", data.details);
                fragmentLoadingErrors++;

                if (fragmentLoadingErrors > 2) {
                  // If we've had multiple errors, try switching quality levels
                  const currentLevel = hls.currentLevel;
                  const levels = hls.levels;

                  if (levels && levels.length > 1) {
                    // Try a lower quality level
                    const newLevel = currentLevel > 0 ? currentLevel - 1 : 1;
                    // console.log("Switching to lower quality level:", newLevel);
                    hls.currentLevel = newLevel;
                  }

                  // Reset error counter after recovery attempt
                  fragmentLoadingErrors = 0;
                }
              }
            }

            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.log("Network error - attempting recovery");
                  if (lastLoadedFragmentSN !== -1) {
                    console.log("Trying to resume from last known fragment:", lastLoadedFragmentSN);
                    hls.startLoad(lastLoadedFragmentSN as number);
                  } else {
                    hls.startLoad();
                  }
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("Media error - attempting recovery");
                  hls.recoverMediaError();
                  break;
                default:
                  if (isVisible) {
                    console.log("Fatal error - reinitializing player");
                    destroyPlayer();
                    setTimeout(() => {
                      initPlayer(true);
                    }, 1000);
                  } else {
                    console.error("Fatal error while invisible - will reinit when visible");
                    setHasError(true);
                    setIsLoading(false);
                  }
                  break;
              }
            }
          });

          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            if (videoRef.current) {
              const video = videoRef.current;
              video.playsInline = true;
              video.setAttribute("playsinline", "true");
              video.setAttribute("webkit-playsinline", "true");
              video.loop = true;
            }

            if (isVideoVisible) {
              hls.loadSource(src);
              if (videoRef.current) {
                const video = videoRef.current;
                video.setAttribute("autoplay", "true");
                video.autoplay = true;
              }
            } else {
              setTimeout(() => {
                hls.loadSource(src);
              }, 2000);
            }
          });

          hls.attachMedia(videoRef.current);
        } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
          const video = videoRef.current;
          video.playsInline = true;
          video.setAttribute("playsinline", "true");
          video.setAttribute("webkit-playsinline", "true");
          video.loop = true;

          if (isVideoVisible) {
            video.src = src;
            video.setAttribute("autoplay", "true");
            video.autoplay = true;
          } else {
            setTimeout(() => {
              if (videoRef.current) {
                videoRef.current.src = src;
              }
            }, 2000);
          }

          video.addEventListener("loadedmetadata", () => {
            if (isVideoVisible) {
              startPlayback();
            }
          });
        } else {
          setHasError(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error initializing player:", error);
        setHasError(true);
        setIsLoading(false);
      }
    },
    [src, startPlayback, isVisible]
  );

  const togglePlay = useCallback(() => {
    if (!videoRef.current || hasError) return;

    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      startPlayback();
    }
  }, [isPlaying, hasError, startPlayback]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isNowVisible = entry.isIntersecting && entry.intersectionRatio >= 0.95;
          setIsVisible(isNowVisible);

          if (isNowVisible) {
            if (index === total - 3) {
              loadMoreDiscovers();
            }

            if (hlsRef.current) {
              if (videoRef?.current) {
                videoRef.current.muted = globalMuted;
                videoRef.current.loop = true;
              }
              hlsRef.current.startLoad();
              startPlayback();
            } else {
              initPlayer(isNowVisible);
            }
          } else {
            if (videoRef?.current) {
              videoRef.current.muted = globalMuted;
              videoRef.current.loop = true;
              videoRef.current.pause();
            }
            setIsPlaying(false);

            if (!hlsRef.current) {
              // Initialize HLS for invisible video
              initPlayer(isNowVisible);
            }
            // else {
            // setTimeout(() => {
            //   if (hlsRef.current && !isVisible) {
            //     console.log("Stopping load after delay");
            //     hlsRef.current.stopLoad();
            //   }
            // }, 1000);
            // }
          }
        });
      },
      { threshold: [0, 0.95], rootMargin: "0px 0px -70px 0px" }
    );

    // Add touch scroll enforcement
    let touchStartY = 0;
    let lastScrollTop = 0;
    let isTouching = false;

    const handleTouchStart = (e: TouchEvent) => {
      isTouching = true;
      touchStartY = e.touches[0].clientY;
      if (containerRef.current) {
        lastScrollTop = containerRef.current.scrollTop;
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (!containerRef.current || !isTouching) return;

      const touchY = e.touches[0].clientY;
      const deltaY = touchStartY - touchY;
      const container = containerRef.current;
      const videoHeight = container.clientHeight;

      // Only allow scroll if we've moved more than 50px
      if (Math.abs(deltaY) > 50) {
        const direction = deltaY > 0 ? 1 : -1;
        const targetScroll = Math.round((lastScrollTop + videoHeight * direction) / videoHeight) * videoHeight;

        container.scrollTo({
          top: targetScroll,
          //behavior: "smooth",
        });

        // Prevent default only for touch devices
        e.preventDefault();
      }
    };

    const handleTouchEnd = () => {
      isTouching = false;
    };

    if (containerRef.current) {
      observer.observe(containerRef.current);
      containerRef.current.addEventListener("touchstart", handleTouchStart, { passive: true });
      containerRef.current.addEventListener("touchmove", handleTouchMove, { passive: false });
      containerRef.current.addEventListener("touchend", handleTouchEnd, { passive: true });
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
        containerRef.current.removeEventListener("touchstart", handleTouchStart);
        containerRef.current.removeEventListener("touchmove", handleTouchMove);
        containerRef.current.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [initPlayer, globalMuted, index, total, loadMoreDiscovers, startPlayback]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      const percentage = Math.floor((video.currentTime / video.duration) * 100);
      if (percentage >= 25 && !hasReached25) {
        setHasReached25(true);
        discoverService.updateDiscoverStats({ id: discoverId, event: EventType.VIEWS });
      }
    };

    const handleEnded = () => {
      if (video.loop) {
        video.currentTime = 0;
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            // console.log("Auto-replay failed:", error);
            // Add retry logic
            setTimeout(() => {
              video.play().catch(console.error);
            }, 100);
          });
        }
      }
    };

    const handleTimeUpdateLoop = () => {
      if (video.currentTime > video.duration - 0.5) {
        video.loop = true;
        // Add this to ensure looping
        if (!video.seeking) {
          video.currentTime = 0;
        }
      }
    };

    const handleCanPlay = () => {
      setIsVideoReady(true);
    };

    video.addEventListener("timeupdate", handleTimeUpdate);
    video.addEventListener("ended", handleEnded);
    video.addEventListener("timeupdate", handleTimeUpdateLoop);
    video.addEventListener("canplay", handleCanPlay);

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
      video.removeEventListener("ended", handleEnded);
      video.removeEventListener("timeupdate", handleTimeUpdateLoop);
      video.removeEventListener("canplay", handleCanPlay);
    };
  }, [discoverId, hasReached25]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleVolumeChange = () => {
      if (isMobileDevice() && hasInitialized && userInteracted && video.volume > 0) {
        const newMuteState = video.muted;
        setIsMuted(newMuteState);
        onMuteChange(newMuteState);
      }
    };

    video.addEventListener("volumechange", handleVolumeChange);
    return () => {
      video.removeEventListener("volumechange", handleVolumeChange);
    };
  }, [hasInitialized, userInteracted, onMuteChange]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.loop = true;
    }
  }, [videoRef.current]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = globalMuted;
      setIsMuted(globalMuted);
    }
  }, [globalMuted]);

  useEffect(() => {
    // Only show button if:
    // 1. Video is paused (!isPlaying) OR
    // 2. Video is playing AND muted (isPlaying && isMuted)
    // AND video is visible to prevent flash during swipes
    const shouldShow = (!isPlaying && isVideoReady && isVisible) || (isPlaying && isMuted);

    if (!shouldShow) {
      setShouldShowMuteButton(false);
      handleShowMuteButtonChange(false);
    } else {
      setShouldShowMuteButton(true);
      handleShowMuteButtonChange(true);
    }
  }, [isPlaying, isMuted, isVideoReady, isVisible]);

  const destroyPlayer = useCallback(() => {
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.removeAttribute("src");
      videoRef.current.load();
    }
  }, []);

  useEffect(() => {
    return () => {
      destroyPlayer();
    };
  }, [destroyPlayer]);

  return (
    <div
      ref={containerRef}
      className="hls-video-player"
      style={{
        position: "relative",
      }}
    >
      {/* Thumbnail layer - stays visible until video is ready */}
      {!isVideoReady && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${thumbnail})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 5,
          }}
        />
      )}
      {isLoading && !isPlaying && (
        <div className="loading-spinner" style={{ zIndex: 6 }}>
          <div className="spinner"></div>
        </div>
      )}
      <video
        ref={videoRef}
        playsInline
        // autoPlay
        loop
        muted={isMuted}
        crossOrigin="anonymous"
        poster={thumbnail}
        preload="metadata"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          opacity: isVideoReady ? 1 : 0.5,
          //transition: "opacity 0.3s ease-out",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 6,
        }}
      >
        {/* Play area - only handles play/pause */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            cursor: "pointer",
          }}
          onClick={togglePlay}
        />

        {/* Play icon */}
        {!isPlaying && !isLoading && !hasError && (
          <div
            className="play-icon"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              pointerEvents: "none",
            }}
          >
            <img src={playIcon} alt="play" />
          </div>
        )}

        {/* Mute button - separate from play area */}
        <button
          className="mute-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleMute(e);
          }}
          aria-label={isMuted ? "Unmute" : "Mute"}
          style={{
            position: "absolute",
            top: "80px",
            right: "12px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            color: "white",
            border: "none",
            borderRadius: "12px",
            width: "auto",
            padding: "10px",
            display: shouldShowMuteButton ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            zIndex: 10,
            transition: "opacity 0.3s ease-in-out",
            opacity: shouldShowMuteButton ? 1 : 0,
          }}
        >
          {isMuted ? <VolumeOffIcon /> : <VolumeOnIcon />}
          <Typography.Text className="mute-button-text">{isMuted ? t("sound-on") : t("sound-off")}</Typography.Text>
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
