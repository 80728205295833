import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RightArrowShowIcon from "../../../../../assets/icons/right-arrow-show.svg";
import LeftArrowSubIcon from "../../../../../assets/icons/left-arrow-sub.svg";
import React from "react";
import "./index.less";

type SubscribeViewCollectionsComponent = {
  username?: string;
  creatorId?: string;
};

const SubscribeViewCollectionsComponent: React.FC<SubscribeViewCollectionsComponent> = ({ username, creatorId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const subscribeButtonHandleClick = () => {
    if (creatorId) {
      navigate(`/common/subscribe/${creatorId}`);
    }
  };

  const viewCollectionsButtonHandleClick = () => {
    if (username) {
      navigate(`/p/${username}?ctc=collections`);
    }
  };

  return (
    <div className="subscribe-view-collections-button-wrapper">
      <button className="subscribe-button" onClick={subscribeButtonHandleClick}>
        <img src={LeftArrowSubIcon} alt="subscribe" />
        {t("subscribe")}
      </button>

      <button className="view-collections-button" onClick={viewCollectionsButtonHandleClick}>
        <img src={RightArrowShowIcon} alt="View collections" />
        {t("see-collections")}
      </button>
    </div>
  );
};

export default SubscribeViewCollectionsComponent;
