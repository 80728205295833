import { useTranslation } from "react-i18next";
import { Row, Typography } from "antd";
import "./index.less";

type CollectionHideModalProps = {
  isOpen: boolean;
  hide: boolean;
  onClose: () => void;
  hideShowCollection: () => void;
};

const CollectionHideModalComponent: React.FC<CollectionHideModalProps> = ({ isOpen, hide, onClose, hideShowCollection }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-24">
            {hide ? t("you-want-show-collection") : t("you-want-hide-collection")}
          </Typography.Text>
        </div>
        <div className="bottom-modal-content mt-10">
          {hide ? (
            <button className="bottom-modal-button mt-14" onClick={hideShowCollection}>
              <Typography.Text className="bottom-modal-button-title">{t("yes-show")}</Typography.Text>
            </button>
          ) : (
            <button className="bottom-modal-button mt-14" onClick={hideShowCollection}>
              <Typography.Text className="bottom-modal-button-title">{t("yes-hide")}</Typography.Text>
            </button>
          )}

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollectionHideModalComponent;
