import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import DiscoverButtonIcon from "../../../../assets/icons/discover-button.svg";
import "./index.less";

const PresentationVideoButton = ({ onClick, notificationVisible }: { onClick: () => void; notificationVisible: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className="presentation-video-button-container-wrapper">
      <div className="presentation-video-button-container" onClick={onClick}>
        <img src={DiscoverButtonIcon} className="presentation-video-button-img" alt="Discover" />
        <Typography.Text className="presentation-video-button-text">{t("presentation-video")}</Typography.Text>
        {notificationVisible && <div className="presentation-video-button-notification"></div>}
      </div>
    </div>
  );
};

export default PresentationVideoButton;
