import { Button, Col, Layout, message, Row, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
//import uniqBy from "lodash/uniqBy";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import ArrowChatIcon from "../../../assets/icons/arrow-chat.svg";
import AvatarComponent from "../../../components/AvatarComponent";
import ConversationThreeDotsModalComponent from "../../../components/ConversationThreeDotsModalComponent";
import FanAvatarComponent from "../../../components/FanAvatarComponent";
import FanRequireSubscribeModalComponent from "../../../components/FanRequireSubscribeModalComponent";
import IncomeMessageComponent from "../../../components/IncomeMessageItemComponent";
import MessageInputComponent from "../../../components/MessageInputComponent";
import MessageMediaItemComponent from "../../../components/MessageMediaItemComponent";
import MessageTextItemComponent from "../../../components/MessageTextItemComponent";
import ShareModalComponent from "../../../components/ShareModalComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import ThreeDotsComponent from "../../../components/ThreeDotsComponent";
import VerifiedUserIconComponent from "../../../components/VerifiedUserIconComponent/VerifiyedUserIconComponent";
import {
  ACCEPTED,
  CONVERSATION_DATE_FORMAT,
  CREATOR,
  F_CURRENT_TAB,
  FAN,
  FILE,
  HELLO_MESSAGE,
  MESSAGES_PER_PAGE,
  PENDING,
  TEXT,
} from "../../../helpers/constant";
import { usePreviousLocationContext } from "../../../helpers/PreviousLocationContext";
import { IConversationDTO, IMessage, PageableData, VerifySubscriptionPayload } from "../../../helpers/types";
import {
  getConversationById,
  readAllMessagesInConversation,
  selectConversation,
  updateConversationsMediaPush,
  updateMessagesData,
} from "../../../redux/messages/messagesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { fileService, subscriptionsService } from "../../../services";
import { socket } from "../../../socket";
import "./index.less";

const FanRequestMediaPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = usePreviousLocationContext();
  const dispatch = useDispatch<AppDispatch>();
  const { conversationId } = useParams();
  const [searchParams] = useSearchParams();
  const { selectedConversation, error } = useSelector((state: StoreState) => state.messages);
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);
  const shouldFetch = useRef(true);
  //const [messages, setMessages] = useState<IMessage[]>([]);
  const [isRequireSubscribeModalOpen, setIsRequireSubscribeModalOpen] = useState<boolean>(false);
  const [isThreeDotsModalOpen, setIsThreeDotsModalOpen] = useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  //const [page, setPage] = useState(1);
  //const [totalPages, setTotalPages] = useState(-1);
  //const [hasNextPage, setHasNextPage] = useState(true);
  //TODO: improve this code to get the subscription with selected conversation
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [oldestMessageId, setOldestMessageId] = useState<string | null>(null);
  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    readAs: "ArrayBuffer",
    multiple: false,
    accept: ["image/*", "video/*"],
    limitFilesConfig: { max: 1 },
  });
  const [progress, setProgress] = useState<number>(0);
  const messageData = useSelector((state: StoreState) =>
    state.messages.messagesData.find((conversation) => conversation.conversationId === selectedConversation._id)
  );
  const page = messageData?.page || 1;
  const hasNextPage = (messageData && messageData.messages.length > 0 && messageData?.page < messageData?.totalPages) || false;
  //const oldestMessageId = (messageData?.oldestMessageId || null);
  const [isConversationStep, setIsConversationStep] = useState<boolean>((messageData && messageData?.messages.length > 0) || true);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState<number | null>(null);
  const from = searchParams.get("from");

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      if (conversationId !== "new") {
        if (!messageData?.messages || messageData?.messages.length < MESSAGES_PER_PAGE) {
          socket.emit("find-all-messages", { conversationId: conversationId, page: page });
          setLoading(true);
        }
        socket.emit("read-all-messages", conversationId);
        dispatch(readAllMessagesInConversation({ conversationId, conversationType: window.localStorage.getItem(F_CURRENT_TAB) }));
        if (!isEmpty(selectedConversation)) {
          if (selectedConversation.chat !== "free") {
            updateSubscription();
          }
        } else {
          if (conversationId !== undefined) {
            dispatch(getConversationById(conversationId));
          }
        }
      } else if (searchParams.get("newConversation") !== null) {
        dispatch(selectConversation(JSON.parse(searchParams.get("newConversation")!)));
      }
    }
  }, []);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    // if (oldestMessageId !== null) {
    //  setTimeout(() => {
    //   const oldestMessage = document.getElementById(oldestMessageId);
    //   if (oldestMessage !== null) {
    //     oldestMessage.scrollIntoView({ behavior: "auto" });
    //   }

    //   }, 250)
    // }

    if (scrollRef.current) {
      scrollToBottom();
    }
  }, [messageData?.messages]);

  // useEffect(() => {
  //   if (totalPages !== -1 && page >= totalPages) {
  //     setHasNextPage(false);
  //   }
  // }, [totalPages]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition ? scrollRef.current.scrollHeight - scrollPosition : scrollRef.current.scrollHeight;
      scrollPosition && setScrollPosition(null);
    }
  };

  useEffect(() => {
    function onProgressUpdate(data: number) {
      setProgress((prev) => {
        const prog = prev + 1;
        if (prog <= 99) {
          return prog;
        }
        return prev;
      });
    }

    socket.on("progress-update", onProgressUpdate);

    return () => {
      socket.off("progress-update", onProgressUpdate);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(plainFiles)) {
      setIsLoading(true);
      let newMessage: IMessage = {
        conversationId: conversationId,
        senderId: selectedConversation.currentUser?.userId,
        receiverId: selectedConversation.interlocutor?.userId,
        type: FILE,
        status: ACCEPTED,
        medias: [],
      };
      const formData = new FormData();
      formData.append("media", plainFiles[0]);
      fileService
        .uploadMedia(formData, setProgress)
        .then((file) => {
          newMessage.medias?.push({
            fileId: file._id !== undefined ? file._id : "",
            url: file?.absolutePath !== undefined ? file?.absolutePath : "",
            thumbnails: file.thumbnails,
          });
          socket.emit("send-message", newMessage);
          clear();
          setIsLoading(false);
        })
        .catch(() => {
          message.error("Something went wrong, could not send file !");
        });
    }
  }, [plainFiles]);

  function loadMoreMessages() {
    const nextPage = page + 1;
    //if (nextPage <= totalPages) {
    if (hasNextPage) {
      setLoading(true);
      socket.emit("find-all-messages", { conversationId: conversationId, page: nextPage });
    }
    //else {
    //   setHasNextPage(false);
    // }
  }

  function handleMessages(payload: PageableData<IMessage>) {
    if (!isEmpty(payload.data)) {
      dispatch(updateMessagesData(payload));
      setIsConversationStep(true);

      // setOldestMessageId(payload.data[0]._id !== undefined ? payload.data[0]._id.toString() : null);
      // setMessages((prevState) => {
      //   return uniqBy([...prevState, ...payload.data], "_id").sort((a, b) => {
      //     if (a.createdAt && b.createdAt) {
      //       const da: any = new Date(a.createdAt),
      //         db: any = new Date(b.createdAt);
      //       return da - db;
      //     }
      //     return 1;
      //   });
      // });
      // setTotalPages(payload.totalPages);
    }
    setLoading(false);
  }

  function handleMessage(data: IMessage) {
    if (data.conversationId && selectedConversation._id && data.conversationId.toString() === selectedConversation._id.toString()) {
      //setOldestMessageId(data._id !== undefined ? data._id.toString() : null);
      //setMessages((prevState) => uniqBy([...prevState, data], "_id"));
      if (data.type === FILE) {
        dispatch(updateConversationsMediaPush(data));
      }
      socket.emit("read-all-messages", data.conversationId);
    }
  }

  function handleConversationCreated(data: IConversationDTO) {
    dispatch({
      type: getConversationById.fulfilled.type,
      payload: data,
    });
    navigate(`/fan/requestMedia/${data._id}`, { replace: true });
    socket.emit("find-all-messages", { conversationId: data._id, page: page });
    window.location.reload();
    setLoading(true);
  }

  // function handleConversationUpdated(data: IConversationDTO) {
  //   if (data._id && selectedConversation._id && data._id.toString() === selectedConversation._id.toString()) {
  //     dispatch({
  //       type: getConversationById.fulfilled.type,
  //       payload: data,
  //     });
  //   }
  // }

  // function handleUpdateMessage(data: IMessage) {
  //   if (data.conversationId && selectedConversation._id && data.conversationId.toString() === selectedConversation._id.toString()) {
  //     setMessages((messages) =>
  //       messages.map((message) => {
  //         if (message._id && data._id && message._id.toString() === data._id.toString()) {
  //           message = data;
  //         }
  //         return message;
  //       })
  //     );
  //   }
  // }

  useEffect(() => {
    if (!isEmpty(selectedConversation) || selectedConversation.chat !== "free") {
      updateSubscription();
    }

    socket.on("messages", handleMessages);
    socket.on("message", handleMessage);
    socket.on("conversation-created", handleConversationCreated);
    //socket.on("conversation-updated", handleConversationUpdated);
    //socket.on("update-message", handleUpdateMessage);

    return () => {
      socket.off("messages", handleMessages);
      socket.off("message", handleMessage);
      socket.off("conversation-created", handleConversationCreated);
      //socket.off("conversation-updated", handleConversationUpdated);
      //socket.off("update-message", handleUpdateMessage);
    };
  }, [selectedConversation]);

  const updateSubscription = () => {
    const payload: VerifySubscriptionPayload = {
      fanId: selectedConversation.currentUser?.role === FAN ? selectedConversation.currentUser.userId : selectedConversation.interlocutor?.userId,
      creatorId:
        selectedConversation.currentUser?.role === CREATOR ? selectedConversation.currentUser.userId : selectedConversation.interlocutor?.userId,
    };
    if (!payload.creatorId || !payload.fanId) {
      return;
    }
    subscriptionsService
      .verifySubscription(payload)
      .then((data) => {
        setIsSubscribed(data);
        scrollToBottom();
        setSubscriptionLoading(false);
      })
      .catch((error) => {
        scrollToBottom();
        setSubscriptionLoading(false);
        message.error(error);
      });
  };

  const requestMediaButtonClicked = () => {
    if (isSubscribed) {
      setIsConversationStep(true);
    } else {
      setIsRequireSubscribeModalOpen(true);
    }
  };

  const sendMessageClicked = (newMessage: string) => {
    if (isEmpty(newMessage)) return;
    const messageToSend: IMessage = {
      conversationId: conversationId,
      senderId: selectedConversation.currentUser?.userId,
      receiverId: selectedConversation.interlocutor?.userId,
      type: TEXT,
      text: newMessage,
      status: PENDING,
    };
    socket.emit("send-message", messageToSend);
  };

  const sendHelloMessage = () => {
    const helloMessage = t(HELLO_MESSAGE);
    const messageToSend: IMessage = {
      conversationId: conversationId,
      senderId: selectedConversation.currentUser?.userId,
      receiverId: selectedConversation.interlocutor?.userId,
      type: TEXT,
      text: helloMessage,
      status: PENDING,
    };
    socket.emit("send-message", messageToSend);
  };

  const acceptMessage = (message: IMessage) => {
    if (message.price !== undefined) {
      if (parseInt(message.price, 10) === 0) {
        socket.emit("accept-message", {
          messageId: message._id,
          userType: FAN,
        });
      } else {
        navigate(
          `/fan/payment?creatorId=${message.senderId}&type=mediaPush&mediaPushId=${message.mediaPushId}&messageId=${message._id}&redirectUrl=fan/requestMedia/${selectedConversation._id}`
        );
      }
    }
  };

  const rejectMessage = (messageId: string) => {
    socket.emit("reject-message", {
      messageId,
      userType: FAN,
    });
  };

  function handleSendMedia() {
    openFileSelector();
  }

  function onPrivateGalleryClick() {
    let receiverId: string;
    let senderId: string;
    if (selectedConversation.interlocutor?.role === FAN) {
      receiverId = selectedConversation.interlocutor?.userId !== undefined ? selectedConversation.interlocutor?.userId : "";
      senderId = selectedConversation.currentUser?.userId !== undefined ? selectedConversation.currentUser?.userId : "";
    } else {
      receiverId = selectedConversation.currentUser?.userId !== undefined ? selectedConversation.currentUser?.userId : "";
      senderId = selectedConversation.interlocutor?.userId !== undefined ? selectedConversation.interlocutor?.userId : "";
    }
    navigate(
      `/common/privateGallery?conversationId=${selectedConversation._id}&receiverId=${receiverId}&senderId=${senderId}&shouldAddWatermark=true`
    );
  }

  const onShareProfileClick = () => {
    setIsThreeDotsModalOpen(false);
    setIsShareModalOpen(true);
  };

  const handleScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollTop === 0) {
      setScrollPosition(e.currentTarget.scrollHeight);
      loadMoreMessages();
    }
  };

  const goToSubscriptionPage = () => {
    let url = location.pathname.substring(1);
    const param = url.split("/")[2];
    if (param === "new") {
      url = url + `?newConversation=${JSON.stringify(selectedConversation)}`;
    }
    navigate(`/common/subscribe/${selectedConversation?.interlocutor?.userId}?redirectUrl=${url}`);
  };

  const goToProfilePage = () => {
    if (selectedConversation.interlocutor?.name !== "user-not-found") {
      navigate(`/p/${selectedConversation?.interlocutor?.username}`);
    }
  };

  const handleNavigation = () => {
    const { activeKey } = location.state || {};
    //navigate("/fan/message", { state: { activeKey } });
    if (previousLocation?.pathname !== undefined && previousLocation?.pathname.includes("/p/")) {
      navigate(-1);
    } else {
      navigate("/fan/message", { state: { activeKey } });
    }
  };

  const renderFooterComponent = () => {
    if (isConversationStep) {
      if (isSubscribed || selectedConversation.chat === "free") {
        return selectedConversation?.fanCanSendMessage ? (
          <Row className="message-input-row">
            <MessageInputComponent onSendClick={sendMessageClicked} onMediaClick={handleSendMedia} sendMedia={true} sendQuickAnswer={false} />
          </Row>
        ) : (
          <>
            <Row className={"justify-content-center"}>
              <Button
                disabled={!selectedConversation?.fanCanSendHelloMessage}
                className={`whiteButton big max-w-273 font-16-bold br-21-5 mb-6 ${!selectedConversation?.fanCanSendHelloMessage ? "disabled" : ""}`}
                onClick={sendHelloMessage}
              >
                Hello
              </Button>
            </Row>
            <Row className={"justify-content-center"}>
              <Typography.Text className="creatorAcceptInfoText">{t("hello-btn-message")}</Typography.Text>
            </Row>
          </>
        );
      } else {
        return (
          !subscriptionLoading && (
            <div className={"d-flex justify-content-center"}>
              <Button className="request-media-button" onClick={requestMediaButtonClicked}>
                {t("request-private-media")}
              </Button>
            </div>
          )
        );
      }
    } else {
      if (!isSubscribed) {
        return (
          <div className={"d-flex justify-content-center"}>
            <Button className="request-media-button" onClick={requestMediaButtonClicked}>
              {t("request-private-media")}
            </Button>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <Spin spinning={isLoading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent progress={true} progressValue={progress} />}>
      <Layout className="gradient-background-conversation">
        <Layout.Header className="header-chat background-transparent">
          <div className="header-chat-row">
            <div className="header-icon mt-10" onClick={handleNavigation}>
              <img width={11} src={ArrowChatIcon} alt="Loly messages" />
            </div>
            <div className="header-content">
              {selectedConversation.interlocutor?.avatar ? (
                <AvatarComponent image={selectedConversation.interlocutor?.avatar} size={35} onClick={goToProfilePage} />
              ) : (
                <FanAvatarComponent name={selectedConversation.interlocutor?.name} size={35} />
              )}
              <div className="header-text-wrapper">
                <Typography.Text className="title-name-messagerie-header text-white-color" onClick={goToProfilePage}>
                  {selectedConversation.interlocutor?.name === "user-not-found" ? t("user-not-found") : selectedConversation.interlocutor?.name}
                </Typography.Text>
                <VerifiedUserIconComponent
                  userId={selectedConversation.interlocutor?.userId}
                  verified={selectedConversation.interlocutor?.isVerified}
                  fetch={false}
                />
                {isSubscribed !== null && !isSubscribed && selectedConversation.interlocutor?.name === "user-not-found" && (
                  <Button className="fanRequestMediaSubscribeBtn" onClick={goToSubscriptionPage}>
                    {t("subscribe")}
                  </Button>
                )}
              </div>
            </div>
            <div className="three-dots-component">
              <ThreeDotsComponent onClick={() => setIsThreeDotsModalOpen(true)} />
            </div>
          </div>
        </Layout.Header>
        <Layout.Content className={`content fanRequestMediaWrapper background-transparent ${loading && "loading"}`}>
          {loading && <SpinnerComponent />}
          {isConversationStep && (
            <Col className="messageListContent" ref={scrollRef} onScroll={handleScroll}>
              <Row className="mt-17" style={{ display: "flex", flexDirection: "column-reverse" }}>
                <Space className="w-full" direction="vertical">
                  {messageData?.messages?.map((message, index) => {
                    const isIncome = message.receiverId === selectedConversation.currentUser?.userId;
                    const messageDate = dayjs(message.createdAt);
                    const showDate = index === 0 || messageDate.diff(dayjs(messageData.messages[index - 1].createdAt), "hour") >= 1;
                    return (
                      <>
                        {showDate && (
                          <Row className="justify-content-center mt-10 mb-10">
                            <Typography.Text className="messageDateLabel">{messageDate.format(CONVERSATION_DATE_FORMAT)}</Typography.Text>
                          </Row>
                        )}
                        <Row id={message._id} key={message._id} className={isIncome ? "justify-content-start" : "justify-content-end"}>
                          {message.type === TEXT && !isIncome && (
                            <MessageTextItemComponent message={message} userRole={selectedConversation.currentUser?.role} />
                          )}
                          {message.type === FILE && !isIncome && <MessageMediaItemComponent message={message} hideStatus={true} />}
                          {isIncome && (
                            <IncomeMessageComponent
                              message={message}
                              user={selectedConversation.interlocutor?.avatar}
                              userRole={selectedConversation.currentUser?.role}
                              acceptMessage={acceptMessage}
                              rejectMessage={rejectMessage}
                              username={selectedConversation.interlocutor?.username}
                            />
                          )}
                        </Row>
                      </>
                    );
                  })}
                </Space>
              </Row>
            </Col>
          )}
          {selectedConversation.interlocutor?.name === "user-not-found" ? "" : renderFooterComponent()}
          <FanRequireSubscribeModalComponent
            isOpen={isRequireSubscribeModalOpen && selectedConversation.interlocutor?.name !== "user-not-found"}
            onClose={() => setIsRequireSubscribeModalOpen(false)}
            onSubmit={goToSubscriptionPage}
            creatorName={selectedConversation.interlocutor?.name}
          />
          <ConversationThreeDotsModalComponent
            isOpen={isThreeDotsModalOpen}
            onClose={() => setIsThreeDotsModalOpen(false)}
            onPrivateGalleryClick={onPrivateGalleryClick}
            onShareProfileClick={onShareProfileClick}
            hideShareButton={selectedConversation.interlocutor?.name === "user-not-found"}
            hideCancelSubButton={true}
          />
          <ShareModalComponent
            isOpen={isShareModalOpen && selectedConversation.interlocutor?.name !== "user-not-found"}
            onClose={() => setIsShareModalOpen(false)}
            id={selectedConversation?.interlocutor?.userId}
            username={selectedConversation?.interlocutor?.username}
          />
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default FanRequestMediaPage;
