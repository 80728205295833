import React from "react";
import ChevronBack from "../../assets/icons/chevron-back-other-profile.svg";
import { Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import "./index.less";

type HeaderTitleProps = {
  title: string;
  fontSize: string;
  navigateTo?: () => void;
};

const HeaderTitlePage: React.FC<HeaderTitleProps> = ({ title, fontSize, navigateTo }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (navigateTo) {
      navigateTo();
    } else {
      backToPreviousPage();
    }
  };

  const backToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <Row className="header-title-page-row">
      <img src={ChevronBack} className="header-title-back-icon" alt="arrow left" onClick={handleNavigate} />
      <Typography.Text className="header-title-text" style={{ fontSize: fontSize }}>
        {title}
      </Typography.Text>
    </Row>
  );
};

export default HeaderTitlePage;
