import axios from "../helpers/axios";
import { IVideoUpload } from "../helpers/types";

class DiscoverService {
  public uploadPresentationVideos(payload: { videoFiles: IVideoUpload[] }, setProgress: Function) {
    return new Promise<any>((resolve, reject) => {
      const url = "discover";
      axios
        .post(url, payload, {
          onUploadProgress: (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total!));
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response?.data?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public updateDiscoverVideos(payload: { id: string; videoFiles: IVideoUpload[] }, setProgress: Function) {
    return new Promise<any>((resolve, reject) => {
      const url = "discover/edit-discover-video";
      axios
        .patch(url, payload, {
          onUploadProgress: (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total!));
          },
        })
        .then((response) => {
          if (response?.data) {
            resolve(response?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public getFanDiscovers({ page, username }: { page: number; username?: string }) {
    return new Promise<any>((resolve, reject) => {
      let url = `discover/fan/${page}`;
      if (username) url += `?username=${username}`;
      axios
        .get(url)
        .then((response) => {
          if (response?.data) {
            resolve(response?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public getCreatorDiscovers() {
    return new Promise<any>((resolve, reject) => {
      const url = "discover/creator";
      axios
        .get(url)
        .then((response) => {
          if (response?.data) {
            resolve(response?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public deleteDiscover(discoverId: string) {
    return new Promise<any>((resolve, reject) => {
      const url = `discover/${discoverId}`;
      axios
        .delete(url)
        .then((response) => {
          if (response?.data) {
            resolve(response?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public updateDiscoverStats(payload: { id: string; event: string }) {
    return new Promise<any>((resolve, reject) => {
      const url = `discover/stats`;
      axios
        .patch(url, payload)
        .then((response) => {
          if (response?.data !== undefined) {
            resolve(response?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public getDiscoverAnalytics(id: string) {
    return new Promise<any>((resolve, reject) => {
      const url = `discover/analytics/${id}`;
      axios
        .get(url)
        .then((response) => {
          if (response?.data) {
            resolve(response?.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }
}

export const discoverService = new DiscoverService();
