import { Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PresentationVideoAnimation from "../../../../../assets/videos/formation/presentation-videos-animation.mp4";
import "./index.less";

const TipsVideoPresentationComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="tips-presentation-videos-container">
      <div style={{ zIndex: 11 }}>
        <Typography.Text className="tips-presentation-videos-container-title">{t("tips-for-optimizing-video-presentations")}</Typography.Text>
      </div>
      <div className="tips-presentation-videos-container-desc">
        <Typography.Text className="tips-presentation-videos-container-desc-text">
          {t("tips-for-optimizing-video-presentations-desc")}
        </Typography.Text>
        <Button className="tips-presentation-videos-container-button">{t("discover")}</Button>
      </div>
      <video src={PresentationVideoAnimation} className="tips-presentation-videos-animation" width={34} autoPlay loop muted playsInline />
    </div>
  );
};

export default TipsVideoPresentationComponent;
