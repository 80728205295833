import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MicrophoneImg from "../../../../../assets/images/microphone.webp";
import { useDisableScroll } from "../../../../../utls/PresentationVideoUtils";
import VideoOfPresentationVideoStatusComponent from "../VideoOfPresentationVideoStatusComponent/VideoOfPresentationVideoStatusComponent";
import "../index.less";

interface VideoExhaustedModalProps {
  isOpen: boolean;
  onClose: () => void;
  editVideo: () => void;
}

const VideoExhaustedModalComponent: React.FC<VideoExhaustedModalProps> = ({ isOpen, onClose, editVideo }) => {
  const { t } = useTranslation();

  useDisableScroll(isOpen);

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleEditVideo = () => {
    onClose();
    editVideo();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="presentation-video-modal-overlay no-scroll" onClick={handleOverlayClick}>
      <div className="presentation-video-modal-content" onClick={handleModalClick}>
        <div className="presentation-video-modal-content-wrapper">
          <div className="w-full d-flex justify-content-center">
            <div className="formation-modal-line-header"></div>
          </div>
          <div className="w-full d-flex justify-content-center">
            <img src={MicrophoneImg} className="presentation-video-modal-img w-50-p m-30" alt="Under verification" />
          </div>
          <Typography.Text className="presentation-video-modal-title font-size-26">{t("video-statistics-decrease")}</Typography.Text>

          <Typography.Text className="presentation-video-modal-text mt-16">{t("video-statistics-decrease-desc")}</Typography.Text>

          {/* <Typography.Text className="presentation-video-modal-title font-size-16 mt-40 mb-15">
            {t("watch-explanatory-video-better-understanding")}
          </Typography.Text>

          <VideoOfPresentationVideoStatusComponent
            src="https://www.youtube.com/watch?v=eMGSHRXfctQ"
            thumbnail="https://img.youtube.com/vi/eMGSHRXfctQ/0.jpg"
          /> */}
        </div>

        <div className="presentation-video-sticky-button-container">
          <button className="presentation-video-sticky-button" onClick={handleEditVideo}>
            {t("import-a-new-video")}
          </button>

          <div className="w-full d-flex justify-content-center" onClick={onClose}>
            <Typography.Text className="presentation-video-modal-cancel-button">{t("later-on")}</Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoExhaustedModalComponent;
