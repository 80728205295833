import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

type ChangePaymentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  changePaymentMethod: () => void;
  selectedMethod: string | null;
};

const ChangePaymentModalComponent: React.FC<ChangePaymentModalProps> = ({ isOpen, onClose, changePaymentMethod, selectedMethod }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const getModalMessage = () => {
    if (selectedMethod === "7") {
      return t("confirm-payment-method-7-days");
    } else if (selectedMethod === "30") {
      return t("confirm-payment-method-30-days");
    } else {
      return t("confirm-payment-method-pause");
    }
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-20 font-bold">{getModalMessage()}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          <button className="bottom-modal-button" onClick={changePaymentMethod}>
            <Typography.Text className="bottom-modal-button-title">{t("confirm")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePaymentModalComponent;
