import { Input, Layout, Row, Spin, Switch, Typography, message } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import AddMediaComponent from "../../../components/AddMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { IFeed } from "../../../helpers/types";
import { getMyFeeds } from "../../../redux/feeds/feedsSlice";
import { AppDispatch } from "../../../redux/store";
import { feedService, storageService } from "../../../services";
import "./index.less";

const CreatorPublicationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const textRef = useRef<any>();
  const dispatch = useDispatch<AppDispatch>();
  const [feed, setFeed] = useState<Partial<IFeed>>({ isPrivate: true, type: "photo", text: "" });
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);
  // const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [editText, setEditText] = useState<string>("");
  // const [isIdentifier, setIsIdentifier] = useState<boolean>(false);
  // const [identifiers, setIdentifiers] = useState<any>([]);
  // const [isLoading, setIsLoading] = useState<any>(true);
  const [quickFiles, setQuickFiles] = useState<any>({ id: "", type: "" });

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  const changedItem = (field: keyof IFeed, value: any) => {
    setFeed((prevFeed) => ({
      ...prevFeed,
      [field]: value,
    }));
  };

  const handleFilesChange = (fileList: UploadFile<[]>[]) => {
    setFiles(fileList);
  };

  const createFeed = async () => {
    if (files.length === 0 && !quickFiles.id) {
      return;
    }
    if (!quickFiles.id && !quickFiles.type) {
      setLoading(true);
      const feedData = new FormData();
      const file = files[0];

      if (file?.originFileObj?.type?.includes("image/")) {
        feedData.append(`files`, file.originFileObj);
      } else {
        setTotalSize(file.size);
        const result = await storageService.uploadFileMultiPart(file, setLoadedData);

        const videoFiles = [
          {
            Location: result.Location,
            Key: result.Key,
            ETag: result.ETag,
            Bucket: result.Bucket,
            filename: file.name,
            size: file.size,
          },
        ];
        feedData.append("videoFiles", JSON.stringify(videoFiles));
      }

      // Set the type of the feed based on the file type (photo or video)
      feed.type = files[0].type.includes("video") ? "video" : "photo";
      for (const [key, value] of Object.entries(feed)) {
        feedData.set(key, value);
      }

      const resp = feedService.createFeed(feedData, setProgress);
      resp
        .then((data: any) => {
          if (data.message) {
            message.error(data.message);
          } else {
            message.success(t("feed-created-successfully"));
            message.info(t("big-file-info"));
            setLoading(false);
            dispatch(getMyFeeds());
            navigate("/creator/profile/newPost");
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    } else {
      setLoading(true);
      const feedData = new FormData();
      feedData.append(`quickFiles`, quickFiles?.id);
      feed.type = quickFiles?.type;
      for (const [key, value] of Object.entries(feed)) {
        feedData.set(key, value);
      }

      const resp = feedService.createFeed(feedData, setProgress);
      resp
        .then((data: any) => {
          if (data.message) {
            message.error(data.message);
          } else {
            message.success(t("feed-created-successfully"));
            message.info(t("big-file-info"));
            setFiles([]);
            setQuickFiles({ id: "", type: "" });
            setLoading(false);
            dispatch(getMyFeeds());
            navigate("/creator/profile/newPost");
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  // const handleClickOk = () => {
  //   setIsEdit(false);
  //   setFeed((prevFeed) => ({
  //     ...prevFeed,
  //     text: editText,
  //   }));
  // };
  // const handleIdentifierClick = () => {
  //   const trimmedText = editText.trimEnd();
  //   const words = trimmedText.split(/\s+/);
  //   const lastWord = words[words.length - 1];
  //   const secondLastWord = words[words.length - 2] || "";

  //   if (lastWord === "" && secondLastWord === "@") {
  //     setEditText(`${trimmedText.slice(0, -1)} @`);
  //   } else if (lastWord !== "") {
  //     setEditText(`${editText} @`);
  //   } else if (editText === "") {
  //     setEditText("@");
  //   }

  //   textRef.current.focus();
  // };

  // useEffect(() => {
  //   const words = editText?.match(/(\S+|\s+)/g) || [];
  //   const lastWord = words[words.length - 1];
  //   if (lastWord) {
  //     if (lastWord === "@") {
  //       setIsLoading(true);
  //       setIsIdentifier(true);
  //     } else if (lastWord.startsWith("@") && lastWord.length > 1) {
  //       setIsIdentifier(true);
  //       const wordWithoutAt = lastWord.slice(1); // Remove "@" from the word
  //       if (wordWithoutAt) {
  //         searchUser(wordWithoutAt);
  //       } else {
  //         setIdentifiers([]);
  //       }
  //       return;
  //     } else {
  //       setIdentifiers([]);
  //       setIsIdentifier(false);
  //     }
  //   } else {
  //     setIdentifiers([]);
  //     setIsIdentifier(false);
  //   }
  // }, [editText]);

  // const searchUser = async (value: string) => {
  //   setIsLoading(true);
  //   const payload = {
  //     q: value,
  //     limit: 10,
  //   };
  //   await userService
  //     .search(payload)
  //     .then((res) => {
  //       setIdentifiers(res.data);
  //       if (res?.data.length === 0) {
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(true);
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.log(err?.message);
  //       setIsLoading(false);
  //     });
  // };

  // const handleProfileClick = (username: string) => {
  //   const words = editText?.match(/(\S+|\s+)/g) || [];
  //   if (words.length > 0) {
  //     const lastWord = words[words.length - 1];
  //     // Replace the last word in the sentence and add a space
  //     const updatedText = editText.replace(new RegExp(`${lastWord}$`), `@${username} `);
  //     setEditText(updatedText);
  //     textRef.current.focus();
  //   }
  // };

  return (
    <Spin
      spinning={loading}
      style={{ maxHeight: "100%" }}
      indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
    >
      <Layout>
        <Layout.Content className="content creatorPublicationContentWrapper gradient-background">
          <Row className="relative justify-content-center header-title-container">
            <ArrowLeftIconComponent top={10} left={0} navigateTo="/creator/profile" />
            <Typography.Text className="header-title-black-32">{t("publications")}</Typography.Text>
          </Row>
          <Row className="w-full mt-20 justify-content-center">
            <AddMediaComponent
              showTitle={false}
              maxCount={1}
              crop={true}
              onFilesChange={handleFilesChange}
              greenCircle={true}
              iconSize={80}
              addPublication={true}
              setQuickFiles={setQuickFiles}
            />
          </Row>
          <Row className="mt-20">
            <Input.TextArea
              className="captionInput"
              placeholder={`${t("add-a-caption")}...`}
              autoSize={{ minRows: 6, maxRows: 6 }}
              bordered={false}
              onChange={(value) => changedItem("text", value.target.value)}
            />
            {/* <Typography.Text
              className="font-15-regular text-color-offwhite"
              onClick={() => {
                setIsEdit(true);
                setTimeout(() => {
                  textRef.current.focus();
                }, 100);
              }}
            >
              {feed?.text ? feed.text : t("write-caption-tag-creators-text")}
            </Typography.Text> */}
          </Row>
          {/* <Divider className="publication-divider mt-50" /> */}
          <Row className="justify-content-center mt-30">
            <Row className="w-full justify-content-between">
              <Typography.Text className="font-24-bold text-white-color">{t("want-blur-media")}</Typography.Text>
              {/* <Row className="gap-10 items-center">
                <img src={LockIcon} width={24} alt="lock icon" />
                <Typography.Text className="font-24-bold text-white-color">{t("want-blur-media")}</Typography.Text>
              </Row> */}
              <Switch className="switch revenueSwitch" defaultChecked={feed.isPrivate} onChange={(value) => changedItem("isPrivate", value)} />
            </Row>
            <Row className="w-full justify-content-center mt-30">
              <AuthButtonComponent
                label={t("publish")}
                onClick={() => {
                  !loading && createFeed();
                }}
                disabled={files.length === 0}
              />
            </Row>
          </Row>
        </Layout.Content>
        {/* {isEdit && (
          <div className="creatorPublicationEditContainer">
            <Layout.Content className="creatorPublicationEditContentWrapper">
              <Row className="relative justify-content-center header-title-container items-center mt-8">
                <Typography.Text className="header-title-black-32">{t("caption")}</Typography.Text>
                <Typography.Text className="ok-text" onClick={handleClickOk}>
                  {t("OK")}
                </Typography.Text>
              </Row>
              <Divider className="publication-divider mt-8" />
              <Row className="mt-20">
                <Input.TextArea
                  className="captionInput"
                  placeholder={`${t("write-caption-tag-creators-text")}...`}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  bordered={false}
                  onChange={(e) => setEditText(e.target.value)}
                  ref={textRef}
                  value={editText}
                  inputMode="search"
                />
                {!isIdentifier && (
                  <Typography.Text className="font-14-regular Identify-btn" onClick={handleIdentifierClick}>
                    @ {t("identify")}
                  </Typography.Text>
                )}
              </Row>
              <Row>
                {isIdentifier &&
                  (identifiers?.length !== 0 ? (
                    <>
                      {identifiers.map((creator: any, index: any) => {
                        return (
                          <Col span={24}>
                            <Row
                              key={creator._id}
                              onClick={() => handleProfileClick(creator.username!)}
                              className="justify-between items-center mt-10"
                            >
                              <Col span={24}>
                                <Row className="items-center">
                                  <img
                                    className="avatar"
                                    src={creator.avatar !== undefined ? creator.avatar : ""}
                                    alt={creator.avatar !== undefined ? creator.avatar : ""}
                                  />
                                  <Space direction="vertical" className="recentVisitedTextWrapper">
                                    <Typography.Text className="UserName">
                                      {t(`${creator.username !== undefined ? creator.username : ""}`)}
                                    </Typography.Text>
                                    <Typography.Text className="FirstName">{creator.name}</Typography.Text>
                                  </Space>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        );
                      })}
                    </>
                  ) : (
                    !isLoading && <Typography.Text className="mx-auto text-white font-16-regular">No creator found!</Typography.Text>
                  ))}
              </Row>
            </Layout.Content>
          </div>
        )} */}
      </Layout>
    </Spin>
  );
};

export default CreatorPublicationPage;