import { Col, Layout, Row, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DirectLinksComponent from "../../../components/DirectLinksComponent";
import FooterComponent from "../../../components/FooterComponent";
import SearchComponent from "../../../components/Search/SearchComponent";
import { FOOTER_IDS, categories } from "../../../helpers/constant";
import { AppDispatch } from "../../../redux/store";
import { resetList } from "../../../redux/users/usersSlice";
import RecentVisitedProfiile from "../RecentVisitedProfile";
import IsSearchingComponent from "../../../components/Search/IsSearchingComponent";
import "./index.less";

const CategorySearchPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showCancelButton, setshowCancelButton] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const recentRef = useRef<HTMLInputElement | null>(null);
  const [showRecentVisitedProfile, setShowRecentVisitedProfile] = useState(false);
  const [loaded, setLoaded] = useState<any>({});

  useEffect(() => {
    categories?.forEach((category) => {
      const img = new Image();
      img.src = category.img;
      img.onload = () => {
        setLoaded((prev: any) => ({ ...prev, [category.id]: true }));
      };
    });
  }, []);

  const handleInputClick = () => {
    setShowRecentVisitedProfile(true);
    setshowCancelButton(true);
  };

  const categoryClicked = (categoryName: string) => {
    if (categoryName.includes("/")) {
      categoryName = categoryName.replace("/", "-");
    }
    navigate(`/common/search/${categoryName}`);
  };

  const searchCreators = (value: string) => {
    if (value !== undefined && value !== "") {
      setSearchValue(value);
      setIsSearching(true);
    } else {
      cancelSearching();
    }
  };

  const cancelSearching = () => {
    setSearchValue("");
    setIsSearching(false);
    setShowRecentVisitedProfile(false);
    setshowCancelButton(false);
    dispatch(resetList());
  };

  return (
    <Layout>
      <Layout.Content className="content fanCategorySearchContentWrapper gradient-background-black-blue no-scroll-horizontal">
        <Row className="header-title-container-80">
          <Typography.Text className="header-title-black-35">{t("search")}</Typography.Text>
        </Row>
        <Row className="items-center pr-15">
          <Col span={showCancelButton ? 19 : 24} ref={inputRef}>
            <SearchComponent
              onClick={handleInputClick}
              placeholder={`${t("search-2")}`}
              handleChange={(e) => searchCreators(e.target.value)}
              value={searchValue}
            />
          </Col>
          {showCancelButton && (
            <Col span={5} className="cancelText">
              <Typography.Text className="cancel-button cursor-pointer" onClick={cancelSearching}>
                {t("cancel")}
              </Typography.Text>
            </Col>
          )}
        </Row>

        <Row className="direct-links-wrapper">
          <DirectLinksComponent />
        </Row>

        {!showCancelButton && (
          <Row>
            <Typography.Text className="content-title mb-10 mt-20">{t("explore")}</Typography.Text>
          </Row>
        )}
        <Row className={`${showRecentVisitedProfile ? "mx-0 px-5 pt-5" : ""}`} gutter={isSearching ? 0 : 19}>
          {isSearching ? (
            <IsSearchingComponent isSearching={isSearching} searchValue={searchValue} />
          ) : !isSearching && showRecentVisitedProfile ? (
            <div className="w-full" ref={recentRef}>
              <RecentVisitedProfiile />
            </div>
          ) : (
            <div className="category-images-container">
              {categories.map((category, index) => (
                <Col span={index === 2 ? 24 : 12} key={index} className="image-col">
                  {loaded[category?.id] && (
                    <div className="image-wrapper" onClick={() => categoryClicked(category.id)}>
                      <img src={category.img} className="categoryItem" alt={category.name} />

                      <div className="image-overlay">
                        <div className={`title-category ${index === 2 ? "full-width-title" : ""}`}>{t(category.title)}</div>
                        <div className={`description ${index === 2 ? "full-width-description" : ""}`}>{t(category.description)}</div>
                      </div>
                    </div>
                  )}
                </Col>
              ))}
            </div>
          )}
        </Row>
      </Layout.Content>
      <FooterComponent page={FOOTER_IDS.SEARCH} />
    </Layout>
  );
};

export default CategorySearchPage;
