import React, { useState } from "react";
import { Row, Col, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ChevronIcon from "../../assets/icons/chevron-quick-answer.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import numberIcons from "../../utls/numberIcons";
import { IQuickMessage } from "../../helpers/types";
import CreatorDeleteQuickMessage from "../CreatorDeleteQuickMessageModal/CreatorDeleteQuickMessageModal";
import { useNavigate } from "react-router-dom";
import "./index.less";

type Props = {
  quickMessages: IQuickMessage[];
  handleSelectMessage?: (message: string | undefined) => void;
  confirmLoading: boolean;
};

const QuickMessagesComponent: React.FC<Props> = ({ quickMessages, handleSelectMessage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [quickMessageIdToDelete, setQuickMessageIdToDelete] = useState<string>("");

  return (
    <>
      <Row className="creatorSavedResponsesModalContent">
        {quickMessages.length > 0 ? (
          <Space className="creatorSavedResponsesList w-full gap-11" direction="vertical">
            {quickMessages.map((message, index) => (
              <div className={"d-flex items-center"} key={message._id}>
                <div className="number-icon">
                  <img src={numberIcons[index]} width={24} alt={`Number ${index + 1}`} />
                </div>
                <Row
                  key={message._id}
                  className="creatorSavedResponsesItem"
                  onClick={() => handleSelectMessage && handleSelectMessage(message?.text)}
                >
                  <Col span={20}>
                    <Row>
                      <Typography.Text className="font-16-bold text-white-color ellipsis-text">{message.title}</Typography.Text>
                    </Row>
                    <Row className="mt-5">
                      <Typography.Text className="font-13-regular text-grey-color ellipsis-text">{message.text}</Typography.Text>
                    </Row>
                  </Col>
                  <Col className="chevron-icon-wrapper">
                    <img src={ChevronIcon} width={24} />
                  </Col>
                </Row>
                {/* <Popconfirm
                title={t("delete-quick-answer-title")}
                description={t("delete-quick-answer-desc")}
                onConfirm={() => deleteQuickAnswer(message._id !== undefined ? message._id : "")}
                okButtonProps={{ loading: confirmLoading }}
                okText={t("yes")}
                cancelText={t("no")}
              > */}
                <div
                  onClick={() => {
                    setOpenModal(true);
                    setQuickMessageIdToDelete(message._id !== undefined ? message._id : "");
                  }}
                >
                  <img src={TrashIcon} width={38} />
                </div>
                {/* </Popconfirm> */}
              </div>
            ))}
          </Space>
        ) : (
          <div className="no-saved-responde-wrapper">
            <div className="no-saved-responde-container" onClick={() => navigate("/creator/newResponse")}>
              <Typography.Text className="no-saved-responde-text">{t("create-quick-response")}</Typography.Text>
            </div>
          </div>
        )}
        <Row className="centered-row">
          <Typography.Text className="font-12-regular text-grey-color">{t("quick-response-terms")}</Typography.Text>
        </Row>
      </Row>
      <CreatorDeleteQuickMessage isOpen={openModal} onClose={() => setOpenModal(false)} messageId={quickMessageIdToDelete} />
    </>
  );
};

export default QuickMessagesComponent;
