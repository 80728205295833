import { message, Row, Typography } from "antd";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";
import { ICollections, PageableData } from "../../../helpers/types";
import { collectionService } from "../../../services/collection.service";
import SpinnerComponent from "../../SpinnerComponent/SpinnerComponent";
import CollectionEditModalComponent from "../Modals/CollectionEditModalComponent";
import { deleteCollectionById } from "../../../redux/collections/collectionsSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import CollectionDeleteModalComponent from "../Modals/CollectionDeleteModalComponent";
import CollectionItemComponent from "../CollectionItemComponent";
import CollectionTrainingModalComponent from "../Modals/CollectionTrainingModalComponent";
import CollectionHideModalComponent from "../Modals/CollectionHideModalComponent";
import { checkModalStatus, updateModalStatus } from "../../../utls/formationModalUtils";
import "./index.less";

type CollectionGridProps = {
  isMyProfile?: boolean;
  creatorId?: string;
  username?: string;
  isPublic?: boolean;
  showModal?: () => void;
  goToPublicationTab?: () => void;
  setCollectionEditMode: (value: boolean) => void;
};

const ITEMS_PER_PAGE = 15;

const CollectionGridComponent: React.FC<CollectionGridProps> = ({
  isMyProfile = false,
  username,
  creatorId,
  isPublic = false,
  showModal,
  goToPublicationTab,
  setCollectionEditMode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<"idle" | "pending" | "succeeded" | "failed">("idle");
  const [collections, setCollections] = useState<ICollections[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [editCollectionModalOpen, setEditCollectionModalOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openHideModal, setOpenHideModal] = useState<boolean>(false);
  const [trainingCollectionModal, setTrainingCollectionModal] = useState<boolean>(false);
  const [collectionId, setCollectionId] = useState<string>("");
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const searchParams = new URLSearchParams(window.location.search);
  const collectionTab = searchParams.get("ctc");

  useEffect(() => {
    if (isMyProfile) {
      checkModalStatus("hasSeenTrainingCollectionModal", setTrainingCollectionModal);
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsEditMode(false);
        setCollectionEditMode(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        setLoading("pending");

        let res: PageableData<ICollections>;

        if (!isPublic) {
          res = isMyProfile
            ? await collectionService.getCreatorCollections(currentPage)
            : await collectionService.getFanCollections(creatorId!, currentPage);
        } else {
          res = await collectionService.getPublicCollections(creatorId!, currentPage);
          res.data = res?.data.map((item: any) => {
            delete item.price;
            return item;
          });
        }

        if (res.data.length === 0) {
          setHasMore(false);
          setLoading("succeeded");
          if (currentPage === 1) {
            goToPublicationTab && goToPublicationTab();
          }
          return;
        }

        setCollections((prevCollections) => uniqBy([...prevCollections, ...res.data], "_id"));
        setLoading("succeeded");
        const totalPages = Math.ceil(res.total / ITEMS_PER_PAGE);
        setHasMore(currentPage < totalPages);
      } catch (error) {
        setHasMore(false);
        setLoading("failed");
      }
    };

    if (hasMore && ((creatorId && creatorId !== "") || isMyProfile)) {
      fetchCollections();
    }
  }, [isMyProfile, creatorId, currentPage]);

  useEffect(() => {
    setCollections([]);
    setCurrentPage(1);
    setHasMore(true);
  }, [isMyProfile, creatorId]);

  const handleNavigation = (collectionId: string) => {
    if (isEditMode) {
      return null;
    }

    if (isPublic) {
      if (showModal) showModal();
    } else {
      if (isMyProfile) {
        navigate("/creator/collection/" + collectionId + "/" + username);
      } else {
        if(collectionTab){
          navigate("/common/collection/" + collectionId + "/" + username + "?ctc=collections");
        }else{
          navigate("/common/collection/" + collectionId + "/" + username);
        }
      }
    }
  };

  const loadMoreCollections = () => {
    if (hasMore && loading !== "pending") {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading === "pending",
    hasNextPage: hasMore,
    onLoadMore: loadMoreCollections,
  });

  const handleLongPressStart = () => {
    if (isMyProfile) {
      const timer = setTimeout(() => {
        setIsEditMode(true);
        setCollectionEditMode(true);
      }, 1000);
      setLongPressTimer(timer);
    }
  };

  const handleLongPressEnd = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
  };

  const handleEditCollection = (collection: ICollections) => {
    setEditCollectionModalOpen(true);
    setCollectionId(collection._id);
    setIsHidden(collection.isHidden);
  };

  const openModal = (value: string) => {
    switch (value) {
      case "delete":
        setEditCollectionModalOpen(false);
        setOpenDeleteModal(true);
        break;
      case "hide":
        setEditCollectionModalOpen(false);
        setOpenHideModal(true);
        break;
      default:
        break;
    }
  };

  const closeModal = (value: string) => {
    switch (value) {
      case "edit":
        setEditCollectionModalOpen(false);
        break;
      case "delete":
        setOpenDeleteModal(false);
        break;
      case "hide":
        setOpenHideModal(false);
        break;
      default:
        break;
    }
  };

  const deleteCollection = async () => {
    if (collectionId) {
      try {
        const resultAction = await dispatch(deleteCollectionById(collectionId));
        if (deleteCollectionById.fulfilled.match(resultAction)) {
          message.success(t("collection-successfully-deleted"));
          setOpenDeleteModal(false);
          window.location.reload();
        } else {
          console.error("Error deleting collection:", resultAction.payload);
        }
      } catch (error) {
        console.error("Error deleting collection:", error);
      }
    }
  };

  const hideShowCollection = async () => {
    if (collectionId) {
      try {
        collectionService.hideShowCollection(collectionId).then(() => {
          message.success(t("collection-successfully-modified"));
          setOpenHideModal(false);
          window.location.reload();
        });
      } catch (error) {
        console.error("Error when hiding the collection:", error);
      }
    }
  };

  const handleDragStart = (event: React.DragEvent, index: number) => {
    event.dataTransfer.setData("index", String(index));
  };

  const handleDrop = (event: React.DragEvent, index: number) => {
    const draggedIndex = Number(event.dataTransfer.getData("index"));
    const updatedCollections = [...collections];

    const draggedItem = updatedCollections[draggedIndex];
    const targetItem = updatedCollections[index];

    updatedCollections.splice(draggedIndex, 1);
    updatedCollections.splice(index, 0, draggedItem);
    setCollections(updatedCollections);

    sendReorderedDataToBackend(draggedItem._id, draggedItem.order, targetItem.order);

    event.preventDefault();
  };

  const sendReorderedDataToBackend = async (draggedCollectionId: string, draggedOrder: number, targetOrder: number) => {
    if (draggedOrder === targetOrder) return;
    try {
      await collectionService.updateCollectionOrder(draggedCollectionId, draggedOrder, targetOrder);
      message.success(t("successful-modification"));
    } catch (error) {
      console.error("Error updating collection order:", error);
      message.error(t("something-want-wrong"));
    }
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const getIt = async () => {
    updateModalStatus("hasSeenTrainingCollectionModal", setTrainingCollectionModal);
  };

  return (
    <div ref={containerRef}>
      <Row className="custom-spacing" gutter={4}>
        {collections.length === 0 && loading === "succeeded" && (
          <Row className="w-full justify-content-center mt-50">
            <Typography.Text className="font-16-bold text-white-color">{t("no-collection-at-the-moment")}</Typography.Text>
          </Row>
        )}
        {collections.map((collection, index) => (
          <CollectionItemComponent
            key={collection._id}
            index={index}
            collection={collection}
            isEditMode={isEditMode}
            isMyProfile={isMyProfile}
            showTrainingOverlay={index === 0 && trainingCollectionModal}
            onEdit={handleEditCollection}
            onNavigate={handleNavigation}
            onLongPressStart={handleLongPressStart}
            onLongPressEnd={handleLongPressEnd}
            draggable={isEditMode}
            onDragStart={(e: React.DragEvent) => handleDragStart(e, index)}
            onDrop={(e: React.DragEvent) => handleDrop(e, index)}
            onDragOver={handleDragOver}
            collectionHiddenPressed={() => {
              setIsEditMode(true);
              setCollectionEditMode(true);
            }}
          />
        ))}
        {(loading === "pending" || hasMore) && (
          <Row className="w-full justify-content-center mt-20">
            <SpinnerComponent refProp={sentryRef} />
          </Row>
        )}
      </Row>
      <CollectionEditModalComponent
        isOpen={editCollectionModalOpen}
        isHidden={isHidden}
        onClose={() => closeModal("edit")}
        openDeleteModal={() => openModal("delete")}
        openHideModal={() => openModal("hide")}
        navigateToDetails={() => {
          navigate("/creator/collection/" + collectionId + "/" + username);
        }}
      />
      <CollectionDeleteModalComponent isOpen={openDeleteModal} onClose={() => closeModal("delete")} deleteCollection={deleteCollection} />
      <CollectionHideModalComponent
        isOpen={openHideModal}
        hide={isHidden}
        onClose={() => closeModal("hide")}
        hideShowCollection={hideShowCollection}
      />
      {collections.length !== 0 && trainingCollectionModal && <CollectionTrainingModalComponent onClose={getIt} />}
    </div>
  );
};

export default CollectionGridComponent;
