import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface CollectionEditModalProps {
  isOpen: boolean;
  isHidden: boolean;
  openDeleteModal: () => void;
  openHideModal: () => void;
  navigateToDetails: () => void;
  onClose: () => void;
}

const CollectionEditModalComponent: React.FC<CollectionEditModalProps> = ({
  isOpen,
  isHidden,
  openDeleteModal,
  openHideModal,
  navigateToDetails,
  onClose,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-30">{t("what-do-you-want-to-do")}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          {isHidden ? (
            <>
              <button className="bottom-modal-button" onClick={openHideModal}>
                <Typography.Text className="bottom-modal-button-title">{t("display-collection-again")}</Typography.Text>
                <Typography.Text className="bottom-modal-button-desc mt-3">{t("display-collection-again-desc")}</Typography.Text>
              </button>
              <button className="bottom-modal-button mt-14" onClick={navigateToDetails}>
                <Typography.Text className="bottom-modal-button-title edit-collection">{t("Afficher les statistiques")}</Typography.Text>
              </button>
            </>
          ) : (
            <button className="bottom-modal-button" onClick={openHideModal}>
              <Typography.Text className="bottom-modal-button-title edit-collection">{t("hide-collection")}</Typography.Text>
              <Typography.Text className="bottom-modal-button-desc mt-3">{t("hide-collection-desc")}</Typography.Text>
            </button>
          )}

          <button className="bottom-modal-button mt-14" onClick={openDeleteModal}>
            <Typography.Text className="bottom-modal-button-title edit-collection">{t("delete-collection")}</Typography.Text>
            <Typography.Text className="bottom-modal-button-desc mt-3">{t("delete-collection-desc")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollectionEditModalComponent;
