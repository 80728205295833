import { Layout, Typography } from "antd";
import ChevronBack from "../../../../assets/icons/chevron-back-other-profile.svg";
import ChevronRight from "../../../../assets/icons/discover-chevron.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LockImg from "../../../../assets/images/lock.webp";
import ChatImg from "../../../../assets/images/conversation.webp";
import SearchImg from "../../../../assets/images/search.webp";
import LiveImg from "../../../../assets/images/live-tips.webp";
import CollectionImg from "../../../../assets/images/collection.webp";
import MediaPushImg from "../../../../assets/images/media-push.webp";
import Profile1Img from "../../../../assets/images/edit-cover-1.webp";
import Profile2Img from "../../../../assets/images/edit-cover-2.webp";
import Paiement1Img from "../../../../assets/images/paiement-img-1.webp";
import Paiement2Img from "../../../../assets/images/paiement-img-2.webp";
import ProfileTrunkImg from "../../../../assets/images/profile-trunk.webp";
import PresentationVideoImg from "../../../../assets/images/presentation-video.webp";
import { IUser } from "../../../../helpers/types";
import { authService } from "../../../../services";
import { useLayoutEffect } from "react";
import "./index.less";

const CreatorFormationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user: IUser = authService.getUser();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const handleNavigate = (id: string) => {
    navigate("/creator/formation/" + id);
  };

  return (
    <Layout className="no-scroll-horizontal">
      <Layout.Content className="creator-formations-page-wrapper">
        <div className="formation-header">
          <div className="back-icon">
            <img
              src={ChevronBack}
              width={13}
              alt="arrow left"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>

          <div className="formation-header-creator-name-row">
            <Typography.Text className="formation-header-creator-name-text">Hello {user.firstName},</Typography.Text>
          </div>

          <div className="formation-header-creator-title-row">
            <Typography.Text className="formation-header-creator-title-text">{t("how-can-we-help-you")}</Typography.Text>
          </div>
        </div>

        <div className="formation-content">
          {/* FORMATION VIDEO ROW */}
          {/* <div className="formation-item w-full h-180">
            <div className="formation-item-text-container">
              <Typography.Text className="formation-item-title text-center mt-8">{t("a-z-tutorial")}</Typography.Text>
              <div className="py-20 mt-5">
                <Typography.Text className="formation-item-description d-flex text-center">{t("formation-description")}</Typography.Text>
              </div>

              <div className="w-full d-flex justify-content-center mt-18 mb-10">
                <div
                  className="formation-item-button"
                  onClick={() => {
                    handleNavigate("training-video");
                  }}
                >
                  {t("discover-training")}
                </div>
              </div>
            </div>
          </div> */}

          {/* MEDIAS PUSH AND COLLECTION ROW */}
          <div className="formation-grid">
            <div
              className="formation-item"
              onClick={() => {
                handleNavigate("media-push");
              }}
            >
              <div className="formation-item-img-container linear-background-blue media">
                <img className="formation-item-img mt-10 media-push-first" src={MediaPushImg} alt="media push 1" />
                <img className="formation-item-img mt-10 media-push-second" src={MediaPushImg} alt="media push 2" />
              </div>
              <div className="formation-item-text-container">
                <Typography.Text className="formation-item-title">{t("tutorial-media-push")}</Typography.Text>
                <Typography.Text className="formation-item-description">{t("media-push-description")}</Typography.Text>
                <div className="discover-container">
                  <Typography.Text className="discover-text">{t("discover")}</Typography.Text>
                  <img src={ChevronRight} className="formation-chevron-right" alt="discover" />
                </div>
              </div>
            </div>
            <div
              className="formation-item"
              onClick={() => {
                handleNavigate("collection");
              }}
            >
              <div className="formation-item-img-container">
                <img className="formation-item-img collection" src={CollectionImg} alt="collection" />
              </div>
              <div className="formation-item-text-container">
                <Typography.Text className="formation-item-title">{t("tutorial-collections")}</Typography.Text>
                <Typography.Text className="formation-item-description">{t("collections-description")}</Typography.Text>
                <div className="discover-container">
                  <Typography.Text className="discover-text">{t("discover")}</Typography.Text>
                  <img src={ChevronRight} className="formation-chevron-right" alt="discover" />
                </div>
              </div>
            </div>
          </div>

          {/* MESSAGERIE AND LIVES ROW */}
          <div className="formation-grid">
            <div
              className="formation-item"
              onClick={() => {
                handleNavigate("chat");
              }}
            >
              <div className="formation-item-img-container">
                <img className="formation-item-img chat" src={ChatImg} alt="chat" />
              </div>
              <div className="formation-item-text-container">
                <Typography.Text className="formation-item-title">{t("tutorial-messaging")}</Typography.Text>
                <Typography.Text className="formation-item-description">{t("messaging-description")}</Typography.Text>
                <div className="discover-container">
                  <Typography.Text className="discover-text">{t("discover")}</Typography.Text>
                  <img src={ChevronRight} className="formation-chevron-right" alt="discover" />
                </div>
              </div>
            </div>
            <div
              className="formation-item"
              onClick={() => {
                handleNavigate("live");
              }}
            >
              <div className="formation-item-img-container linear-background-blue">
                <img className="formation-item-img mt-10 mb-10 gift" src={LiveImg} alt="live" />
              </div>
              <div className="formation-item-text-container">
                <Typography.Text className="formation-item-title">{t("tutorial-lives")}</Typography.Text>
                <Typography.Text className="formation-item-description">{t("lives-description")}</Typography.Text>
                <div className="discover-container">
                  <Typography.Text className="discover-text">{t("discover")}</Typography.Text>
                  <img src={ChevronRight} className="formation-chevron-right" alt="discover" />
                </div>
              </div>
            </div>
          </div>

          {/* UNLOCKS AND PROFILE ROW */}
          <div className="formation-grid">
            <div
              className="formation-item"
              onClick={() => {
                handleNavigate("unlock");
              }}
            >
              <div className="formation-item-img-container">
                <img className="formation-item-img lock" src={LockImg} alt="lock" />
              </div>
              <div className="formation-item-text-container">
                <Typography.Text className="formation-item-title">{t("unlocks")}</Typography.Text>
                <Typography.Text className="formation-item-description">{t("unlocks-description")}</Typography.Text>
                <div className="discover-container">
                  <Typography.Text className="discover-text">{t("discover")}</Typography.Text>
                  <img src={ChevronRight} className="formation-chevron-right" alt="discover" />
                </div>
              </div>
            </div>
            <div
              className="formation-item"
              onClick={() => {
                handleNavigate("profile");
              }}
            >
              <div className="formation-item-img-container profile">
                <img className="formation-item-img mt-10 profile-first" src={Profile1Img} alt="profile 1" />
                <img className="formation-item-img mt-10 profile-second" src={Profile2Img} alt="profile 2" />
              </div>
              <div className="formation-item-text-container">
                <Typography.Text className="formation-item-title">{t("tutorial-profile")}</Typography.Text>
                <Typography.Text className="formation-item-description">{t("profile-description")}</Typography.Text>
                <div className="discover-container">
                  <Typography.Text className="discover-text">{t("discover")}</Typography.Text>
                  <img src={ChevronRight} className="formation-chevron-right" alt="discover" />
                </div>
              </div>
            </div>
          </div>

          {/* SEARCH AND PAIEMENTS ROW */}
          <div className="formation-grid">
            <div
              className="formation-item"
              onClick={() => {
                handleNavigate("presentation-videos");
              }}
            >
              <div className="formation-item-img-container">
                <img className="formation-item-img search" src={PresentationVideoImg} alt="search" />
              </div>
              <div className="formation-item-text-container">
                <Typography.Text className="formation-item-title">{t("presentation-video")}</Typography.Text>
                <Typography.Text className="formation-item-description">{t("formation-presentation-video-description")}</Typography.Text>
                <div className="discover-container">
                  <Typography.Text className="discover-text">{t("discover")}</Typography.Text>
                  <img src={ChevronRight} className="formation-chevron-right" alt="discover" />
                </div>
              </div>
            </div>
            <div
              className="formation-item"
              onClick={() => {
                handleNavigate("payment");
              }}
            >
              <div className="formation-item-img-container lineard-background-dark paiement">
                <img className="formation-item-img mt-10 paiement-first" src={Paiement2Img} alt="paiement 1" />
                <img className="formation-item-img mt-10 paiement-second" src={Paiement1Img} alt="paiement 2" />
              </div>
              <div className="formation-item-text-container">
                <Typography.Text className="formation-item-title">{t("payments")}</Typography.Text>
                <Typography.Text className="formation-item-description">{t("payments-description")}</Typography.Text>
                <div className="discover-container">
                  <Typography.Text className="discover-text">{t("discover")}</Typography.Text>
                  <img src={ChevronRight} className="formation-chevron-right" alt="discover" />
                </div>
              </div>
            </div>
          </div>

          {/* GENERATE TRAFFIC */}
          {/* <div className="formation-item w-full generate-traffic h-180">
            <div className="formation-item-left-section">
              <Typography.Text className="formation-item-title">{t("generate-traffic")}</Typography.Text>
              <Typography.Text className="formation-item-description">{t("generate-traffic-description")}</Typography.Text>
              <div className="w-full mt-10 mb-6">
                <div
                  className="formation-item-button"
                  onClick={() => {
                    handleNavigate("generate-traffic");
                  }}
                >
                  {t("discover-training")}
                </div>
              </div>
            </div>

            <div className="formation-item-right-section">
              <img className="profile-trunk-img" src={ProfileTrunkImg} alt="trunk" />
            </div>
          </div> */}
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorFormationPage;
