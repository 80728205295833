import { Button, Col, Layout, Row, Space, Spin, Tabs, Typography, message } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SwipeHandUp from "../../../assets/gif/finger-animation.gif";
import ActiveCollectionIcon from "../../../assets/icons/active-collection.svg";
import ActiveGridIcon from "../../../assets/icons/active-grid.svg";
import BookmarkFilledOtherProfileIcon from "../../../assets/icons/bookmark-filled-other-profile.svg";
import BookmarkOtherProfileIcon from "../../../assets/icons/bookmark-other-profile.svg";
import BurgerMenuIcon from "../../../assets/icons/burger-menu.svg";
import ChatIcon from "../../../assets/icons/chat-icon.svg";
import ChevronBackOtherProfileIcon from "../../../assets/icons/chevron-back-other-profile.svg";
import CollectionIcon from "../../../assets/icons/collection.svg";
import GridIcon from "../../../assets/icons/grid.svg";
import VideoIcon from "../../../assets/icons/jouer.svg";
import LiveIcon from "../../../assets/icons/live-icon.svg";
import LocationIcon from "../../../assets/icons/location.svg";
import LockIcon from "../../../assets/icons/lock-outlined-without-point.svg";
import ShareOtherProfileIcon from "../../../assets/icons/share-other-profile.svg";
import VerifyIcon from "../../../assets/icons/verify.svg";
import BellImg from "../../../assets/images/bell.webp";
import AvatarComponent from "../../../components/AvatarComponent";
import CollectionCancelEditModeButtonComponent from "../../../components/Collections/CollectionCancelEditModeButtonComponent";
import CollectionGridComponent from "../../../components/Collections/CollectionGridComponent";
import PresentationVideoButton from "../../../components/discover/common/PresentationVideoButton";
import NoVideoPresentationComponent from "../../../components/discover/creator/NoVideoPresentationComponent";
import RenewPresentationComponent from "../../../components/discover/creator/RenewPresentationComponent";
import FanProfileTrainingOverlayComponent from "../../../components/FanProfileTrainingOverlayComponent";
import FanReportCreatorModalComponent from "../../../components/FanReportCreatorModalComponent";
import FooterComponent from "../../../components/FooterComponent";
import FeedMediaComponent from "../../../components/MediaComponent/FeedMediaComponent";
import NoPublicationComponent from "../../../components/NoPublicationComponent";
import ShareModalComponent from "../../../components/ShareModalComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import ThreeDotsComponent from "../../../components/ThreeDotsComponent";
import { CREATOR, FAN, FILE_STATUS, FOOTER_IDS, PAYMENT_TYPES, THUMBNAIL_TYPES } from "../../../helpers/constant";
import { IConversation } from "../../../helpers/types";
import { resetFanDiscovers } from "../../../redux/discovers/discoversSlice";
import { getFeedsByCreatorId, getMyFeeds, loadMoreFeedsByCreatorId, updateFile } from "../../../redux/feeds/feedsSlice";
import { getCreatorScheduleLive } from "../../../redux/lives/livesSlice";
import { getConversationById, resetUserConversations, selectConversation } from "../../../redux/messages/messagesSlice";
import { createReaction, deactivatedReaction } from "../../../redux/reactions/reactionsSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { getMyProfile, getProfileById } from "../../../redux/users/usersSlice";
import { authService, liveService, messageService, reactionService, reportService, subscriptionsService, userService } from "../../../services";
import { socket } from "../../../socket";
import { APP_NAME } from "../../../utls/constants";
import { checkModalStatus, updateModalStatus } from "../../../utls/formationModalUtils";
import { formatSpaceNumber, removeActiveProfileTabFromStorage } from "../../../utls/FunctionsUtil";
import liveUtil from "../../../utls/LiveUtil";
import "./index.less";

type props = {
  isMyProfile?: boolean;
};

const MAX_NAME_LENGTH = 16;

const ProfilePage: React.FC<props> = ({ isMyProfile }) => {
  const limit = 15;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const collectionTab = searchParams.get("ctc");
  const search = searchParams.get("search");
  const { username } = useParams();
  const tokenId = authService.getId();
  const type = authService.getRole();
  const shouldFetchUserId = useRef(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const { item: user, currentUserProfile, error } = useSelector((state: StoreState) => state.users);
  const feeds = useSelector((state: StoreState) => state.feeds.list);
  const feedsIsLoading = useSelector((state: StoreState) => state.feeds.loading);
  const currentUserFeedsLoading = useSelector((state: StoreState) => state.feeds.currentUserLoading);
  const currentUserFeeds = useSelector((state: StoreState) => state.feeds.currentUserFeeds);
  const totalPages = useSelector((state: StoreState) => state.feeds.total);
  const scheduleLive = useSelector((state: StoreState) => state.lives.item);
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [followed, setFollowed] = useState<any>(false);
  const [skip, setSkip] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [hasMore, setHasMore] = useState<any>(false);
  const [isVerifyPaymentForScheduleLive, setIsVerifyPaymentForScheduleLive] = useState<boolean>(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [id, setId] = useState("");
  const isLongName = currentUserProfile?.name ? currentUserProfile.name.length > MAX_NAME_LENGTH : false;
  const iconSize = isLongName ? "16px" : "22px";
  const pageTitle = user?.username ? `${user.username} ${APP_NAME}` : APP_NAME;
  const pageDescription = username ? t("profile_description", { username, appName: APP_NAME }) : t("app_description", { appName: APP_NAME });

  //TODO: this needs to be changed it's a quick fix
  const [showContent, setShowContent] = useState<boolean>(isMyProfile ?? false);
  const [activeTab, setActiveTab] = useState<string>(user?.defaultPage || "collection");
  const [showOverlay, setShowOverlay] = useState(false);
  const [fanModalstep, setFanModalstepStep] = useState(1);
  const [collectionEditMode, setCollectionEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (!isMyProfile) {
      checkModalStatus("hasSeenFanProfileModal", setShowOverlay);
    }

    const timer = setTimeout(() => {
      !isMyProfile && setShowContent(true);
    }, 270);

    function handleFileUpdated(data: { fileId: string; absolutePath: string; thumbnails: any; status: string }) {
      dispatch(updateFile(data));
    }

    socket.on("file-updated", handleFileUpdated);

    return () => {
      socket.off("file-updated", handleFileUpdated);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const activeTabFromLocalStorage = localStorage.getItem("activeProfileTab");
    if (activeTabFromLocalStorage) {
      setActiveTab(activeTabFromLocalStorage);
    } else {
      setActiveTab(user?.defaultPage || "collection");
    }
  }, [user]);

  useEffect(() => {
    document.title = pageTitle;
    let metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    if (metaDescription) {
      metaDescription.setAttribute("content", pageDescription);
    } else {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = pageDescription;
      document.head.appendChild(metaDescription);
    }
    let metaRobots = document.querySelector('meta[name="robots"]') as HTMLMetaElement;
    if (!metaRobots) {
      metaRobots = document.createElement("meta");
      metaRobots.name = "robots";
      document.head.appendChild(metaRobots);
    }
    metaRobots.setAttribute("content", "noimageindex");

    return () => {
      document.title = APP_NAME;
      if (metaDescription) {
        metaDescription.setAttribute("content", "Loly");
      }
      if (metaRobots) {
        metaRobots.setAttribute("content", "");
      }
    };
  }, [pageTitle, pageDescription]);

  useEffect(() => {
    if (authService.isAuthenticated() && username && id === "" && shouldFetchUserId.current) {
      shouldFetchUserId.current = false;
      userService
        .getUserIdByUsername(username)
        .then((id) => {
          setId(id);
        })
        .catch((error) => {
          message.error(t(error));
          navigate("/");
        });
    }
  }, [username]);

  useEffect(() => {
    if (scheduleLive?._id && id && !isMyProfile && id !== tokenId) {
      liveService
        .verifyPayment(scheduleLive._id, tokenId)
        .then((value) => setIsVerifyPaymentForScheduleLive(value))
        .catch((error) => console.log(error));
    }
  }, [scheduleLive]);

  useEffect(() => {
    if (feeds.length !== 0 && totalPages !== -1) {
      setCurrentPage(Math.ceil((totalPages - feeds?.length) / limit));
      setSkip(feeds?.length || 0);
      setHasMore(feeds.length < totalPages || feeds.length !== totalPages);
    }
  }, [feeds, totalPages, currentPage]);

  const loadMoreFeed = () => {
    if (hasMore && id !== "") {
      dispatch(loadMoreFeedsByCreatorId({ creatorId: id, skip: skip, limit }));
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: feedsIsLoading === "pending",
    hasNextPage: hasMore,
    onLoadMore: loadMoreFeed,
  });

  useEffect(() => {
    if (isMyProfile || id === tokenId) {
      if (!currentUserProfile) {
        dispatch(getMyProfile());
      }
      //if (currentUserFeeds?.length === 0) {
      dispatch(getMyFeeds());
      //}
      dispatch(getCreatorScheduleLive(tokenId));
    } else if (id !== "") {
      dispatch(getProfileById(id));
      dispatch(getCreatorScheduleLive(id));
      //if (user?._id !== id || feeds.length === 0) {
      dispatch(getFeedsByCreatorId({ creatorId: id, skip: 0, limit }));
      //}
    }
  }, [id, isMyProfile]);

  /* useEffect(() => {
    if (isMyProfile || id === tokenId) {
      setFeedsItems(currentUserFeeds);
    } else {
      if (id !== "") {
        setFeedsItems(feeds);
      }
    }
  }, [currentUserFeeds, feeds, id, isMyProfile, user?._id]); */

  useEffect(() => {
    if (user?._id && !(isMyProfile || id === tokenId)) {
      const data = {
        creatorId: user?._id,
        fanId: authService.getId(),
      };
      subscriptionsService
        .verifySubscription(data)
        .then((response) => {
          setSubscribed(response);
          createInterestedReaction(response);
        })
        .catch((error) => {
          console.log(error);
        });
      reactionService
        .verifyFollow(data)
        .then((response) => {
          setFollowed(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);

  const createInterestedReaction = (isSubscribed: boolean) => {
    if (!(isMyProfile || id === tokenId) && id !== "" && !isSubscribed) {
      const payload = {
        action: "interested",
        objectId: id,
        objectType: "creator",
      };
      dispatch(createReaction(payload));
    }
  };

  const onSubscribeClick = (creatorId: string) => {
    if (error !== "user-not-found") {
      if (subscribed) {
        message.success(t("already-subscribed-creator"));
      } else {
        navigate(`/common/subscribe/${creatorId}`);
      }
    }
  };

  const goToSettings = () => {
    navigate("/common/setting");
  };

  const follow = async (creatorId: string) => {
    if (error !== "user-not-found") {
      const payload = {
        action: "follow",
        objectId: creatorId,
        objectType: "creator",
      };
      await dispatch(createReaction(payload));
      setFollowed(true);
      message.success(t("you-are-now-following") + " " + user?.name);
    }
  };

  const unfollow = async (creatorId: string) => {
    if (error !== "user-not-found") {
      const payload = {
        action: "follow",
        objectId: creatorId,
        objectType: "creator",
      };
      await dispatch(deactivatedReaction(payload));
      setFollowed(false);
    }
  };

  async function onReportClick(creatorId: string) {
    const payload = {
      target: "creator",
      targetId: creatorId,
      title: "report",
      description: "",
    };
    const resp = await reportService.createReport(payload);
    if (resp?.data) {
      message.success(t("report-success"));
    } else if (resp?.message) {
      message.error(resp.message);
    }
    setIsReportModalOpen(false);
  }

  const onClickGetTicket = () => {
    if (!scheduleLive) return;
    navigate(
      `/fan/payment?creatorId=${scheduleLive?.creator?._id}&type=${PAYMENT_TYPES.LIVE}&liveId=${scheduleLive._id}&redirectUrl=common/profile/${user?._id}`
    );
  };

  const onClickJoinLive = () => {
    if (!scheduleLive && !scheduleLive!.isStreaming) return;
    navigate(`/fan/live/${scheduleLive?._id}`);
  };

  const onClickLiveEventDetails = () => {
    if (!scheduleLive && scheduleLive!.isStreaming) return;
    navigate(`/fan/event/${scheduleLive?._id}`);
  };

  const displayActionBtn = () => {
    if (isMyProfile || id === tokenId) {
      const liveStartTime = dayjs(scheduleLive?.scheduleData?.dateTime) as Dayjs;
      const timeNow = dayjs();
      if (liveStartTime.isAfter(timeNow)) {
        return (
          <Button
            className="editLiveButton"
            onClick={() => {
              navigate(`/creator/live/event/detail/${scheduleLive!._id}`);
            }}
          >
            {t("edit")}
          </Button>
        );
      } else if (liveStartTime.isBefore(timeNow.add(10, "minute"))) {
        return (
          <Button
            className="editLiveButton"
            onClick={() => {
              navigate(`/creator/live/publish/${scheduleLive?._id}`);
            }}
          >
            {t("start")}
          </Button>
        );
      } else {
        return <React.Fragment />;
      }
    } else if (!isMyProfile && id !== tokenId) {
      if (!isVerifyPaymentForScheduleLive) {
        return (
          <Button className="editLiveButton" onClick={onClickGetTicket}>
            {t("take-ticket")}
          </Button>
        );
      } else {
        const isLive = scheduleLive?.isStreaming === true;
        return (
          <Button className="editLiveButton" onClick={isLive ? onClickJoinLive : onClickLiveEventDetails}>
            {t(isLive ? "join" : "view-details")}
          </Button>
        );
      }
    }
  };

  const writeClicked = async () => {
    if (!user || error === "user-not-found") {
      return;
    }
    const currentUserRole = authService.getRole();
    let urlToNavigate = currentUserRole === CREATOR ? "/creator/conversation/new" : "/fan/requestMedia/new";
    const currentUserId = authService.getId();
    const conversationId = await messageService.isConversationExist({
      fanId: currentUserId,
      creatorId: user._id !== undefined ? user._id : "",
    });
    if (conversationId === null) {
      const newConversation: IConversation = {
        _id: "new",
        fanCanSendMessage: true,
        interlocutor: {
          userId: user._id,
          avatar: user.avatar,
          name: user.name,
          role: CREATOR,
        },
        currentUser: {
          userId: currentUserId,
          avatar: "",
          name: "",
          role: FAN,
        },
        chat: user.chat,
      };
      dispatch(selectConversation(newConversation));
      urlToNavigate = urlToNavigate + `?newConversation=${JSON.stringify(newConversation)}`;
    } else {
      dispatch(resetUserConversations());
      dispatch(getConversationById(conversationId));
      urlToNavigate = urlToNavigate.replace("new", conversationId);
    }
    navigate(urlToNavigate, { replace: false });
  };

  const feedsToRender = isMyProfile || id === tokenId ? currentUserFeeds : feeds;
  const userToRender = isMyProfile || id === tokenId ? currentUserProfile : user;
  const fromPayment = sessionStorage.getItem("payment-page") === "True";

  const handleNavigate = () => {
    localStorage.removeItem("activeProfileTab");
    if (fromPayment) {
      navigate("/common/discover");
      sessionStorage.removeItem("payment-page");
    } else if (collectionTab) {
      if (search) {
        navigate("/common/discover");
      } else {
        navigate(-3);
      }
    } else {
      navigate(-1);
    }
  };

  const handleTabChange = (key: string) => {
    localStorage.setItem("activeProfileTab", key);
    setActiveTab(key);
  };

  const fanModalNextStep = () => {
    if (fanModalstep === 1) {
      setFanModalstepStep(2);
    } else {
      setShowOverlay(false);
      updateModalStatus("hasSeenFanProfileModal", setShowOverlay);
    }
  };

  const handlePresentationVideoNavigation = () => {
    if (isMyProfile || id === tokenId) {
      navigate("/creator/create/presentation/videos");
    } else {
      dispatch(resetFanDiscovers());
      navigate(`/common/presentation/videos/${userToRender?.username}`);
    }
  };

  return (
    <Layout className="gradient-background-profile no-scroll-horizontal">
      <Layout.Content className="content fanProfileContentWrapper background-transparent">
        {showContent && (
          <>
            {(isMyProfile || id === tokenId) && (
              <>
                <Row className="header-container">
                  <Typography.Text className="header-my-profile-text">@{userToRender?.username}</Typography.Text>
                  <div className="settings-icon-container" onClick={goToSettings}>
                    <img src={BurgerMenuIcon} width={20} alt="settings" />
                  </div>
                </Row>

                <div className="fanProfileTopWrapper">
                  <Row align="middle">
                    <Col className="colAvatar mr-5">
                      <AvatarComponent image={userToRender?.avatar || ""} size={125} fontSize={16} fontWeight={true} />
                    </Col>
                    <Col className="colDetails">
                      <Row className="nameRow" align="middle">
                        <Row className="ml-20 mt-10 max-w-80 justify-content-between" align="middle">
                          <Space>
                            <Col className="colValues">
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeValue">{userToRender?.stats?.totalFeeds || 0}</Typography.Text>
                              </Row>
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeTitle">{t("publications")}</Typography.Text>
                              </Row>
                            </Col>
                            <Col
                              className="colValues cursor-pointer"
                              onClick={() => {
                                navigate("/creator/allSubscribers");
                              }}
                            >
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeValue">
                                  {formatSpaceNumber(userToRender?.stats?.subscribers || 0)}
                                </Typography.Text>
                              </Row>
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeTitle">{t("subscribers")}</Typography.Text>
                              </Row>
                            </Col>
                            <Col className="colValues">
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeValue">
                                  {formatSpaceNumber(userToRender?.stats?.followers || 0)}
                                </Typography.Text>
                              </Row>
                              <Row className="fanProfileAttributeRow">
                                <Typography.Text className="fanProfileAttributeTitle">{t("interested")}</Typography.Text>
                              </Row>
                            </Col>
                          </Space>
                        </Row>
                      </Row>

                      <Row className="buttonRow mt-20" align="middle">
                        <Space>
                          <Col>
                            <Button className="editProfileBtn" onClick={() => navigate("/creator/editProfile")}>
                              {t("To-modify")}
                            </Button>
                          </Col>
                          <Col>
                            <Button className="editProfileBtn" onClick={() => setIsShareModalOpen(true)}>
                              {t("share-profile")}
                            </Button>
                            {/* <img src={NewShareIcon} className="share-icon" alt="share" onClick={() => setIsShareModalOpen(true)} /> */}
                          </Col>
                        </Space>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="labelWrapper">
                  <Typography.Text className="nameTitle">
                    {error !== "user-not-found" ? <>{userToRender?.name}</> : t(error as string)}
                  </Typography.Text>
                  {userToRender?.verifiedAccount && <img className="verifyIcon" src={VerifyIcon} width={14} alt="verify" />}
                  {userToRender?.hasDiscovers && (
                    <PresentationVideoButton
                      onClick={handlePresentationVideoNavigation}
                      notificationVisible={userToRender?.expiredDiscovers || false}
                    />
                  )}
                  {userToRender?.city && (
                    <Row className="">
                      <Col className="colValues location mt-5">
                        <img className="locationImg mr-5" src={LocationIcon} width={16} alt="location" />
                        <Typography.Text className="fanProfileLocationLabel">{userToRender.city}</Typography.Text>
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-10">
                    <div
                      className="fanProfileDescriptionLabel"
                      dangerouslySetInnerHTML={{
                        __html: (userToRender?.description || "").replace(/\n/g, "<br />"),
                      }}
                    />
                  </Row>
                </div>
              </>
            )}
            {!isMyProfile && id !== tokenId && (
              <>
                <Row className="header-container">
                  <img
                    src={ChevronBackOtherProfileIcon}
                    width={13}
                    alt="arrow left"
                    onClick={() => {
                      handleNavigate();
                    }}
                  />
                  <h2 className="header-text-other-profile">@{user?.username}</h2>

                  <div>
                    {followed ? (
                      <img
                        src={BookmarkFilledOtherProfileIcon}
                        className="mr-15"
                        width={16}
                        alt="bookmark"
                        onClick={() => unfollow(id !== "" ? id : "")}
                      />
                    ) : (
                      <img src={BookmarkOtherProfileIcon} className="mr-15" width={16} onClick={() => follow(id !== "" ? id : "")} />
                    )}
                    <img className="mr-10" src={ShareOtherProfileIcon} width={20} alt="share" onClick={() => setIsShareModalOpen(true)} />
                  </div>
                </Row>
                <div className="fanProfileTopWrapper">
                  <Row align="middle">
                    <Col className="colAvatar mr-10">
                      <AvatarComponent image={userToRender?.avatar || ""} size={105} fontSize={16} fontWeight={true} isLargeView={true} />
                    </Col>
                    <Col className="colDetails">
                      <Row className="nameRow" align="middle">
                        <Typography.Text className={`nameTitleOtherProfile`}>
                          {error !== "user-not-found" ? <>{userToRender?.name}</> : t(error as string)}
                        </Typography.Text>
                        {userToRender?.verifiedAccount && <img className="verifyIcon" src={VerifyIcon} width={iconSize} alt="verify" />}
                      </Row>

                      <Row className="mt-5" align="middle">
                        <Space>
                          {/* <Col className={`${!subscribed ? "border-btn" : ""}`}> */}
                          <Button className={`subscribeBtn ${subscribed ? "unsubscribe" : ""}`} onClick={() => onSubscribeClick(id !== "" ? id : "")}>
                            {t(`${subscribed ? "subscriber" : "Subscribe-without-obligation"}`)}
                          </Button>
                          {/* </Col> */}
                          <img
                            className="mt-3"
                            width={44}
                            src={ChatIcon}
                            onClick={() => {
                              writeClicked();
                            }}
                            alt="chat"
                          />
                          {/* <Button
                        className={"fanProfileWriteBtn btn-size"}
                        onClick={() => {
                          writeClicked();
                        }}
                      >
                        {t("write")}
                      </Button> */}
                        </Space>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="labelWrapper">
                  {userToRender?.city && (
                    <Row className="m-5">
                      <Col className="colValues location">
                        <img className="locationImg mr-5" src={LocationIcon} width={16} alt="location" />
                        <Typography.Text className="fanProfileValue">{userToRender.city}</Typography.Text>
                      </Col>
                    </Row>
                  )}
                  {userToRender?.hasDiscovers && <PresentationVideoButton onClick={handlePresentationVideoNavigation} notificationVisible={false} />}
                  <Row className="mt-10">
                    <div
                      className="fanProfileDescriptionLabel"
                      dangerouslySetInnerHTML={{
                        __html: (userToRender?.description || "").replace(/\n/g, "<br />"),
                      }}
                    />
                  </Row>

                  <Row className="mt-10 max-w-80 justify-content-between" align="middle">
                    <div className="leftItems">
                      <Space>
                        <Col
                          className="colValues"
                          onClick={() => {
                            handleTabChange("feeds");
                          }}
                        >
                          <Typography.Text className="fanProfileAttributeValue">{userToRender?.stats?.totalFeeds || 0}</Typography.Text>
                          <Typography.Text className="fanProfileValue">{t("publications")}</Typography.Text>
                        </Col>
                      </Space>

                      <Space>
                        <Col
                          className="colValues"
                          onClick={() => {
                            handleTabChange("collection");
                          }}
                        >
                          <Typography.Text className="fanProfileAttributeValue">{userToRender?.stats?.totalCollections || 0}</Typography.Text>
                          <Typography.Text className="fanProfileValue">{t("collections")}</Typography.Text>
                        </Col>
                      </Space>
                    </div>

                    <ThreeDotsComponent onClick={() => setIsReportModalOpen(true)} />
                  </Row>
                </div>
              </>
            )}

            {scheduleLive?.scheduleData?.dateTime && error !== "user-not-found" && (
              <Row className="editLive-container mb-20 mt-10">
                <Col className="editLive-content">
                  <Row>
                    <Typography.Text className="editLive-title mt-5">
                      <img src={LiveIcon} width={30} alt="" /> {liveUtil.getScheduleDateTimeStringForProfile(t, scheduleLive.scheduleData.dateTime)}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text className="editLive-description">
                      {isMyProfile || id === tokenId ? t("modify-cancel-live") : t("take-ticket-live")}
                    </Typography.Text>
                  </Row>
                  <Row className="w-full">{displayActionBtn()}</Row>
                </Col>
                <Col className="editLive-image">
                  <img src={BellImg} width={120} alt="" />
                </Col>
              </Row>
            )}

            {/** TODO: when user is fan show only button to display discover videos of the creator */}
            {(isMyProfile || id === tokenId) && !scheduleLive?.scheduleData?.dateTime && userToRender !== null && (
              <>
                {userToRender?.expiredDiscovers ? (
                  <RenewPresentationComponent />
                ) : !userToRender?.hasDiscovers ? (
                  <NoVideoPresentationComponent />
                ) : null}
              </>
            )}

            <Col className={`feeds-container ${showOverlay ? "overlay-visible" : ""}`}>
              <Tabs activeKey={activeTab} onChange={showOverlay ? fanModalNextStep : handleTabChange}>
                <TabPane
                  tab={
                    <div className="tab-container">
                      <img
                        src={fanModalstep === 2 && showOverlay ? ActiveGridIcon : !showOverlay && activeTab === "feeds" ? ActiveGridIcon : GridIcon}
                        width={showOverlay && !isMyProfile ? 30 : 20}
                        className={`${fanModalstep !== 2 && showOverlay ? "opacity-icon" : ""}`}
                        alt="publications icon"
                      />
                      {fanModalstep === 2 && showOverlay && !isMyProfile && <img className="swipe-icon" src={SwipeHandUp} alt="swipe up" />}
                    </div>
                  }
                  key="feeds"
                >
                  {feedsToRender.length === 0 ? (
                    <div
                      onClick={() => {
                        if (isMyProfile) {
                          navigate("/creator/publication");
                        }
                      }}
                    >
                      <NoPublicationComponent />
                    </div>
                  ) : (
                    <Row className="mt-10 pr-4 pl-4" gutter={2}>
                      {feedsToRender?.map((feed) => {
                        if (feed.files) {
                          const lock = !subscribed && id !== tokenId && feed.isPrivate;
                          const isVideo = feed.type === "video";
                          const isUploading: boolean = feed.files[0].status === FILE_STATUS.UPLOADING;
                          const feedThumbnail = isUploading
                            ? ""
                            : isVideo
                              ? feed.isPrivateForCurrentUser
                                ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath
                                : feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                              : feed.isPrivateForCurrentUser
                                ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath
                                : feed.files[0].thumbnails?.findIndex((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR) !== -1
                                  ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                                  : feed.files[0].url;

                          return isUploading && !isMyProfile ? (
                            <></>
                          ) : (
                            <Col
                              className="fanProfilePostWrapper"
                              key={feed._id}
                              span={8}
                              onClick={() => {
                                navigate(`/common/publications/${feed._id}/${userToRender?._id}?isMyProfile=${isMyProfile}`);
                              }}
                            >
                              <FeedMediaComponent
                                shouldAddWatermark={!isMyProfile!}
                                description=""
                                fileUrl={feedThumbnail!}
                                thumbnail=""
                                type="image"
                                imageClassName={`fanProfilePost ${!isMyProfile && lock ? "fanProfilePrivatePost" : ""}`}
                                isPrivate={feed.isPrivateForCurrentUser!}
                                isMyProfile={isMyProfile!}
                              />
                              {lock && <img className="fanProfilePostLockIcon" src={LockIcon} alt="lock" />}
                              {isVideo && <img src={VideoIcon} style={!lock ? { right: "11px" } : {}} alt="video icon" className="feedVideoIcon" />}
                              <Spin spinning={isUploading} className="feedSpinner" indicator={<SpinnerComponent />} />
                            </Col>
                          );
                        }
                        return <></>;
                      })}
                    </Row>
                  )}
                  {!isMyProfile && id !== tokenId && hasMore && <SpinnerComponent refProp={sentryRef} />}
                  {currentUserFeeds.length === 0 && currentUserFeedsLoading === "pending" && (isMyProfile || id === tokenId) && <SpinnerComponent />}
                  {feeds.length === 0 && !isMyProfile && id !== tokenId && !hasMore && feedsIsLoading === "pending" && <SpinnerComponent />}
                </TabPane>

                <TabPane
                  tab={
                    <div className="tab-container">
                      <img
                        src={
                          fanModalstep === 1 && showOverlay
                            ? ActiveCollectionIcon
                            : !showOverlay && activeTab === "collection"
                              ? ActiveCollectionIcon
                              : CollectionIcon
                        }
                        width={showOverlay && !isMyProfile ? 30 : 20}
                        className={`${fanModalstep !== 1 && showOverlay ? "opacity-icon" : ""}`}
                        alt="collections icon"
                      />
                      {fanModalstep === 1 && showOverlay && !isMyProfile && <img className="swipe-icon" src={SwipeHandUp} alt="swipe up" />}
                    </div>
                  }
                  key="collection"
                >
                  <CollectionGridComponent
                    isMyProfile={isMyProfile}
                    creatorId={id}
                    username={isMyProfile ? currentUserProfile?.username : username}
                    goToPublicationTab={() => handleTabChange("feeds")}
                    setCollectionEditMode={(value) => {
                      setCollectionEditMode(value);
                    }}
                  />
                </TabPane>
              </Tabs>
            </Col>
            {showOverlay && !isMyProfile && <FanProfileTrainingOverlayComponent step={fanModalstep} fanModalStep={fanModalNextStep} />}

            <ShareModalComponent
              isOpen={isShareModalOpen && error !== "user-not-found"}
              onClose={() => setIsShareModalOpen(false)}
              id={id !== "" ? id : tokenId}
              username={isMyProfile ? currentUserProfile?.username : username}
            />
            <FanReportCreatorModalComponent
              isOpen={isReportModalOpen && error !== "user-not-found"}
              onClose={() => setIsReportModalOpen(false)}
              onReportClick={() => userToRender && userToRender._id && onReportClick(userToRender._id)}
            />
          </>
        )}
      </Layout.Content>
      {collectionEditMode ? (
        <CollectionCancelEditModeButtonComponent />
      ) : (
        <FooterComponent type={type || ""} page={id !== "" ? FOOTER_IDS.SEARCH : FOOTER_IDS.PROFILE} />
      )}
    </Layout>
  );
};

export default ProfilePage;
